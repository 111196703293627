import { z } from 'zod';

import { ApiStatusSchema } from '../../status';

export const ApiPerformanceJsonTagLoadTimesSchema = z.object({
  avg: z.string().endsWith('ms'),
  tag: z
    .object({
      status: ApiStatusSchema,
      tag: z.string(),
      time: z.string().endsWith('ms'),
    })
    .optional(),
  tags: z
    .array(
      z.object({
        status: ApiStatusSchema,
        tag: z.string(),
        time: z.string().endsWith('ms'),
      })
    )
    .optional(),
});

type ApiPerformanceJsonTagLoadTimes = z.infer<typeof ApiPerformanceJsonTagLoadTimesSchema>;

export default ApiPerformanceJsonTagLoadTimes;
