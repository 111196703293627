import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import Select from '@mui/material/Select';

export const Selector = styled(Select)(
  ({ theme }: { theme?: Theme }) => `
  min-width: 130px;
  margin-top: 5px;
  margin-right: 7px;
  padding: 2px;
  color: ${theme?.color.GRAY_600};
`
);
