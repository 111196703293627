import { Stack } from '@mui/material';
import React from 'react';

import { useSwissWidget } from './SwissWidget.hooks';
import { Card } from './SwissWidget.styles';

export type SwissWidgetProps = {
  passed?: boolean;
  title?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'normal' | 'small';
  headerFooterColor?: string;
  backgroundColor?: string;
  textColor?: string;
} & Omit<React.ComponentProps<typeof Stack>, 'title'>;

/**
 * Represents a widget that shows a number (title), icon, and a caption
 * @namespace Widgets
 * @param {string} type - Type of the widget (error, info, success, warning)
 * @param {string} title - Title of the widget
 * @param {string} caption - Caption of the widget
 * @param {ReactNode} icon - Icon of the widget
 * @returns ReactJSXElement
 */
export default function SwissWidget(props: SwissWidgetProps) {
  const { textColor, backgroundColor, title, header, footer, icon, children, ...rest } = useSwissWidget(props);

  return (
    <Card textColor={textColor} backgroundColor={backgroundColor} data-testid="swiss-widget" {...rest}>
      {header}
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        <Stack>
          {children}
          {title}
        </Stack>
      </Stack>
      {footer}
    </Card>
  );
}
