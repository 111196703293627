type StatusP = 'pass';
type StatusR = 'review';
type StatusC = 'critical';

/** Status 'review', 'critical' */
export type StatusRC = StatusR | StatusC;
/** Status 'passed' 'review', 'critical' */
export type StatusPRC = StatusP | StatusR | StatusC;

type StatusD = 'danger';
type StatusW = 'warning';
type StatusS = 'success';

/**
 * Status 'danger', 'warning', 'success'
 * @description This type should only be used to transform from API types.
 * @deprecated
 */
export type StatusDWS = StatusD | StatusW | StatusS;

/** Transform from 'danger', 'warning', 'success' model */
export function transformStatusFromDWS(status: StatusDWS): Status {
  switch (status) {
    case 'danger':
      return 'critical';
    case 'warning':
      return 'review';
    case 'success':
      return 'pass';
    default:
      throw new Error('Unknown status');
  }
}

type StatusE = 'error';

/**
 * Status 'success', 'warning', 'error'
 * @description This type is used by MUI
 */
export type StatusSWE = StatusS | StatusW | StatusE;

/** Transform to 'success', 'warning', 'error' model for use by MUI */
export function transformStatusToSWE(status: Status): StatusSWE {
  switch (status) {
    case 'critical':
      return 'error';
    case 'review':
      return 'warning';
    case 'pass':
      return 'success';
    default:
      throw new Error('Unknown status');
  }
}

/**
 * Status 'pass', 'review', 'fail'
 * @description This type is only used by the API
 * @deprecated
 */
export type StatusPRF = 'PASS' | 'REVIEW' | 'FAIL';

/** Transform from 'pass', 'review', 'fail' model */
export function transformPRFtoStatus(status: StatusPRF): Status {
  switch (status) {
    case 'PASS':
      return 'pass';
    case 'REVIEW':
      return 'review';
    case 'FAIL':
      return 'critical';
    default:
      throw new Error('Unknown status');
  }
}

type Status = StatusPRC;
export default Status;
