import { KeyboardArrowUp } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useFlags } from 'api/feature-flags/provider';
import React, { useState } from 'react';

export default function InternalToolsOverlaysFlagsOverlay() {
  const [collapsed, setCollapsed] = useState(false);

  const flagContext = useFlags();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        bottom: 0,
        right: 50,
      }}
    >
      <Accordion variant="outlined" expanded={collapsed} onChange={(_, v) => setCollapsed(v)}>
        <AccordionSummary expandIcon={<KeyboardArrowUp />}>Flags</AccordionSummary>
        <AccordionDetails sx={{ px: 0, maxHeight: '80vh', overflow: 'auto' }}>
          <List sx={{ width: 500 }} dense>
            {flagContext.getAllFlags().map((flag) => (
              <Accordion variant="outlined" key={flag?.id} sx={{ border: 0 }}>
                <AccordionSummary>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                    <Stack>
                      <Typography>
                        {flag?.name}
                        <Chip label={flag?.type} size="small" sx={{ ml: 1 }} />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {flag?.id}
                      </Typography>
                    </Stack>
                    <Switch size="small" checked={flag?.enabled} />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ mx: 2, bgcolor: 'background.verylight' }}>
                  <ButtonGroup fullWidth>
                    <Button
                      onClick={() => {
                        if (flag)
                          flagContext.updateFlag(flag.id, {
                            source: 'override',
                            value: true,
                          });
                      }}
                      disabled={flag?.enabled === true}
                    >
                      Enable
                    </Button>
                    <Button
                      onClick={() => {
                        if (flag)
                          flagContext.updateFlag(flag.id, {
                            source: 'override',
                            value: false,
                          });
                      }}
                      disabled={!flag?.enabled}
                    >
                      Disable
                    </Button>
                  </ButtonGroup>

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="caption">Id</Typography>
                  <Typography>{flag?.id}</Typography>

                  <Divider sx={{ my: 1 }} />

                  <Typography variant="caption">Name</Typography>
                  <Typography>{flag?.name}</Typography>

                  <Divider sx={{ my: 1 }} />

                  <Typography variant="caption">Description</Typography>
                  <Typography>{flag?.description}</Typography>

                  <Divider sx={{ my: 1 }} />

                  <Typography variant="caption">History</Typography>
                  <List dense sx={{ maxHeight: 200, overflow: 'auto' }}>
                    {[...(flag?.reasons ?? [])].reverse().map((reason, index) => (
                      <ListItem key={index} selected={index === 0}>
                        <ListItemText primary={reason.source} secondary={reason.value.toString()} />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
