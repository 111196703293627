import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { refine } from 'api/utils';
import { z } from 'zod';

import { ApiVendorInsightsSchema } from './vendor/insights';
import { ApiBoardIdInsightSchema, ApiBoardIdSchema, ApiBoardIdVendorSchema } from './boardId';
import { ApiDashboardSchema } from './dashboard';
import { ApiInsightSchema } from './insight';
import { ApiPerformanceSchema } from './performance';
import { ApiScoreSchema } from './score';
import { ApiSummarySchema } from './summary';
import { ApiTopFindingsSchema } from './topFindings';

export const ApiReportKnownPropsSchema = z.object({
  [0]: ApiDashboardSchema,
  summary: ApiSummarySchema,
  performance: ApiPerformanceSchema,
  topFindings: ApiTopFindingsSchema,
  insight: z.record(ApiBoardIdSchema, ApiInsightSchema),
  vendor: z.record(ApiBoardIdVendorSchema, ApiVendorInsightsSchema),
  // optional
  score: z.array(ApiScoreSchema).optional(),
});

export const ApiReportSchema = z.preprocess((args: any) => {
  const knownPropertyKeySchema = ApiReportKnownPropsSchema.keyof();

  // extract known properties
  const [known, insightsVendorsOthers] = refine(args, (key) => knownPropertyKeySchema.safeParse(key).success);

  // extract any known vendor properties, vendor{number}
  const [vendors, insightsOthers] = refine(
    insightsVendorsOthers,
    (key) => ApiBoardIdVendorSchema.safeParse(key).success
  );

  // extract any known insight properties, insight{number} or any known named properties
  const [insights, _others] = refine(insightsOthers, (key) => ApiBoardIdInsightSchema.safeParse(key).success);

  // in a perfect scenario, _other should be empty

  return {
    ...known,
    insight: insights,
    vendor: vendors,
  };
}, ApiReportKnownPropsSchema);

/*
 * Variables that will always be in JSON file:
 *  - summary
 *  - 0
 *  - score
 *  - insight47
 *  - insight49
 *  - insight44
 *  - insight45
 *  - insight52
 *  - insight217
 *  - insight40
 */

type ApiReport = z.infer<typeof ApiReportSchema> | ApiError | ApiLoading;

export default ApiReport;
