/**
 * Event to event name short code mappings
 */
const EventTypeShortCodes = {
  anomalyArray: 'a',
  consentArray: 'cs',
  cspArray: 'csp',
  customArray: 'c',
  dataLayerArray: 'dl',
  dataLayerEventArray: 'dle',
  debugArray: 'd',
  domEventArray: 'de',
  generalErrorArray: 'ge',
  insightArray: 'i',
  interactionArray: 'in',
  jsErrorArray: 'j',
  missingRequests: 'mr',
  newVariableArray: 'v',
  piiArray: 'p',
  requestArray: 'r',
  requestFailedArray: 'rf',
  serverSideInsightArray: 'ssi',
  unknownCookieArray: 'uc',
  unknownRequestArray: 'ur',
} as const;

// reverse mapping
export const EventTypesKeys: Record<
  (typeof EventTypeShortCodes)[keyof typeof EventTypeShortCodes],
  keyof typeof EventTypeShortCodes
> = {
  a: 'anomalyArray',
  cs: 'consentArray',
  csp: 'cspArray',
  c: 'customArray',
  dl: 'dataLayerArray',
  dle: 'dataLayerEventArray',
  d: 'debugArray',
  de: 'domEventArray',
  ge: 'generalErrorArray',
  i: 'insightArray',
  in: 'interactionArray',
  j: 'jsErrorArray',
  mr: 'missingRequests',
  v: 'newVariableArray',
  p: 'piiArray',
  r: 'requestArray',
  rf: 'requestFailedArray',
  ssi: 'serverSideInsightArray',
  uc: 'unknownCookieArray',
  ur: 'unknownRequestArray',
} as const;

export default EventTypeShortCodes;
