import { startTransition, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

/** A general hook to set custom key values in the URL */
export default function useAppGeneralParams() {
  //const [params] = useSearchParams();
  const navigate = useNavigate();

  /*
   * This is a workaround for the issue with useSearchParams not updating the URL.
   */
  const params = new URLSearchParams(window.location.search);

  /*
   * setSearchParams does not work correctly. It will not update the URL, use this instead.
   */
  const setParams = useCallback(
    (params: (prev: URLSearchParams) => URLSearchParams, options?: { replace?: boolean }) => {
      const oldParams = new URLSearchParams(window.location.search);
      const newParams = params(oldParams).toString();
      // from react-router-dom docs
      startTransition(() => navigate('?' + newParams, options));
    },
    [navigate]
  );

  const setParam = useCallback(
    (key: string, value: string | null) => {
      return value === null
        ? setParams((prev) => (prev.delete(key), prev), { replace: true })
        : setParams((prev) => (prev.set(key, value), prev), { replace: true });
    },
    [setParams]
  );

  return {
    params,
    setParam,
  };
}
