import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getExploreRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.explore.enabled')) return <></>;

  const ExplorePage = lazily('explore');

  return (
    <>
      <Route path={AppRoutes.explore.index()} element={<ExplorePage />} />
    </>
  );
};

export default getExploreRoutes;
