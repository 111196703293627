import { RouteListGetter } from 'App';
import React from 'react';
import { Outlet, Route } from 'react-router-dom';

import InternalToolsFeatureFlagsPage from './tools/featureFlags';

const getInternalRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('user.isPowerUser')) return <></>;

  const InternalToolsPage = lazily('internal/tools');
  const InternalAdminPage = lazily('internal/admin');
  const InternalToolsPrivacyPage = lazily('internal/tools/privacy');
  const InternalToolsOverlaysPage = lazily('internal/tools/overlays');
  const InternalToolsNotificationsPage = lazily('internal/tools/notifications');

  return (
    <>
      <Route path="/rule-configuration" element={<InternalAdminPage />} />
      <Route path="/internal" element={<Outlet />}>
        <Route path="/internal/tools" element={<InternalToolsPage />} />
        <Route path="/internal/tools/overlays" element={<InternalToolsOverlaysPage />} />
        <Route path="/internal/tools/feature-flags" element={<InternalToolsFeatureFlagsPage />} />
        {isExperimentEnabled('pages.privacy.enabled') && (
          <Route path="/internal/tools/privacy" element={<InternalToolsPrivacyPage />} />
        )}
        {isExperimentEnabled('pages.notifications.enabled') && (
          <Route path="/internal/tools/notifications" element={<InternalToolsNotificationsPage />} />
        )}
      </Route>
    </>
  );
};

export default getInternalRoutes;
