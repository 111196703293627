import { PostfixFunctionSchema, PostfixLogicSchema } from 'api/apiTypes/postfixLogic';
import { z } from 'zod';

export const ApiSourceOfTruthTagRemovalUpdateCreateSchema = z.object({
  consentRuleId: z.string().optional(),
  isEnabled: z.boolean(),
  pageQualifier: PostfixFunctionSchema,
  pageUrlMatchCriteria: PostfixLogicSchema,
  tagRequestTypeQualifier: PostfixFunctionSchema,
});

export const ApiSourceOfTruthTagRemovalCreateSchema = ApiSourceOfTruthTagRemovalUpdateCreateSchema.extend({
  ruleId: z.string(),
});

export const ApiSourceOfTruthTagRemovalSchema = ApiSourceOfTruthTagRemovalCreateSchema.extend({
  tagId: z.string(),
});

export type ApiSourceOfTruthTagRemoval = z.infer<typeof ApiSourceOfTruthTagRemovalSchema>;
export type ApiSourceOfTruthTagRemovalCreate = z.infer<typeof ApiSourceOfTruthTagRemovalCreateSchema>;
export type ApiSourceOfTruthTagRemovalUpdate = z.infer<typeof ApiSourceOfTruthTagRemovalUpdateCreateSchema>;
