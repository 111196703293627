import { z } from 'zod';

import { ApiPerformanceJsonSchema } from './json';

export const ApiPerformanceSchema = z.object({
  boardId: z.literal('performance'),
  json: ApiPerformanceJsonSchema,
});

type ApiPerformance = z.infer<typeof ApiPerformanceSchema>;

export default ApiPerformance;
