import { RoleFlagDefinition } from '../types/role';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "role." DURING POST PROCESSING
const defaultRoleFlags: RoleFlagDefinition[] = [
  {
    id: 'internalUser',
    name: 'Internal User',
    description: 'Internal users of the application',
    enabled: false,
  },
];

export default defaultRoleFlags;
