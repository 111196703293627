import { z } from 'zod';

export const ApiInsightTableSchema = z.object({
  /**
   * Contains the keys and header details for the report and how to map the data in the data array to columns.
   */
  header: z.array(z.any()),
  /**
   * Contains the actual data for the report. Each row is an object with the keys matching the header keys.
   */
  data: z.array(z.any()).optional(),
  /**
   * This config was for the old table component. We don't use it anymore after using the new MUI table
   * @deprecated
   */
  config: z.any().optional(),
});

type ApiInsightData = z.infer<typeof ApiInsightTableSchema>;

export default ApiInsightData;
