import { Email, Person } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Portal,
  Stack,
  Switch,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCurrentUser } from 'api';
import useExperiments from 'api/experiments';
import { isErrored } from 'api/utils';
import NavDrawer from 'components/NavDrawer';
import React, { useContext, useState } from 'react';

import { drawer } from 'config/responsive';

import * as Styled from './Header.style';
import * as Items from './items';
import { useHeader } from './useHeader';

const HeaderMixinContext = React.createContext<{
  ref: HTMLDivElement | null;
}>({
  ref: null,
});

/**
 * @summary Represents header component with contents logo and logout
 * @namespace Header
 * @return {ReactJSXElement} jsx component
 */
export default function Header({ children }: { children: React.ReactNode }) {
  const userProfile = useCurrentUser();

  const { isExperimentEnabled } = useExperiments();

  const { open, anchorEl, handleClose, onPressProfile } = useHeader();
  const [navOpen, setNavOpen] = React.useState(false);

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [headerMixinsRef, setHeaderMixinsRef] = useState<null | HTMLDivElement>(null);

  if (isErrored(userProfile))
    return (
      <Stack alignItems="center" justifyContent="center" height="100vh" direction="column">
        <Typography variant="h5">Error loading data for user</Typography>
        <Typography maxWidth={500} align="center">
          Do you have any assigned environments? If not, please contact your administrator to assign you to an
          environment.
          <br />
          Otherwise, if the issue persists, please contact support.
        </Typography>
      </Stack>
    );

  const onLogout = () => {
    userProfile?.logout();
    handleClose();
  };

  return (
    <>
      <Styled.AppBar color="transparent" elevation={0} data-testid="header">
        <Styled.ReversedToolbar>
          <IconButton color="primary" onClick={(e: any) => onPressProfile(e)} data-testid="HeaderProfileIconButton">
            <Person />
          </IconButton>
          <Items.EnvironmentSelector />
          <Items.NotificationsToolbarItem />
          <Stack flex="1 1 auto" direction="row" alignItems="center" ref={(ref) => setHeaderMixinsRef(ref)} />
        </Styled.ReversedToolbar>
      </Styled.AppBar>
      <NavDrawer
        open={navOpen}
        onClose={() => setNavOpen(false)}
        sx={{ width: drawer.widths.normal }}
        variant={isMdUp ? 'permanent' : isSmUp ? 'mini' : 'temporary'}
      />
      <Styled.ContentWrapper>
        <Toolbar sx={{ marginBottom: 2 }} />
        <HeaderMixinContext.Provider
          value={{
            ref: headerMixinsRef,
          }}
        >
          {children}
        </HeaderMixinContext.Provider>
      </Styled.ContentWrapper>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ pointerEvents: 'none' }}>
          <ListItemText
            primary={`${userProfile?.firstName} ${userProfile?.lastName}`}
            secondary={userProfile?.organization}
          />
        </MenuItem>
        <ListItemButton onClick={() => window.open('mailto:support@sentinelinsights.com')}>
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItemButton>
        <Divider />
        {isExperimentEnabled('user.isInternal') && (
          <MenuItem
            data-testid="internal-tools-toggle"
            onClick={() => {
              const prev = window.localStorage.getItem('showInternalTools');
              window.localStorage.setItem('showInternalTools', prev === 'true' ? 'false' : 'true');
              window.location.reload();
            }}
            sx={{
              alignItems: 'center',
            }}
          >
            <Typography>Show Internal Tools</Typography>
            <Switch checked={window.localStorage.getItem('showInternalTools') === 'true'} />
          </MenuItem>
        )}
        <MenuItem data-testid="log-out" onClick={onLogout}>
          <Typography color="error">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export function HeaderMixin({ children }: React.PropsWithChildren) {
  const { ref } = useContext(HeaderMixinContext);
  return <Portal container={ref}>{children}</Portal>;
}
