import NavigatorModalLabels from './navigatorModal';
import NotificationsLabels from './notifications';
import SourceOfTruth from './sourceOfTruth';

// the root locale object, note: all other locales will use this as a base

const Label = {
  topFindings: 'Top Findings',
  welcome: 'Welcome,',
  overview: 'Overview',
  insights: 'Insights',
  admin: 'Admin',
  documentation: 'Documentation',
  view: 'View',
  accessability: 'Accessability',
  seo: 'SEO',
  label: 'Label',
  bestPractice: 'Best Practices',
  performance: 'Performance',
  criticalIssue: 'Critical Issues',
  needReview: 'Needs Review',
  needsReview: 'Needs Review',
  passed: 'Passed',
  stats: 'STATS',
  adobe: 'Adobe',
  facebook: 'Facebook',
  google: 'Google',
  technology: 'Technology',
  governance: 'Governance',
  other: 'Other',
  sort: 'Sort',
  vendor: 'Vendor',
  reports: 'Reports',
  topFindingsNotAvailable: 'No summary of findings available !!',
  dashboard: 'Dashboard',
  critical: 'Critical',
  password: 'Password',
  email: 'Email',
  signIn: 'Sign In',
  loginContinue: 'Continue',
  invalidPassword: 'Please enter valid password',
  invalidEmail: 'Please enter valid Email address',
  signInProgress: 'Signing In..',
  summary: 'Summary',
  businessImpactAndRecommendation: 'Business Impact & Recommendation',
  businessImpact: 'Business Impact',
  recommendation: 'Recommendation',
  showHideColumn: 'Show / Hide Columns',
  hidden: ' Hidden',
  searchPlaceholder: 'Search',
  restPassword: 'Reset Password',
  resettingPassword: 'Resetting Password',
  successResetMessage:
    "If you've entered a valid email address, you will receive an email with instructions to reset your password. If you have any issues or do not receive the password reset email, please contact support@sentinelinsights.com",
  invalidOtp: 'Please enter valid OTP',
  verifyOtp: 'Verify OTP',
  verifyingOtp: 'Verifying OTP',
  incorrectOtp: 'OTP is incorrect',
  passwordError:
    'Your password must be have at least 8 characters long, 1 uppercase, 1 lowercase character, 1 number and special character',
  passwordMismatch: 'Password and New password does not match',
  setPassword: 'Set Password',
  settingPassword: 'Setting Password',
  newPassword: 'New Password',
  newPasswordSuccess: 'Your password has been reset successfully',
  resetInstruction: 'Please enter the 6 digit verification code sent to your email address and set new password',
  loginNavigate: 'Click here to Login',
  signUp: 'Activate',
  signUpPageTitle: 'Activate your account',
  signingUp: 'Activating',
  currentPassword: 'Current Password',
  lastName: 'Last Name',
  firstNameError: 'Enter first name',
  lastNameError: 'Enter last name',
  primary: 'Primary',
  primaryShowColumns: 'Shown Columns ',
  secondary: 'Secondary',
  secondaryShowColumns: 'Shown Columns ',
  apply: 'APPLY',
  avgTagLoadNormal: 'Average Tags Load Time (Normal Tag)',
  avgTagLoadFast: 'Average Tags Load Time (Fast Tag)',
  numOfTagFast: 'Number Of Tags (Fast Load Speed)',
  numOfTagNormal: 'Number Of Tags (Normal Load Speed)',
  avgPerceivedPageLoad: 'Average Perceived Page Load Time',
  avgPageLoad: 'Average Page Load Time',
  avgTagLoadSlow: 'Average Tags Load Time (Slow Tag)',
  numOfTagSlow: 'Number Of Tags (Slow Load Speed)',
  firstNameValidationMsg: 'Please enter first name',
  lastNameValidationMsg: 'Please enter last name',
  firstName: 'First Name',
  advancedRules: 'Advanced Rules',
  equals: 'Equals',
  pleaseSelectMsg: 'Please select item from left side panel...',
  create: 'Create',
  existing: 'Existing',
  hide: 'SAVE RULE',
  removeRule: 'REMOVE RULE',
  addGroup: 'ADD GROUP',
  addItem: 'ADD ITEM',
  columnErrorMsg: 'At least one primary column must be selected',
  someThingWrong: 'Something went wrong, please try again later.',
  noRuleExist: 'No Rules Exist',
  edit: 'EDIT',
  invalidRuleValues: 'Empty or invalid header or value in form',
  off: 'OFF',
  on: 'ON',
  close: 'Close',
  locate: 'Locate',
  mapping: 'Mapping',
  review: 'Review',
  howWouldYouLikeToConfigureYourConsentRules: 'How would you like to configure your consent rules?',
  custom: 'Custom',
  auto: 'Auto',
  next: 'Next',
  select: 'Select',
  consentCategoryHelpTooltip:
    'This will only affect the category names that will be displayed, you can configure the behavior after setting up your categories.',
  areYouSure: 'Are you sure?',
  configuratorCloseConfirmation: 'You are about to close the configurator. All your progress will be lost.',
  configuratorCloseConfirmationAccept: 'Yes, close',
  configuratorCloseConfirmationDecline: 'No, take me back',
  whereAreYourConsentValues: 'Where are your consent values?',
  importFromFile: 'Import from file',
  readTheDocs: 'Read the docs',
  cookie: 'Cookie',
  localStorage: 'Local Storage',
  sessionStorage: 'Session Storage',
  javascriptVariable: 'Javascript Variable',
  customFunction: 'Custom Function',
  createYourConsentCategories: 'Create your consent categories',
  back: 'Back',
  mapsTo: 'Maps to',
  category: 'Category',
  ifRequest: 'If Request',
  value: 'Value',
  requestName: 'Request Name',
  customize: 'Customize',
  virtualConsultantFindings: 'Virtual Consultant Findings',
  export: 'Export',
  dataExport: 'Data Export',
  requestDataExport: 'Request Data Export',
  exportProcessing: 'Your export is processing',
  linkWillBeSent: 'A download link will be sent to your email address shortly',
  returnToInsight: 'Return to Insight',
  default: 'Default',
  ip: 'IP',
  proxyType: 'Proxy Type',
  proxyDescription: 'Proxy Description',
  state: 'State',
  pageDetails: 'Page Details',
  deviceDetails: 'Device Details',
  locationNetworkDetails: 'Location/Network Details',
  botName: 'Bot Name',
  browserName: 'Browser Name',
  browserVersion: 'Browser Version',
  deviceManufacturer: 'Device Manufacturer',
  deviceModel: 'Device Model',
  deviceType: 'Device Type',
  osName: 'OS Name',
  osVersion: 'OS Version',
  userAgent: 'User Agent',
  city: 'City',
  country: 'Country',
  continent: 'Continent',
  url: 'URL',
  date: 'Date',
  name: 'Name',
  maxNumberOfRows: 'Max Number of Rows',
  limitedTo500000: 'Limited to 500,000',
  takeTourPromptAction: 'Take a tour',
  takeTourPromptTitle: 'Welcome to the new Sentinel Insights dashboard!',
  takeTourPromptBody:
    "We've made some major changes to our website, and we'd love to show you what has changed. Would you like to take a tour of our new UI?",
  environment: 'Environment',
  insightsDetails: 'Insights Details',
  whatTypeOfEvents: 'What type of events?',
  onWhichPages: 'On which pages?',
  atWhatThreshold: 'At what threshold?',
  overWhatTimePeriod: 'Over what time period?',
  whoGetsAlerted: 'Who gets alerted?',
  howLongUntilWeAlertYouAgain: 'How long until we alert you again?',
  howManyAlertsDoYouWant: 'How many alerts do you want?',
  giveItAName: 'Alert name',
  notifications: NotificationsLabels,
  navigatorModal: NavigatorModalLabels,
  sourceOfTruth: SourceOfTruth,
} as const;

export default Label;
