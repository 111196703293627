import { z } from 'zod';

import { ApiSummaryJsonSchema } from './json';

export const ApiSummarySchema = z.object({
  boardId: z.literal('summary'),
  json: ApiSummaryJsonSchema,
});

type ApiSummary = z.infer<typeof ApiSummarySchema>;

export default ApiSummary;
