import BoardId from 'api/types/report/boardId';
import { StatusPRC } from 'api/types/report/status';
import { useCallback } from 'react';

import { BOARD_ID_PARAM, STATUS_PARAM } from './config';
import useAppGeneralParams from './general';

/** The hook used to manipulate URL parameters on the insights page */
export default function useAppInsightsParams() {
  const { params, setParam } = useAppGeneralParams();

  const boardId = params.get(BOARD_ID_PARAM) as BoardId | null;
  const setBoardId = useCallback((id: BoardId | null) => setParam(BOARD_ID_PARAM, id), [setParam]);

  const status = (params.get(STATUS_PARAM) as StatusPRC | null) ?? 'critical';
  const setStatus = useCallback((status: StatusPRC | null) => setParam(STATUS_PARAM, status), [setParam]);

  return {
    /** The current boardId parameter */
    boardId,
    /** Function to set the current boardId parameter */
    setBoardId,

    /** The current status in pass, review, critical format */
    status,
    /** Function to set the current status in pass, review, critical format */
    setStatus,
  };
}
