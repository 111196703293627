import { z } from 'zod';

export const ApiStatusSchema = z.union([z.literal('1'), z.literal('2'), z.literal('3'), z.literal('4')]);

/** Denotes a status in the Pass, Review, Fail format */
export const ApiStatusPRF = z.union([z.literal('PASS'), z.literal('REVIEW'), z.literal('FAIL')]);

type ApiStatus = z.infer<typeof ApiStatusSchema>;

export default ApiStatus;
