export type ClickHouseDataHeadingType = {
  type: string;
  args: ClickHouseDataHeadingType[];
};

export function ClickHouseParseDataHeadingType(type: string) {
  const perenthesis = type.indexOf('(');

  let mainType = '';
  const args: string[] = [];

  if (perenthesis >= 0) {
    mainType = type.slice(0, perenthesis);
    args.push(
      ...type
        .slice(perenthesis + 1, -1)
        .split(',')
        .map((x) => x.trim())
        .filter((x) => x.length)
    );
  } else {
    mainType = type;
  }

  while (isExtraCharacter(mainType[0])) {
    mainType = mainType.slice(1);
  }

  while (isExtraCharacter(mainType[mainType.length - 1])) {
    mainType = mainType.slice(0, -1);
  }

  const output: ClickHouseDataHeadingType = {
    type: mainType.toLowerCase(),
    args: args.map((x) => ClickHouseParseDataHeadingType(x)),
  };

  return output;
}

function isExtraCharacter(char: string) {
  return char === `'` || char === `"`;
}
