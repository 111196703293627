import { getEnabledOverlays } from 'api/utils';
import React from 'react';

import overlayItems from 'pages/internal/tools/overlays/overlayItems';

export default function InternalToolsProvider(props: React.PropsWithChildren) {
  return <OverlayProvider>{props.children}</OverlayProvider>;
}

type OverlaysContextType = {
  updateOverlays: () => void;
};

const OverlaysContext = React.createContext({} as OverlaysContextType);

function OverlayProvider(props: React.PropsWithChildren) {
  const enabledOverlays = getEnabledOverlays();

  const [, setUpdate] = React.useState(0);

  const overlayComponents = overlayItems
    .filter((x) => enabledOverlays.includes(x.id))
    .map((x) => <React.Fragment key={x.id}>{x.component}</React.Fragment>);

  const updateOverlays = () => setUpdate((x) => x + 1);

  return (
    <OverlaysContext.Provider
      value={{
        updateOverlays,
      }}
    >
      {window.localStorage.getItem('showInternalTools') === 'true' ? overlayComponents : null}
      {props.children}
    </OverlaysContext.Provider>
  );
}

export function useUpdateOverlays() {
  const context = React.useContext(OverlaysContext);

  return context.updateOverlays;
}
