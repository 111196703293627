import useExperiments from 'api/experiments';
import React from 'react';

import { PrivacyProvider } from './usePrivacy';
import PrivacyVendorsProvider from './usePrivacyInsights';

export default function PrivacyProviders(props: React.PropsWithChildren) {
  const { isExperimentEnabled } = useExperiments();
  if (!isExperimentEnabled('pages.privacy.enabled')) return <>{props.children}</>;
  return (
    <PrivacyProvider>
      <PrivacyVendorsProvider>{props.children}</PrivacyVendorsProvider>
    </PrivacyProvider>
  );
}
