import { useEffect, useRef } from 'react';

import { MiddlewareEvent } from '.';

export function useMiddlewareHookInit(namespace: string, path: string, parentId?: string, data?: any) {
  const event = useRef<MiddlewareEvent | null>(null);

  useEffect(() => {
    event.current = new MiddlewareEvent({
      path,
      namespace,
      name: 'Middleware hook instance created',
      parent: parentId,
      source: 'middleware',
      status: 'completed',
      data,
    });

    return () => {
      new MiddlewareEvent({
        path,
        namespace,
        name: 'Middleware hook instance destroyed',
        parent: event.current?.id,
        source: 'middleware',
        status: 'completed',
      });
    };
  });

  return event.current;
}

export function useMiddlewareHookUpdate(namespace: string, path: string, parentId?: string, data?: any) {
  return new MiddlewareEvent({
    path,
    namespace,
    name: 'Middleware hook instance state updated',
    parent: parentId,
    source: 'middleware',
    status: 'processing',
    data,
  });
}

export function useViewInitEvent(namespace: string, path: string, parentId?: string, data?: any) {
  const event = useRef<MiddlewareEvent | null>(null);

  useEffect(() => {
    event.current = new MiddlewareEvent({
      path,
      namespace,
      name: 'View instance created',
      parent: parentId,
      source: 'view',
      status: 'completed',
      data,
    });

    return () => {
      new MiddlewareEvent({
        path,
        namespace,
        name: 'View instance destroyed',
        parent: event.current?.id,
        source: 'view',
        status: 'completed',
      });
    };
  });

  return event.current;
}

export function useViewUpdateEvent(namespace: string, path: string, parentId?: string, data?: any) {
  return new MiddlewareEvent({
    path,
    namespace,
    name: 'View instance state updated',
    parent: parentId,
    source: 'view',
    status: 'processing',
    data,
  });
}
