import styled from '@emotion/styled';
import { Modal as MuiModal, Stack, Theme } from '@mui/material';

export const Modal = styled(MuiModal)(
  ({ theme }: { theme?: Theme }) => `
    width: 100vw;
    display: flex;
    height: 100vh;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: stretch;
    color: ${theme?.palette?.text?.primary};

    &:focus-visible,
    & *:focus-visible {
      outline: none;
    }

    .MuiTransition-root > *:nth-of-type(1) {
      flex: 1 0 auto;
    }

    .MuiTransition-root > *:nth-of-type(2) {
      flex: 1 1 100%;
    }

    .MuiTransition-root > *:nth-of-type(3) {
      flex: 1 0 auto;
    }
  `
);

export const Content = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: stretch;
    background: ${theme?.palette?.background?.default};
  `
);

export const ContentBody = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
    overflow: auto;
    align-items: center;
    padding: 0 ${theme?.spacing(1)};

    ${theme?.breakpoints.up('md')} {
      padding: 0 ${theme?.spacing(8)};
    }
  `
);
