import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  ClickAwayListener,
  Drawer as MuiDrawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';

import * as Styled from './Drawer.styles';

const POSITIONS = {
  left: {
    openIcon: ChevronRight,
    closeIcon: ChevronLeft,
  },
  right: {
    openIcon: ChevronLeft,
    closeIcon: ChevronRight,
  },
};

export type DrawerProps = {
  open: boolean;
  header: ReactNode;
  position: 'left' | 'right';
  onChange: (open: boolean) => void;
} & Omit<React.ComponentProps<typeof MuiDrawer>, 'onChange' | 'open' | 'variant' | 'anchor'>;

export function Drawer(props: DrawerProps) {
  const { open, children, onChange, header, position, ...rest } = props;

  const theme = useTheme();
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));

  const OpenIcon = POSITIONS[position].openIcon;
  const CloseIcon = POSITIONS[position].closeIcon;

  return (
    <Stack width={isTabletOrSmaller ? 48 : undefined}>
      <Stack
        width={isTabletOrSmaller ? '100vw' : '100%'}
        height="100%"
        bgcolor={isTabletOrSmaller && open ? 'rgba(0,0,0,0.2)' : undefined}
        zIndex={9}
        sx={{ pointerEvents: 'none', transition: 'background 100ms linear' }}
      >
        <ClickAwayListener onClickAway={() => isTabletOrSmaller && onChange(false)}>
          <Styled.Drawer {...rest} variant="permanent" anchor="left" open={open} side={position}>
            <Styled.DrawerTinyHeader visible={!open} onClick={() => onChange(!open)} side={position}>
              <OpenIcon color="action" />
              <Styled.RotatedTypography variant="h6">{header}</Styled.RotatedTypography>
            </Styled.DrawerTinyHeader>
            <Styled.DrawerContentWrapper visible={open}>
              <Styled.DrawerContent>
                <Styled.Header side={position}>
                  <Typography variant="h6">{header}</Typography>
                  <IconButton onClick={() => onChange(!open)}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Styled.Header>
                {children}
              </Styled.DrawerContent>
            </Styled.DrawerContentWrapper>
          </Styled.Drawer>
        </ClickAwayListener>
      </Stack>
    </Stack>
  );
}
