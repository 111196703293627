import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a ReportId.
 */
export const EnvironmentIdSchema = z.string().uuid().brand('EnvironmentId');

/**
 * The EnvironmentId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects an EnvironmentId.
 */
type EnvironmentId = z.infer<typeof EnvironmentIdSchema>;

export default EnvironmentId;
