import useLogs from 'api/logs';
import React, { useCallback, useContext } from 'react';

type CacheContextType = {
  cache: Record<string, any>;
  setItem: (key: string, value: any) => void;
  getItem: <T>(key: string) => T | undefined;
  removeItem: (key: string) => void;
};

const CacheContext = React.createContext({} as CacheContextType);

export function CacheProvider({ children }: React.PropsWithChildren) {
  const { log } = useLogs();

  const [cache, setCache] = React.useState<Record<string, any>>({});

  const setItem: CacheContextType['setItem'] = useCallback(
    (key, value) => {
      log(`[CACHE] store item: ${key}`);
      setCache((prev) => ({ ...prev, [key]: value }));
    },
    [setCache, log]
  );

  const removeItem: CacheContextType['removeItem'] = useCallback(
    (key) => {
      setCache((prev) => {
        const { [key]: _, ...rest } = prev;
        log(`[CACHE] remove item: ${key}`, 'Medium');
        return rest;
      });
    },
    [setCache, log]
  );

  const getItem: CacheContextType['getItem'] = useCallback(
    (key) => {
      const value = cache[key];
      log(`[CACHE] get item: ${key} => ${!!value ? 'HIT' : 'MISS'}`);
      return value;
    },
    [cache, log]
  );

  const value = {
    cache,
    setItem,
    getItem,
    removeItem,
  };

  return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>;
}

export function useCache() {
  return useContext(CacheContext);
}
