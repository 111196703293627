import { BaseFlag } from './base';

export type MetaFlag = BaseFlag & {
  type: 'meta';
};

export type MetaFlagDefinition = Omit<MetaFlag, 'reasons' | 'type'>;

export function MetaFlagFromDefinition(definition: MetaFlagDefinition): MetaFlag[] {
  return [
    {
      ...definition,
      type: 'meta',
      id: `meta.${definition.id}`,
      reasons: [],
    },
  ];
}
