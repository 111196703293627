import { z } from 'zod';

export const ApiBoardIdInsightSchema = z.string();

export const ApiBoardIdVendorSchema = z.union([
  z.literal('piiInsights'),
  z.literal('globalInsights'),
  z.literal('clientInsights'),
  z.literal('anomalyInsights'),
  z.literal('consentInsights'),
  z.literal('dataLayerInsights'),
  z.string().startsWith('vendor'),
  z.literal('tagGovernanceInsights'),
  z.literal('insightTagsNotFiring'),
]);

export const ApiBoardIdSchema = z.union([ApiBoardIdVendorSchema, ApiBoardIdInsightSchema]);

type ApiBoardId = `insight${number}` | `vendor${number}`;

export default ApiBoardId;
