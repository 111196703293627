import useExperiments from 'api/experiments';
import React from 'react';

import { UseUsersProvider } from './useUsers';

export default function AdminProvider(props: React.PropsWithChildren) {
  const { isExperimentEnabled } = useExperiments();
  if (!isExperimentEnabled('pages.admin.enabled')) return <>{props.children}</>;
  return <UseUsersProvider>{props.children}</UseUsersProvider>;
}
