import { Switch, Typography } from '@mui/material';
import React from 'react';

import * as Styled from './BigBoySwitch.styles';

export type BigBoySwitchProps = {
  label: string;
  isToggleable?: boolean;
  onChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, v: boolean) => void;
} & Omit<React.ComponentProps<typeof Switch>, 'onChange'>;

/**
 * @typedef {Module}
 * @namespace BigBoySwitch
 * @summary A "BIG" version of a normal switch, with a label and visual indicator.
 * @returns {ReactJSXElement}
 */
const BigBoySwitch = (props: BigBoySwitchProps) => {
  const { checked, label, isToggleable = true, onChange, ...rest } = props;
  return (
    <Styled.Button
      checked={checked}
      disabled={!isToggleable}
      data-testid="BigBoySwitch"
      onClick={(e) => onChange(e, !checked)}
    >
      <Styled.Switch checked={checked} isToggleable={isToggleable} {...rest} />
      <Typography>{label}</Typography>
    </Styled.Button>
  );
};

export default BigBoySwitch;
