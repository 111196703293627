import styled from '@emotion/styled';
import { alpha, Stack as MuiStack, TextField as MuiTextField, Theme } from '@mui/material';

export const Stack = styled(MuiStack)(
  ({ theme }: { theme?: Theme }) => `
    gap: ${theme?.spacing(2)};
    padding: ${theme?.spacing(2)};
    border-radius: ${theme?.shape.borderRadius}px;
    border: 1px solid ${theme?.palette.divider};
    background-color: ${alpha(theme?.palette.background.verylight ?? '', 0.35)};
  `
);

export const TextField = styled(MuiTextField)(
  ({ theme }: { theme?: Theme }) => `
    .MuiInputBase-root {
      background-color: ${theme?.palette.background.paper};
    }
  `
);
