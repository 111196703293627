import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { z } from 'zod';

import type { DashboardInsightDetailsTableDataModel } from './details.model';
import { DashboardInsightDetailsTableDataModelSchema } from './details.model';

const DashboardInsightDetailsTableDataRowViewModelSchema = z
  .object({
    id: z.string(),
  })
  .catchall(z.string());

type DashboardInsightDetailsTableDataRowViewModel = z.infer<typeof DashboardInsightDetailsTableDataRowViewModelSchema>;

const DashboardInsightDetailsTableDataViewModelSchema = DashboardInsightDetailsTableDataModelSchema.extend({
  data: z.array(DashboardInsightDetailsTableDataRowViewModelSchema),
});

export type DashboardInsightDetailsTableDataViewModel = z.infer<typeof DashboardInsightDetailsTableDataViewModelSchema>;

export function dashboardDetailsModelToViewModel(
  input: unknown,
  parentEvent?: MiddlewareEvent
): DashboardInsightDetailsTableDataViewModel {
  const validationEvent = new MiddlewareEvent({
    namespace: 'Dashboard',
    source: 'validate',
    name: 'Convert Model to ViewModel',
    path: `dashboardDetailsModelToViewModel()`,
    data: input,
    parent: parentEvent?.id, // to record the init AND the update event
  });

  let model: DashboardInsightDetailsTableDataModel;

  try {
    model = DashboardInsightDetailsTableDataModelSchema.parse(input);

    validationEvent.status = 'completed';
    validationEvent.resultText = JSON.stringify(model);
  } catch (error) {
    validationEvent.status = 'failed';
    validationEvent.resultText = `${error}`;

    const middlewareError = new MiddlewareError({
      cause: error instanceof Error ? error : new Error(`${error}`),
      event: validationEvent,
      viewType: 'data',
    });

    throw middlewareError;
  }

  return {
    meta: model.meta,
    data: model.data.map((row, index) => {
      const newRow: DashboardInsightDetailsTableDataRowViewModel = {
        id: index.toString(),
      };

      model.meta.forEach((header, index) => {
        newRow[header.name] = row[index];
      });

      return newRow;
    }),
  };
}
