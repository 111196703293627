export const SORT_PARAM = 'sort';
export const LABEL_PARAM = 'labelIds';
export const TAG_ID_PARAM = 'tagId';
export const STATUS_PARAM = 'status';
export const BOARD_ID_PARAM = 'boardId';
export const CATEGORY_PARAM = 'category';
export const REPORT_ID_PARAM = 'reportId';
export const VENDOR_ID_PARAM = 'vendorId';

// Explore Data
export const SCOPE_PARAM = 'scope';

// Data Export
export const NAME_PARAM = 'name';
