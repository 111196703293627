import {
  ApiSourceOfTruthCustomizationVariablesToIgnore,
  ApiSourceOfTruthCustomizationVariablesToIgnoreSchema,
  ReportId,
  TagId,
} from 'api/apiTypes';
import routes from 'api/routes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import { useContext, useEffect } from 'react';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthIgnoredVariables(
  reportId: ReportId,
  tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const ignoredVariables =
    match === true
      ? sourceOfTruthContext.globalIgnoredVariablesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredVariablesCache[customizationKey];

  useEffect(() => {
    if (currentReport && tagId && !ignoredVariables)
      sourceOfTruthContext.getIgnoredVariables(currentReport, tagId, match);
  }, [currentReport, reportId, sourceOfTruthContext, tagId, match, ignoredVariables]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredVariables)) return loading();
  if (isErrored(ignoredVariables)) return error(ignoredVariables.messages);

  return ignoredVariables;
}

export function useCreateSourceOfTruthIgnoredVariable(reportId: ReportId, tagId: TagId, match: string | boolean) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { post } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const ignoredVariables =
    match === true
      ? sourceOfTruthContext.globalIgnoredVariablesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredVariablesCache[customizationKey];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredVariables)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const create = async (ignoredVariable: ApiSourceOfTruthCustomizationVariablesToIgnore, match: string | boolean) => {
    const data = ApiSourceOfTruthCustomizationVariablesToIgnoreSchema.parse(ignoredVariable);
    await post(
      routes.sourceOfTruth.ignoredVariables.create(
        currentReport.summary.json.environmentId,
        tagId,
        encodeURIComponent(match)
      ),
      data,
      {
        withCredentials: true,
      }
    );
    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredVariablesCache
        : sourceOfTruthContext.setPageIgnoredVariablesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (match === true) {
      sourceOfTruthContext.globalIgnoredVariableIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredVariableIds.current?.delete(customizationKey);
    }
  };

  return create;
}
/*
export function useUpdateSourceOfTruthIgnoredVariable(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const ignoredVariables = sourceOfTruthContext.ignoredVariablesCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredVariables)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (ignoredVariable: ApiSourceOfTruthCustomizationVariablesToIgnore, match: string) => {
    const data = ApiSourceOfTruthCustomizationVariablesToIgnoreSchema.parse(ignoredVariable);
    await patch(
      routes.sourceOfTruth.ignoredVariables.update(currentReport.summary.json.environmentId, tagId, match),
      data,
      {
        withCredentials: true,
      }
    );
    sourceOfTruthContext.setIgnoredVariablesCache((prev) => ({
      ...prev,
      [tagId]: withoutError(prev[tagId])?.map((x) => (x.ruleId === data.ruleId ? data : x)),
    }));
  };

  return update;
}*/

export function useDeleteSourceOfTruthIgnoredVariable(reportId: ReportId, tagId: TagId, match: string | boolean) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteReq } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const ignoredVariables =
    match === true
      ? sourceOfTruthContext.globalIgnoredVariablesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredVariablesCache[customizationKey];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredVariables)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (match: string | boolean, groupPath: string) => {
    await deleteReq(
      routes.sourceOfTruth.ignoredVariables.delete(
        currentReport.summary.json.environmentId,
        tagId,
        encodeURIComponent(match),
        groupPath
      ),
      {
        withCredentials: true,
      }
    );

    if (match === true) {
      sourceOfTruthContext.globalIgnoredVariableIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredVariableIds.current?.delete(customizationKey);
    }

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredVariablesCache
        : sourceOfTruthContext.setPageIgnoredVariablesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
  };

  return update;
}
