import { ChevronLeft, ChevronRight, MoreVert } from '@mui/icons-material';
import { Card as MuiCard, IconButton, Menu, Skeleton } from '@mui/material';
import React from 'react';

import useCard from './hooks/useCard';
import * as Styled from './Card.styles';

function Card(allProps: CardProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { gap, padding, direction, alignItems, justifyContent, ...props } = allProps;

  const {
    menu,
    style,
    header,
    scroller,
    className,
    adornerLeft,
    resizeHandle,
    showSkeleton,
    dropShadowVisible,
    dragIndicatorVisible,
    ...rest
  } = useCard(props);

  return !showSkeleton ? (
    <div ref={ref} className={className} style={style} {...rest}>
      <Styled.Card>
        <Styled.Header dropShadowVisible={dropShadowVisible}>
          <Styled.DraggableHeader className="RglDashboard-draggable" dragIndicatorVisible={dragIndicatorVisible}>
            {adornerLeft ?? <Styled.DragIndicator />}
            {header}
          </Styled.DraggableHeader>
          <Styled.CardActions>
            {scroller.buttonsVisible && (
              <>
                <IconButton disabled={scroller.leftButton.disabled} onClick={scroller.leftButton.onClick}>
                  <ChevronLeft />
                </IconButton>
                <IconButton disabled={scroller.rightButton.disabled} onClick={scroller.rightButton.onClick}>
                  <ChevronRight />
                </IconButton>
              </>
            )}
            {menu.hasContent && (
              <>
                <IconButton ref={menu.anchorEl} onClick={menu.onClick}>
                  <MoreVert />
                </IconButton>
                <Menu
                  open={menu.open}
                  onClose={menu.onClose}
                  anchorEl={menu.anchorEl.current}
                  anchorOrigin={menu.anchorOrigin}
                  transformOrigin={menu.transformOrigin}
                >
                  {menu.content}
                </Menu>
              </>
            )}
          </Styled.CardActions>
        </Styled.Header>
        <Styled.CardContent
          gap={gap}
          padding={padding}
          ref={scroller.ref}
          direction={direction}
          alignItems={alignItems}
          justifyContent={justifyContent}
        >
          {props.children}
        </Styled.CardContent>
      </Styled.Card>
      {resizeHandle}
    </div>
  ) : (
    <Skeleton variant="rounded" className={className} style={style} />
  );
}

type CardContentProps = React.ComponentProps<typeof Styled.CardContent>;

export type CardProps = {
  header?: React.ReactNode;
  resizeHandle?: React.ReactNode;

  menu?: React.ReactNode;
  adornerLeft?: React.ReactNode;
  showScrollButtons?: boolean;

  skeleton?: boolean;

  gap?: CardContentProps['gap'];
  padding?: CardContentProps['padding'];
  direction?: CardContentProps['direction'];
  alignItems?: CardContentProps['alignItems'];
  justifyContent?: CardContentProps['justifyContent'];
} & React.ComponentProps<typeof MuiCard>;

export default React.forwardRef(Card);
