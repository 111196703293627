import { z } from 'zod';

import { ApiDashboardTableSchema } from './table';

export const ApiDashboardSchema = z.object({
  boardId: z.literal('0'),
  title: z.literal('Dashboard'),
  table: ApiDashboardTableSchema,
});

type ApiDashboard = z.infer<typeof ApiDashboardSchema>;

export default ApiDashboard;
