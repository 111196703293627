import { Stack } from '@mui/material';
import React from 'react';

import * as Styled from './ChipToggle.styles';

export { Demo } from './ChipToggle.demo';

type StackProps = React.ComponentProps<typeof Stack>;
type BaseChipProps = React.ComponentProps<typeof Styled.Chip>;

export type ChipItem = {
  label: string;
  /** Badge content */
  special?: string;
  testid?: string;
};

export type ChipToggleProps = {
  value: number;
  large?: boolean;
  items?: ChipItem[];
  size?: BaseChipProps['size'];
  variant?: BaseChipProps['variant'];
  onChange: (index: number) => void;
} & Omit<StackProps, 'children' | 'onChange'>;

/**
 * Multi chip toggle button component
 */
export default function ChipToggle(props: ChipToggleProps) {
  const { items, value, onChange, large, variant, size, ...rest } = props;
  return (
    <Stack gap={2} direction="row" {...rest}>
      {items?.map((item, index) => (
        <Styled.Badge
          key={index}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={item.special}
        >
          <Styled.Chip
            size={size}
            variant={variant}
            label={item.label}
            large={large ?? false}
            selected={index === value}
            onClick={() => onChange(index)}
            data-testid={item.testid}
          />
        </Styled.Badge>
      ))}
    </Stack>
  );
}
