import { EnvironmentIdSchema } from 'api/apiTypes';
import { z } from 'zod';

import { NotificationLimitSchema } from './limit';
import { NotificationThresholdSchema } from './threshold';
import { NotificationTimePeriodSchema } from './timePeriod';
import { NotificationWhatSchema } from './what';
import { NotificationWhereSchema } from './where';

export const NotificationWithoutEnvGuidSchema = z.object({
  enabled: z.boolean(),
  /**
   * The name of the notification
   */
  name: z.string(),
  /**
   * The pages to look for events on
   */
  where: NotificationWhereSchema.optional(),
  /**
   * The people to notify
   */
  who: z.array(z.string().email()),
  /**
   * The time period to look for events in
   */
  within: NotificationTimePeriodSchema,
  /**
   * The events to look for
   */
  what: z.array(NotificationWhatSchema),
  /**
   * The threshold to trigger the notification
   */
  threshold: NotificationThresholdSchema,
  /**
   * The limit to the number of notifications sent
   */
  limits: NotificationLimitSchema.array().optional(),
  /**
   * The cooldown period before sending another notification
   */
  cooldown: NotificationTimePeriodSchema.optional(),
});
export type NotificationWithoutEnvGuid = z.infer<typeof NotificationWithoutEnvGuidSchema>;

// notification without envGuid and id, match with backend _id property
export const NotificationWithoutEnvGuidWithIdSchema = NotificationWithoutEnvGuidSchema.merge(
  z.object({ _id: z.string() })
);
export type NotificationWithoutEnvGuidWithId = z.infer<typeof NotificationWithoutEnvGuidWithIdSchema>;

// notifcation with envGuid
export const NotificationSchema = NotificationWithoutEnvGuidSchema.merge(z.object({ envGuid: EnvironmentIdSchema }));
type Notification = z.infer<typeof NotificationSchema>;

// notification with envGuid and id, match with backend id property
export const NotificationWithIdSchema = NotificationSchema.merge(NotificationWithoutEnvGuidWithIdSchema);
export type NotificationWithId = z.infer<typeof NotificationWithIdSchema>;

export default Notification;
