import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getNotificationRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.notifications.enabled')) return <></>;

  const NotificationsPage = lazily('notifications');
  const NotificationsCreateViewPage = lazily('notifications/createView');

  return (
    <>
      <Route path={AppRoutes.NOTIFICATIONS} element={<NotificationsPage />} />
      <Route path={AppRoutes.NOTIFICATIONS_CREATE} element={<NotificationsCreateViewPage page="create" />} />
      <Route path={AppRoutes.NOTIFICATIONS_DUPLICATE} element={<NotificationsCreateViewPage page="create" />} />
      <Route path={AppRoutes.NOTIFICATIONS_VIEW} element={<NotificationsCreateViewPage page="view" />} />
    </>
  );
};

export default getNotificationRoutes;
