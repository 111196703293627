import styled from '@emotion/styled';
import { AppBar as MuiAppBar, Theme, Toolbar } from '@mui/material';

import { drawer } from 'config/responsive';

export const ContentWrapper = styled.main(
  ({ theme }: { theme?: Theme }) => `
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ${theme?.breakpoints?.up('md')} {
      margin-left: ${drawer.widths.normal}px;
      width: calc(100% - ${drawer.widths.normal}px);
    }

    ${theme?.breakpoints?.between('sm', 'md')} {
      margin-left: ${drawer.widths.mini}px;
      width: calc(100% - ${drawer.widths.mini}px);
    }
  `
);

export const ReversedToolbar = styled(Toolbar)`
  gap: 16px;
  flex-direction: row-reverse;
`;

export const AppBar = styled(MuiAppBar)(
  ({ theme }: { theme?: Theme }) => `
    padding: 4px;
    background-color: ${theme?.palette?.background?.default};

    ${theme?.breakpoints?.up('md')} {
      width: calc(100% - ${drawer.widths.normal}px);
      margin-left: ${drawer.widths.normal}px;
    }
  `
);
