import {
  ApiSourceOfTruthCustomizationCustomRule,
  ApiSourceOfTruthCustomizationCustomRuleSchema,
  EnvironmentId,
  ReportId,
  TagId,
} from 'api/apiTypes';
import { PostfixRegex } from 'api/apiTypes/postfixLogic';
import { NotificationContext } from 'api/notifications/useNotifications';
import routes from 'api/routes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import { useContext, useEffect } from 'react';
import { ZodIssue } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthCustomizations(
  reportId: ReportId,
  tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const customizations =
    match === true
      ? sourceOfTruthContext.globalCustomizationsCache[customizationKey]
      : sourceOfTruthContext.pageCustomizationsCache[customizationKey];

  useEffect(() => {
    if (currentReport && tagId && !customizations) sourceOfTruthContext.getCustomizations(currentReport, tagId, match);
  }, [currentReport, reportId, sourceOfTruthContext, tagId, customizations, match]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(customizations)) return loading();
  if (isErrored(customizations)) return error(customizations.messages);

  return customizations;
}

export function useSourceOfTruthCustomizationsErrors(
  reportId: ReportId,
  _tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + _tagId + '-errors';

  const customizations =
    match === true
      ? sourceOfTruthContext.globalCustomizationsCache[customizationKey]
      : sourceOfTruthContext.pageCustomizationsCache[customizationKey];

  if (isLoading(currentReport)) return [];
  if (isErrored(currentReport)) return [];

  if (isLoading(customizations)) return [];
  if (isErrored(customizations)) return [];

  return customizations as any as (ZodIssue & { context?: string })[];
}

export function useCreateSourceOfTruthCustomization(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const alertingContext = useContext(NotificationContext);
  const { post } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  //const customizations = sourceOfTruthContext.customizationsCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  //if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const create = async (customization: ApiSourceOfTruthCustomizationCustomRule) => {
    const data = ApiSourceOfTruthCustomizationCustomRuleSchema.parse({
      ...customization,
      isEnabled: true,
    });
    await post(routes.sourceOfTruth.customizations.create(currentReport.summary.json.environmentId, tagId), data, {
      withCredentials: true,
    });
    const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;
    const updateState =
      data.pageUrlMatcher === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    const key: `${EnvironmentId}-${TagId}` = `${currentReport.summary.json.environmentId}-${tagId}`;
    alertingContext.groupPaths[key] = loading();
    if (data.pageUrlMatcher === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }
  };

  return create;
}

export function useUpdateSourceOfTruthCustomization(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  // const customizations = sourceOfTruthContext.customizationsCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  // if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (customization: ApiSourceOfTruthCustomizationCustomRule) => {
    const data = ApiSourceOfTruthCustomizationCustomRuleSchema.parse(customization);
    await patch(
      routes.sourceOfTruth.customizations.update(currentReport.summary.json.environmentId, tagId, data.id),
      data,
      {
        withCredentials: true,
      }
    );

    const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

    const updateState =
      data.pageUrlMatcher === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (data.pageUrlMatcher === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }
  };

  return update;
}

export function useDeleteSourceOfTruthCustomization(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteReq } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  // const customizations = sourceOfTruthContext.customizationsCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  // if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const deleteItem = async (match: string | boolean | PostfixRegex, ruleId: string, _groupPath: string) => {
    await deleteReq(
      routes.sourceOfTruth.customizations.delete(currentReport.summary.json.environmentId, tagId, ruleId),
      {
        withCredentials: true,
      }
    );

    const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

    if (match === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }

    const updateState =
      match === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
  };

  return deleteItem;
}
