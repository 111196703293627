import useLogs from 'api/logs';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import ReportId from 'api/types/report/id';
import VendorListAndInsights from 'api/types/vendors/context';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { useContext, useEffect } from 'react';

/**
 * The use vendors function returns a list of vendors and vendor insights.
 * The list is simply an object with the name and boardId, while the insights
 * is an object that contains the number of reports, their statuses, table
 * values, etc.
 */
export default function useVendors(reportId: ReportId): VendorListAndInsights | ApiError | ApiLoading {
  const { log, logObject } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useVendors');
    }
    if (!isErrored(currentReport) && currentReport?.vendors) {
      log('[API] get: vendors => CACHE HIT');
      logObject('vendors', currentReport.vendors);
    }
  }, [currentReport, context, log, logObject]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  return currentReport?.vendors;
}
