import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a LabelId.
 */
export const LabelIdSchema = z.string().brand('LabelId');

/**
 * The LabelId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects an EnvironmentId.
 */
export type LabelId = z.infer<typeof LabelIdSchema>;
