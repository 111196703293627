import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export default function EnvironmentSelectorSkeleton() {
  return (
    <Stack gap={1} width={250} data-testid="headerEnvironmentSelectorSkeleton">
      <Skeleton variant="rounded" width="80%" height={20} />
      <Skeleton variant="rounded" width="50%" height={20} />
    </Stack>
  );
}
