import { z } from 'zod';

import { ApiBoardIdSchema } from '../../boardId';

import { ApiTopFindingsJsonSummarySchema } from './summary';

export const ApiTopFindingsJsonSchema = z.object({
  boardId: ApiBoardIdSchema,
  summary: ApiTopFindingsJsonSummarySchema,
});

type ApiTopFindingsJson = z.infer<typeof ApiTopFindingsJsonSchema>;

export default ApiTopFindingsJson;
