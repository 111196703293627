import { useNextTagIds } from 'api';
import { TagId } from 'api/apiTypes';
import moment, { Moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DATE_PARAM, TAG_ID_PARAM } from 'utils/params/config';

import { useReportId } from '.';

/**
 * Returns the selected report id from the url param, or
 * user default reportid, or the first report id in the
 * users reports.
 */
export function useNextTagId(manual?: boolean): TagId {
  const reportId = useReportId();
  const date = useDatePastWeekExcludingToday();
  const tags = useNextTagIds(reportId, date, manual);
  const [params, setParams] = useSearchParams();

  const tagIdFromParams = tags.data?.find((x) => x.tagId === params.get(TAG_ID_PARAM))?.tagId;

  useEffect(() => {
    // make sure reports and users are loaded, then make the assumption that
    // the report id in the URL is not valid
    if (!tagIdFromParams && tags.data?.length && params.get(TAG_ID_PARAM) !== null) {
      setParams((prev) => (prev.delete(TAG_ID_PARAM), prev));
    }
  }, [params, setParams, tagIdFromParams, tags.data?.length]);

  const tagId = (tagIdFromParams || tags.data?.[0]?.tagId || undefined) as TagId;

  return tagId;
}

/**
 * Returns the date from the URL param and clamps it to the past week excluding today.
 */
export function useDatePastWeekExcludingToday(): Moment {
  const [params] = useSearchParams();

  const dateFromParams = params.get(DATE_PARAM);

  return useMemo(() => {
    if (dateFromParams) {
      const date = moment(dateFromParams, 'MM-DD');
      if (date.isValid()) {
        const yesterday = moment().subtract(1, 'day');
        const lastWeek = moment().subtract(1, 'week');
        if (date.isBefore(lastWeek)) {
          return lastWeek;
        } else if (date.isAfter(yesterday)) {
          return yesterday;
        } else {
          return date;
        }
      } else {
        return moment().subtract(1, 'day');
      }
    } else {
      return moment().subtract(1, 'day');
    }
  }, [dateFromParams]);
}
