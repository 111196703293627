import { z } from 'zod';

import { PartialReportSchema } from '../../report/partialReport';

/** The user object returned from the API. */
export const ApiUserSchema = z.object({
  id: z.string().uuid(),
  last_name: z.string(),
  first_name: z.string(),
  organization: z.string(),
  email: z.string().email(),
  reports: z.array(PartialReportSchema),
  defaultEnvironment: z.union([z.string().uuid(), z.literal(false)]),
  role: z.union([z.literal('admin'), z.literal('viewer')]).optional(),
  is_power_user: z.boolean().optional(),
  featureFlags: z.record(z.boolean()).optional(),
});

/** The user object returned from the API. */
type ApiUser = z.infer<typeof ApiUserSchema>;

export default ApiUser;
