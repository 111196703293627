import { z } from 'zod';

export const NotificationThresholdSchema = z.object({
  unit: z.enum(['count', 'percent']),
  value: z.number().gte(0).int(),
  of: z.enum(['pageview', 'visitor', 'session']).optional(),
});

type NotificationThreshold = z.infer<typeof NotificationThresholdSchema>;

export default NotificationThreshold;
