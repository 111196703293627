import { CellTower, Rule, Token, Tv } from '@mui/icons-material';
import React from 'react';

type MiddlewareEventOptions = {
  name: string;
  namespace: string;
  path: string;
  method?: string;
  parent?: string;
  source?: 'network' | 'middleware' | 'validate' | 'view';
  status?: 'initialized' | 'processing' | 'completed' | 'failed';
  data?: any;
};

export class MiddlewareEventHandler {
  // private static events: MiddlewareEvent[] = [];

  private static eventStore: Record<string, MiddlewareEvent | undefined> = {};

  public static getEvents() {
    return Object.values(this.eventStore);
  }

  public static addEvent(event: MiddlewareEvent) {
    this.eventStore[event.id] = event;
  }

  public static getEvent(id: string) {
    return this.eventStore[id];
  }
}

export class MiddlewareEvent {
  public static handler = MiddlewareEventHandler;
  path: MiddlewareEventOptions['path'];
  method: MiddlewareEventOptions['method'];
  namespace: MiddlewareEventOptions['namespace'];
  name: MiddlewareEventOptions['name'];
  source: MiddlewareEventOptions['source'];
  status: MiddlewareEventOptions['status'];
  parent?: MiddlewareEvent;
  data?: any;

  // results
  resultText?: string;

  private readonly _id: string;
  private readonly _created: Date = new Date();

  constructor({ namespace, name, path, source, status, method, parent, data }: MiddlewareEventOptions) {
    this._id = window.crypto.randomUUID();
    this.path = path;
    this.namespace = namespace;
    this.name = name;
    this.method = method;
    this.status = status ?? 'initialized';
    this.source = source ?? 'middleware';
    this.parent = parent ? MiddlewareEventHandler.getEvent(parent) : undefined;
    this.data = data;

    MiddlewareEvent.handler.addEvent(this);
  }

  get id() {
    return this._id;
  }

  get created() {
    return this._created;
  }

  toString() {
    let sourceFriendly = '';
    switch (this.source) {
      case 'middleware':
        sourceFriendly = 'Middleware';
        break;
      case 'network':
        sourceFriendly = 'Network';
        break;
      case 'validate':
        sourceFriendly = 'Validate';
        break;
      case 'view':
        sourceFriendly = 'View';
        break;
    }
    return `${sourceFriendly}, ${this.namespace} ${this.name}`;
  }

  uiGetAction() {
    let verbString = this.path;
    if (this.method) {
      verbString = `${this.method} ${verbString}`;
    }
    return verbString;
  }

  uiGetResult() {
    if (this.resultText) {
      return this.resultText;
    }
    return 'No data returned';
  }

  uiGetIcon() {
    switch (this.source) {
      case 'middleware':
        return <Token />;
      case 'network':
        return <CellTower />;
      case 'validate':
        return <Rule />;
      case 'view':
        return <Tv />;
    }
  }

  uiGetStatus() {
    switch (this.status) {
      case 'initialized':
        return 'Initialized';
      case 'processing':
        return 'Processing';
      case 'completed':
        return 'Completed';
      case 'failed':
        return 'Failed';
    }
  }
}

type MiddlewareErrorOptions = {
  cause: MiddlewareError | Error;
  viewType?: string;
  event: MiddlewareEvent;
};

export class MiddlewareError extends Error {
  viewType: string;
  event: MiddlewareEvent;
  constructor({ cause, viewType, event }: MiddlewareErrorOptions) {
    super('');
    this.name = 'MiddlewareError';

    this.cause = cause;
    this.event = event;
    this.viewType = viewType ?? 'page';
    this.message = event.uiGetResult();
  }
}

window.MiddlewareEventHandler = MiddlewareEventHandler;
window.MiddlewareEvent = MiddlewareEvent;
window.MiddlewareError = MiddlewareError;
