import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';
import { withoutProps } from 'utils';

export const TextField = styled(
  MuiTextField,
  withoutProps('removeBorder')
)(({ removeBorder }: { removeBorder?: boolean }) =>
  removeBorder
    ? `
      .MuiInputBase-root {
        &:before,
        &:hover:not(#a):before {
          border-width: 0;
        }
        &.Mui-focused .MuiInput-input {
          background: none;
        }
      }
  `
    : ''
);
