export const AllOperands = {
  equalsCI: 'equalsCI',
  containsCI: 'containsCI',
  startsWithCI: 'startsWithCI',
  endsWithCI: 'endsWithCI',
  isOneOfCI: 'isOneOfCI',
  isTypeOfCI: 'isTypeOfCI',
  isTruthy: 'isTruthy',
  isFalsy: 'isFalsy',
  greaterThan: 'greaterThan',
  greaterThanEquals: 'greaterThanEquals',
  lessThan: 'lessThan',
  lessThanEquals: 'lessThanEquals',
  matchesRegex: 'matchesRegex',

  // Not Case Insensitive
  doesNotEqualCI: 'doesNotEqualCI',
  doesNotStartWithCI: 'doesNotStartWithCI',
  doesNotContainCI: 'doesNotContainCI',
  doesNotEndWithCI: 'doesNotEndWithCI',
  isNotOneOfCI: 'isNotOneOfCI',
  isNotTypeOfCI: 'isNotTypeOfCI',

  // Case Sensitives
  equals: 'equals',
  contains: 'contains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isOneOf: 'isOneOf',
  isTypeOf: 'isTypeOf',

  // Not
  doesNotEqual: 'doesNotEqual',
  doesNotContain: 'doesNotContain',
  doesNotStartWith: 'doesNotStartWith',
  doesNotEndWith: 'doesNotEndWith',
  isNotOneOf: 'isNotOneOf',
  isNotTypeOf: 'isNotTypeOf',
  doesNotMatchRegex: 'doesNotMatchRegex',
} as const;

export const StringOperands = {
  equalsCI: 'equalsCI',
  containsCI: 'containsCI',
  startsWithCI: 'startsWithCI',
  endsWithCI: 'endsWithCI',
  isOneOfCI: 'isOneOfCI',
  matchesRegex: 'matchesRegex',

  // Not Case Insensitive
  doesNotEqualCI: 'doesNotEqualCI',
  doesNotStartWithCI: 'doesNotStartWithCI',
  doesNotContainCI: 'doesNotContainCI',
  doesNotEndWithCI: 'doesNotEndWithCI',
  isNotOneOfCI: 'isNotOneOfCI',

  // Case Sensitives
  equals: 'equals',
  contains: 'contains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isOneOf: 'isOneOf',

  // Not
  doesNotEqual: 'doesNotEqual',
  doesNotContain: 'doesNotContain',
  doesNotStartWith: 'doesNotStartWith',
  doesNotEndWith: 'doesNotEndWith',
  isNotOneOf: 'isNotOneOf',
  doesNotMatchRegex: 'doesNotMatchRegex',
} as const;

export const NormalOperands = {
  equalsCI: 'equalsCI',
  containsCI: 'containsCI',
  startsWithCI: 'startsWithCI',
  endsWithCI: 'endsWithCI',
  isOneOfCI: 'isOneOfCI',
  isTypeOfCI: 'isTypeOfCI',
  isTruthy: 'isTruthy',
  isFalsy: 'isFalsy',
  greaterThan: 'greaterThan',
  greaterThanEquals: 'greaterThanEquals',
  lessThan: 'lessThan',
  lessThanEquals: 'lessThanEquals',
  matchesRegex: 'matchesRegex',
} as const;

export const NormalNotOperands = {
  doesNotEqualCI: 'doesNotEqualCI',
  doesNotStartWithCI: 'doesNotStartWithCI',
  doesNotContainCI: 'doesNotContainCI',
  doesNotEndWithCI: 'doesNotEndWithCI',
  isNotOneOfCI: 'isNotOneOfCI',
  isNotTypeOfCI: 'isNotTypeOfCI',
} as const;

export const CaseSensitiveOperands = {
  equals: 'equals',
  contains: 'contains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isOneOf: 'isOneOf',
  isTypeOf: 'isTypeOf',
} as const;

export const CaseSensitiveNotOperands = {
  doesNotEqual: 'doesNotEqual',
  doesNotContain: 'doesNotContain',
  doesNotStartWith: 'doesNotStartWith',
  doesNotEndWith: 'doesNotEndWith',
  isNotOneOf: 'isNotOneOf',
  isNotTypeOf: 'isNotTypeOf',
  doesNotMatchRegex: 'doesNotMatchRegex',
} as const;
