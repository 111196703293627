import ApiScore from 'api/types/report/api/score';
import ReportId from 'api/types/report/id';
import Score from 'api/types/report/score';

import { BuildInsightURL } from 'config/app-routes';

export default function transformScore(score: ApiScore[], reportId: ReportId): Score[] {
  return score.map((score) => ({
    ...score,
    href: BuildInsightURL({ reportId, category: score.name }),
  }));
}
