import React, { createContext } from 'react';

import SourceOfTruthContextType from '../types/context';

export const SourceOfTruthContext = createContext({} as SourceOfTruthContextType);

export function addRecord<D, T, M extends string | number | symbol>(
  id: M,
  data: D,
  setState: React.Dispatch<React.SetStateAction<Record<M, D | T>>>
) {
  if (typeof setState === 'function') setState((prev) => ({ ...prev, [id]: data }));
}

function _removeRecord<M extends string | number | symbol>(
  id: M,
  setState: React.Dispatch<React.SetStateAction<Record<M, unknown>>>
) {
  if (typeof setState === 'function')
    setState((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest as any;
    });
}
