import styled from '@emotion/styled';
import { Stack, Theme } from '@mui/material';

const HorizontalFieldGroup = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${theme?.spacing(2)};

  & > .MuiButton-root {
    flex-shrink: 0;
  }

  & > * {
    flex-basis: fit-content;
  }

  & > .MuiTextField-root {
    min-width: 200px;
  }
`
);

export default HorizontalFieldGroup;
