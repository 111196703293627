import { ApiBoardIdSchema } from 'api/types/report/api/boardId';
import { z } from 'zod';

/**
 * This is the type for the vendor object returned from the API.
 */
export const ApiPrivacyInsightsVendorSchema = z.object({
  title: z.string(),
  boardId: ApiBoardIdSchema,
});

/** This is the type for the vendor object returned from the API. */
type ApiPrivacyInsightsVendor = z.infer<typeof ApiPrivacyInsightsVendorSchema>;

export default ApiPrivacyInsightsVendor;
