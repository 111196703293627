import styled from '@emotion/styled';
import { Badge as MuiBadge, Chip as MuiChip, Theme } from '@mui/material';
import { withoutProps } from 'utils';

export const Badge = styled(MuiBadge)(
  ({ theme }: { theme?: Theme }) => `
    .MuiBadge-badge {
      border: 1px solid ${theme?.palette?.primary?.contrastText};
      background-color: ${theme?.palette?.primary?.main};
      color: ${theme?.palette?.primary?.contrastText};
    }
  `
);

export const Chip = styled(
  MuiChip,
  withoutProps('selected', 'large')
)(
  ({ theme, selected, large }: { theme?: Theme; selected: boolean; large: boolean }) => `
    cursor: pointer;
    ${large ? 'height: 64px;' : ''}
    background-color: rgba(0,0,0,0.001);
    ${
      selected
        ? `
      ${
        /*
          Extend base transition
          https://github.com/mui/material-ui/blob/master/packages/mui-material/src/Chip/Chip.js#L94
          transition: theme.transitions.create(['background-color', 'box-shadow']),
        */
        'transition-property: background-color, box-shadow, border, color;'
      }
      color: ${theme?.palette?.primary?.contrastText};
      border: 1px solid ${theme?.palette?.primary.main};
      background-color: ${theme?.palette?.primary.main};

      &:hover {
        background-color: ${theme?.palette?.primary?.dark};
      }
    `
        : `
      border: 1px solid ${theme?.palette?.divider};

      &:hover {
        background-color: ${theme?.palette?.divider};
      }
    `
    }
  `
);
