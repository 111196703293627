import { ReportId, TagId } from 'api/apiTypes';
import { ApiSourceOfTruthCustomizationCustomMatchCountSchema } from 'api/apiTypes/sourceOfTruth/customMatchCount';
import routes from 'api/routes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { Moment } from 'moment';
import { useContext, useEffect } from 'react';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthSummary(reportId: ReportId, tagId: TagId, date: Moment) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const summary = sourceOfTruthContext.summaryCache[tagId];

  useEffect(() => {
    if (reportId && !summary) sourceOfTruthContext.getSummary(currentReport, tagId, date);
  }, [currentReport, reportId, sourceOfTruthContext, tagId, summary, date]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(summary)) return loading();
  if (isErrored(summary)) return error(summary.messages);

  return summary;
}

export function usePruneSourceOfTruthSummary(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const summary = sourceOfTruthContext.summaryCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(summary)) return loading();

  const pruneSummary = async () => {
    sourceOfTruthContext.pruneSummary(currentReport, tagId);
  };

  return pruneSummary;
}

export function useCountSourceOfTruthCustomRules(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const { get } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  const envGuid = currentReport.summary.json.environmentId;

  const countCustomRules = async (groupPath: string) => {
    const payload = await get(routes.sourceOfTruth.getCustomMatchCount(envGuid, tagId, groupPath), {
      withCredentials: true,
    });
    const data = ApiSourceOfTruthCustomizationCustomMatchCountSchema.parse(payload.data);
    return data;
  };

  return countCustomRules;
}
