import { z } from 'zod';

/**
 * String number schema, used for numbers that are stored as strings.
 * @example
 * '123'
 * @example
 * '0'
 */
export const StringNumberSchema = z.string().regex(/^[0-9]+$/);

// type StringNumber = z.infer<typeof StringNumberSchema>;
type StringNumber = `${number}`;

export default StringNumber;
