import { z } from 'zod';

import { NotificationTimePeriodSchema } from './timePeriod';

export const NotificationLimitSchema = z.object({
  count: z.number().int().positive(),
  within: NotificationTimePeriodSchema,
});

type NotificationLimit = z.infer<typeof NotificationLimitSchema>;

export default NotificationLimit;
