import { customizationTypesKeys } from 'pages/internal/admin/components/SourceOfTruthModalPage/components/AddCustomizationModal';

const CustomizationType: Record<(typeof customizationTypesKeys)[number], string> = {
  create: 'Create Rule',
  exclude: 'Exclude Variable',
  add: 'Add Values',
  remove: 'Remove Values',
};

export default CustomizationType;
