import { Typography, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';

import type { SwissWidgetProps } from '.';
import * as Styled from './SwissWidget.styles';

/**
 * Hooks for SwissWidget logic, simply does null checks and returns the correct values
 * @param {SwissWidgetProps} props
 */
export function useSwissWidget(props: SwissWidgetProps) {
  const { icon: customIcon } = props;
  const { passed, variant, title, header, footer, icon, children, headerFooterColor, ...rest } =
    ApplyDefaultProps(props);

  const titleVariant = variant === 'small' ? 'h6' : 'h4';

  return {
    passed,
    ...rest,
    variant,
    children,
    title: IsString(title) ? <Typography variant={titleVariant}>{title}</Typography> : title,
    icon: (passed || customIcon) && <Styled.IconContainer>{icon}</Styled.IconContainer>,
    footer: IsString(footer) ? <Styled.Caption color={headerFooterColor}>{footer}</Styled.Caption> : footer,
    header: IsString(header) ? <Styled.Caption color={headerFooterColor}>{header}</Styled.Caption> : header,
  };
}

//#region helpers
const IsString = (value: React.ReactNode) => typeof value === 'string';

function ApplyDefaultProps(props: SwissWidgetProps): SwissWidgetProps {
  const theme = useTheme();

  const { passed, variant, backgroundColor, textColor, title, header, footer, icon, children, ...rest } = props;

  return {
    passed,
    ...rest,
    title: title ?? '',
    header: header ?? '',
    footer: footer ?? '',
    variant: variant ?? 'normal',
    children: children ?? null,
    icon: icon ?? <Styled.SuccessIcon />,
    textColor: textColor ?? (passed ? green[900] : theme.palette.text.primary),
    backgroundColor: backgroundColor ?? theme.palette.background.paper,
  };
}
//#endregion
