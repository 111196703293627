import styled from '@emotion/styled';

export const Paper = styled.div(
  ({ theme }: any) => `
      margin-top: ${theme.spacing(8)};
      display: flex;
      flex-direction: column;
      align-items: stretch;`
);

export const Avatar = styled.img(
  () => `
      width: 50%;margin: auto;
      padding-bottom:10px;
      `
);
