import React from 'react';

/**
 * Represents custom hook for header
 * @memberof Header
 * @function
 * @name useHeader
 * @return {function} onclick event handlers and state changes
 */
export const useHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /**
   * onclick profile icon in header
   * @memberof Header
   * @function
   * @name onPressProfile
   * @return {void}
   */
  const onPressProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * onclick logout in header
   * @memberof Header
   * @function
   * @name handleClose
   * @return {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  return {
    open,
    anchorEl,
    handleClose,
    onPressProfile,
  };
};
