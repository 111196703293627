//import useExperiments from 'api/experiments';
import { ApiInsightLabel, ApiInsightLabelSchema } from 'api/apiTypes';
import useExperiments from 'api/experiments';
import useLogs from 'api/logs';
import useRawApiResponse from 'api/raw';
import Routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import Report from 'api/types/report';
import ApiReport, { ApiReportSchema } from 'api/types/report/api';
import ApiStatus, { ApiStatusSchema } from 'api/types/report/api/status';
import type ReportContextType from 'api/types/report/context';
import ReportId from 'api/types/report/id';
import { CurrentUserContext } from 'api/useCurrentUser';
import { error, isErrored, isLoading, loading } from 'api/utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ZodError } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import transformScore from './transformers/score';
import transformTopFindings from './transformers/topFindings';
import transformVendors from './transformers/vendors';

export const ReportContext = React.createContext({} as ReportContextType);

export function ReportProvider({ children }: React.PropsWithChildren) {
  const { get } = useAxios();
  const { log, logObject } = useLogs();
  const { isExperimentEnabled, setExperimentEnabled } = useExperiments();

  // raw store
  const rawStore = useRawApiResponse();

  const [hasConsumer, setHasConsumer] = useState(false);
  /** Actual report data */
  const [reportCache, setReportCache] = useState<ReportContextType['reportCache']>({});
  /** Set of reportids that are currently cached */
  const reportIds = useRef(new Set<string>());

  const currentUserContext = useContext(CurrentUserContext);

  const cacheReport = useCallback(
    (report: ReportContextType['reportCache'][ReportId], reportId: ReportId) =>
      setReportCache((prev) => ({
        ...prev,
        [reportId]: report,
      })),
    []
  );

  const pruneReport = useCallback((reportId: ReportId) => {
    setReportCache((prev) => {
      const { [reportId]: _, ...rest } = prev;
      return rest;
    });
    reportIds.current.delete(reportId);
  }, []);

  const getReport = (reportId: ReportId) => {
    const partialReport = currentUserContext.reports.find((report) => report.id === reportId);
    if (!partialReport || reportIds.current.has(reportId)) return;
    if (isLoading(currentUserContext.user) || isErrored(currentUserContext.user)) return;
    reportIds.current.add(reportId);
    const route = Routes.getReport(reportId);

    log('[API] get: report => CACHE MISS');
    log(`[FETCH] begin: ${route}`, 'High');

    get(route, {
      withCredentials: true,
    })
      .then(async (response) => {
        logObject('rawReport', response.data);
        if (!response.data) throw new Error();
        log(`[FETCH] success: ${route}`, 'Medium');

        try {
          try {
            rawStore[reportId] = {
              ...rawStore[reportId],
              reportData: response.data,
            };
          } catch (_) {}
          const apiReport = ApiReportSchema.parse(response.data);

          if (apiReport.summary.json.featureFlags) {
            try {
              Object.entries(apiReport.summary.json.featureFlags).map(([key, value]) => {
                if (key.startsWith('pages.')) {
                  setExperimentEnabled(`${key}.enabled`, value);
                  setExperimentEnabled(`${key}.visible`, value);
                } else {
                  setExperimentEnabled(key, value);
                }
              });
            } catch {}
          }

          // TODO: this makes sure that the privacyEnabled flag is only used to ENABLE
          // the functionality, but not to disable it. The new flag system fixes this.
          if (!isExperimentEnabled('pages.privacy.enabled')) {
            setExperimentEnabled(
              'pages.privacy.enabled',
              Boolean(apiReport?.summary.json.privacyEnabled) // || isExperimentEnabled('user.isInternal')
            );
          }

          const labels: ApiInsightLabel[] = [];

          // also query labels=
          if (isExperimentEnabled('feature.insight.labels')) {
            try {
              const labelResponse = await get(Routes.labels.get(apiReport.summary.json.environmentId), {
                withCredentials: true,
              });
              const labelData = ApiInsightLabelSchema.array().parse(labelResponse.data);
              labels.push(...labelData);
            } catch (e) {
              log(`[FETCH] no-data: get labels ${e}`, 'Medium');
            }
          }

          const report = transformReport(apiReport, reportId, partialReport.name, labels);
          cacheReport(report, reportId);
          /*setExperimentEnabled(
            'pages.privacy.enabled',
            Boolean(report.summary.json.privacyEnabled) // || isExperimentEnabled('user.isInternal')
          );*/
          //setExperimentEnabled('pages.notifications.visible', isExperimentEnabled('user.isInternal'));
          //setExperimentEnabled('pages.notifications.enabled', isExperimentEnabled('user.isInternal'));
        } catch (_e: unknown) {
          const e = _e as ZodError;
          log(`[ZOD] error: report => MALFORMED DATA`, 'Error');
          logObject(`[ZOD] errorsof: report`, e.errors);
          cacheReport(error(), reportId);
          try {
            rawStore[reportId] = {
              ...rawStore[reportId],
              reportErrors: e,
            };
          } catch (_) {}
        }
      })
      .catch(() => {
        log(`[FETCH] no-data: ${route}`, 'Medium');
        cacheReport(error(), reportId);
      });
  };

  const value: ReportContextType = {
    getReport,
    hasConsumer,
    pruneReport,
    reportCache,
    setHasConsumer,
    setReportCache,
    //currentReportId,
  };

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
}

const ensureStatus = (status?: string): status is ApiStatus => ApiStatusSchema.safeParse(status).success;

function trimImproperStatusInsights<T extends { status?: string }>(
  insights: Record<string, T>
): Record<string, Omit<T, 'status'> & { status: ApiStatus }> {
  const filteredInsights: Record<string, Omit<T, 'status'> & { status: ApiStatus }> = {};

  for (const [key, insight] of Object.entries(insights)) {
    if (!ensureStatus(insight.status)) continue;
    filteredInsights[key] = { ...insight, status: insight.status };
  }

  return filteredInsights;
}

function transformReport(
  report: Exclude<ApiReport, ApiError | ApiLoading>,
  id: ReportId,
  name: string,
  labels: ApiInsightLabel[]
): Exclude<Report, ApiError | ApiLoading> {
  const { 0: apiVendors, vendor, topFindings, insight, score, summary, ...rest } = report;

  const newSummary = {
    ...summary,
    json: {
      ...summary.json,
      privacyEnabled: true,
    },
  };

  const newInsights = trimImproperStatusInsights(insight);

  return {
    id,
    name,
    ...rest,
    summary: newSummary,
    labels,
    score: transformScore(score ?? [], id),
    vendors: transformVendors(apiVendors, vendor, newInsights, id),
    topFindings: transformTopFindings(topFindings, id),
    /* 
      Since we know that ApiReport['insight'] is a [x: string] dictionary
      we can safely cast it to a Report['insight'] dictionary because
      the report is more specific than the api report.
    */
    insight: newInsights,
  };
}

/**
 * Although the middleware is not meant to provide state management for
 * frontend components, the useReport hook does have a shallow "memory"
 * of which report was viewed last. This is important because it needs
 * to have the report cached either way, and having the frontend provide
 * the environment GUID everytime would be a bit too tedious. This way
 * the frontend can simply invoke the hook without arguments to get the
 * latest cached report. Invoking the hook with an environment GUID will
 * cause the hook to fetch the report from the backend and cache it for
 * later use; however, this process is extrememly similar to how an app
 * wide state management library would work and should not be considered
 * as such.
 *
 * @example
 * const report = useReport();
 * // The above example will return the latest cached report.
 *
 * @example
 * const report = useReport('environment-guid');
 * // The above example will fetch the report from the backend and cache it.
 */
export default function useReport(reportId: ReportId): Report | ApiError | ApiLoading {
  const { log, logObject } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (reportId) context.getReport(reportId);
  }, [reportId, context]);

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useReport');
    }
    if (currentReport) {
      log('[API] get: report => CACHE HIT');
      logObject('report', currentReport);
    }
  }, [currentReport, context, log, logObject]);

  return currentReport;
}

/** Explicitly remove a report from the cache, i.e. refetch */
export function usePruneReport(): ReportContextType['pruneReport'] {
  const context = useContext(ReportContext);

  return context.pruneReport;
}
