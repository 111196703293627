import { z } from 'zod';

export const ApiSourceOfTruthCustomizationValuesToIgnoreSchema = z.object({
  groupPath: z.string(),
  values: z.array(z.any()).optional(),
  match: z.union([z.string(), z.boolean()]).optional(),
  localGroupPathMap: z.record(z.string()).optional(),
  groupPathFriendlyName: z.string().optional(),
});

export type ApiSourceOfTruthCustomizationValuesToIgnore = z.infer<
  typeof ApiSourceOfTruthCustomizationValuesToIgnoreSchema
>;
