import Log from 'api/types/log';
import React, { useCallback } from 'react';

declare global {
  interface Window {
    ApiLogs: Log[];
    ApiObjects: Record<string, object>;
  }
}

export type LogContextType = {
  logs: Log[];
  log: (value: any, level?: 'Normal' | 'Medium' | 'High' | 'Error') => void;
  logObject: (title: string, object: any, level?: 'Normal' | 'Medium' | 'High' | 'Error') => void;
};

export const LogContext = React.createContext({} as LogContextType);

window.ApiLogs = [];
window.ApiObjects = {};

export function LogProvider({ children }: React.PropsWithChildren) {
  //const [logs, setLogs] = React.useState<Log[]>([]);

  const log: LogContextType['log'] = useCallback((value) => {
    /*const color =
        level === 'Error' ? '#ff0000' : level === 'High' ? '#cc33cc' : level === 'Medium' ? '#800080' : '#0099ee';
      */
    /*logs.push({
        value,
        date: new Date(),
      });
      */
    window.ApiLogs.push({
      value,
      date: new Date(),
    });
    //setLogs(logs);
    // eslint-disable-next-line no-console
    //console.debug('%c' + value, `color: ${color}`);
  }, []);

  const logObject: LogContextType['logObject'] = useCallback(
    // eslint-disable-next-line
    (title, object) => {
      window.ApiLogs.push({
        value: title,
        date: new Date(),
      });
      window.ApiObjects[title] = object;
      //const color = level === 'High' ? '#cc33cc' : level === 'Medium' ? '#800080' : '#0099ee';
      /*logs.push({
        value: title,
        date: new Date(),
      });
      setLogs(logs);
      */
      /*
      // eslint-disable-next-line no-console
      const customClass = eval(`
      let __tempObj = {
        "${title}": class {}
      };
      __tempObj["${title}"];
      `);
      const obj = new customClass();
      obj.__proto__ = object;
      // eslint-disable-next-line no-console
      console.debug(obj);
      */
    },
    []
  );

  return <LogContext.Provider value={{ logs: [], log, logObject }}>{children}</LogContext.Provider>;
}

export default function useLogs() {
  return React.useContext(LogContext);
}
