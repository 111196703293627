import ReportId from 'api/types/report/id';

import { appendLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from './localstorage';

const INTERNAL_KEY = '_internal';

const FEATURE_FLAGS_KEY = 'featureFlags';
const OVERLAYS_KEY = 'overlays';
const SOURCE_OF_TRUTH = 'sourceOfTruth';
const ENVIRONMENT_SELECTOR = 'environmentSelector';

const OVERLAYS_OVERRIDE = [INTERNAL_KEY, OVERLAYS_KEY].join('.');
const FEATURE_FLAGS_OVERRIDE = [INTERNAL_KEY, FEATURE_FLAGS_KEY].join('.');
const SOURCE_OF_TRUTH_OVERRIDE = [INTERNAL_KEY, SOURCE_OF_TRUTH].join('.');

const OVERRIDES_KEY = 'overrides';
const PRIVACY_ENABLED_KEY = 'privacyEnabled';
const FAVORITED_TAGS = 'favoritedTags';

const PRIVACY_ENABLED_OVERRIDE = [INTERNAL_KEY, OVERRIDES_KEY, PRIVACY_ENABLED_KEY].join('.');
const FAVORITED_TAGS_OVERRIDE = [INTERNAL_KEY, SOURCE_OF_TRUTH_OVERRIDE, FAVORITED_TAGS].join('.');
const ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE = [INTERNAL_KEY, ENVIRONMENT_SELECTOR, FAVORITED_TAGS].join('.');

export type PrivacyOverrides = {
  [reportId: ReportId]: boolean;
};

/** Gets a reportId's privacy enabled override */
export const getPrivacyEnabledOverride = (reportId: ReportId): boolean | undefined =>
  getLocalStorageItem<PrivacyOverrides>(PRIVACY_ENABLED_OVERRIDE)?.[reportId];

/** Sets a reportId's privacy enabled override */
export const setPrivacyEnabledOverride = (reportId: ReportId, enabled: boolean): void =>
  appendLocalStorageItem<PrivacyOverrides>(PRIVACY_ENABLED_OVERRIDE, { [reportId]: enabled });

/** Removes a reportId's privacy enabled override */
export const removePrivacyEnabledOverride = (reportId: ReportId): void =>
  appendLocalStorageItem<PrivacyOverrides>(PRIVACY_ENABLED_OVERRIDE, { [reportId]: undefined as any });

/** Get list of enabled overlay id's */
export const getEnabledOverlays = () => getLocalStorageItem<string[]>(OVERLAYS_OVERRIDE, '[]');

/** Add a new overlay id to show */
export const addEnabledOverlay = (overlay: string) =>
  setLocalStorageItem(OVERLAYS_OVERRIDE, [...getEnabledOverlays(), overlay]);

/** Remove an enabled overlay's id */
export const removeEnabledOverlay = (overlay: string) =>
  setLocalStorageItem(
    OVERLAYS_OVERRIDE,
    getEnabledOverlays().filter((o) => o !== overlay)
  );

/** feature flags */
export const setEnabledFeatureFlags = (featureFlags: string[]) => {
  setLocalStorageItem(FEATURE_FLAGS_OVERRIDE, featureFlags);
};

export const getEnabledFeatureFlags = () => getLocalStorageItem<string[]>(FEATURE_FLAGS_OVERRIDE, '[]');

/** favorited sourceOfTruth trags */
export const addFavoriteTag = (envGuid: string, tag: string) => {
  let current = getLocalStorageItem<string[]>(FAVORITED_TAGS_OVERRIDE, '[]');
  if (!Array.isArray(current)) {
    setLocalStorageItem(FAVORITED_TAGS_OVERRIDE, []);
    current = [];
  }
  const newTags = [...current, `${envGuid}:${tag}`];
  setLocalStorageItem(FAVORITED_TAGS_OVERRIDE, newTags);
};

export const removeFavoriteTag = (envGuid: string, tag: string) => {
  const current = getLocalStorageItem<string[]>(FAVORITED_TAGS_OVERRIDE, '[]');
  if (!Array.isArray(current)) {
    setLocalStorageItem(FAVORITED_TAGS_OVERRIDE, []);
    return;
  }
  const newTags = current.filter((t) => t !== `${envGuid}:${tag}`);
  setLocalStorageItem(FAVORITED_TAGS_OVERRIDE, newTags);
};

export const isTagFavorited = (envGuid: string, tag: string) => {
  let current = getLocalStorageItem<string[]>(FAVORITED_TAGS_OVERRIDE, '[]');
  if (!Array.isArray(current)) {
    setLocalStorageItem(FAVORITED_TAGS_OVERRIDE, []);
    current = [];
  }
  return Boolean(~current?.indexOf(`${envGuid}:${tag}`));
};

export const addFavoriteEnvironment = (name: string) => {
  let current = getLocalStorageItem<string[]>(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, '[]');
  if (!Array.isArray(current)) {
    setLocalStorageItem(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, []);
    current = [];
  }
  const newTags = [...current, name];
  setLocalStorageItem(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, newTags);
};

export const removeFavoriteEnvironment = (name: string) => {
  const current = getLocalStorageItem<string[]>(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, '[]');
  if (!Array.isArray(current)) {
    setLocalStorageItem(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, []);
    return;
  }
  const newTags = current.filter((t) => t !== name);
  setLocalStorageItem(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, newTags);
};

export const isEnvironmentFavorited = (name: string) => {
  let current = getLocalStorageItem<string[]>(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE);
  if (!Array.isArray(current)) {
    setLocalStorageItem(ENVIRONMENT_SELECTOR_FAVORITED_TAGS_OVERRIDE, []);
    current = [];
  }
  return Boolean(~current?.indexOf(name));
};
