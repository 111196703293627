import { ArrowBack, Home } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React, { startTransition } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" textAlign="center">
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">The page you are looking for could not be found.</Typography>
      <br />
      <Stack direction="row" spacing={4}>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => {
            startTransition(() => navigate(-1));
          }}
        >
          Go Back
        </Button>
        <Button href="/" variant="contained" startIcon={<Home />}>
          Go Home
        </Button>
      </Stack>
    </Stack>
  );
}
