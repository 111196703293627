import ApiError from 'api/types/base/apiError';
import { Context, useContext, useEffect, useRef } from 'react';

import { error } from './apiState';

/**
 * A use effect hook that only runs once but allows dependencies to be passed in
 * useful for functions that need to only run once conditionaly after some time.
 */
export function once(callback: React.EffectCallback, deps?: React.DependencyList | undefined, shouldRun = true) {
  const ref = useRef(false);
  useEffect(() => {
    if (!ref.current && shouldRun) {
      ref.current = true;
      return callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? []), shouldRun]);
}

export function useExperimentalContext<T>(context: Context<T>): T | ApiError {
  const value = useContext(context);
  const errorObject = error(['Experimental context is not enabled']);

  if (Array.isArray(value) && value.length > 0) {
    return value;
  } else if (typeof value === 'object' && Object.keys(value ?? {}).length > 0) {
    return value;
  }
  // add more types as needed
  return errorObject;
}
