import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getTechnologyRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.technology.enabled')) return <></>;

  const TechnologyPage = lazily('technology');

  return (
    <>
      <Route path={AppRoutes.TECHNOLOGY} element={<TechnologyPage />} />
    </>
  );
};

export default getTechnologyRoutes;
