import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getAdminRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.admin.enabled')) return <></>;

  const AdminPage = lazily('admin');
  return (
    <>
      <Route path={AppRoutes.ADMIN} element={<AdminPage />} />
    </>
  );
};

export default getAdminRoutes;
