import { SupportedOperandsSchema } from 'api/apiTypes';
import { z } from 'zod';

export const ApiPrivacyConsentConfigurationCategoriesSchema = z.object({
  category: z.string(),
  operator: z.union([SupportedOperandsSchema, z.literal('customFunction')]),
  value: z.string(),
});

type ApiPrivacyConsentConfigurationCategories = z.infer<typeof ApiPrivacyConsentConfigurationCategoriesSchema>;

export default ApiPrivacyConsentConfigurationCategories;
