import styled from '@emotion/styled';
import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { blue, green, red, yellow } from '@mui/material/colors';
import PureLink from 'components/PureLink';
import React from 'react';
import { withoutProps } from 'utils';

//#region styles
const Card = styled(Stack, withoutProps('type'))`
  color: white;
  width: fit-content;
  padding: 16px 12px;
  border-radius: 16px;
  min-width: 125px;
  background-color: ${({ type }: { type: keyof typeof TYPES }) => TYPES[type].background};
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`;

const CardContent = styled(Stack)``;

// Icons
const baseIconStyle = `
  width: 32px;
  height: 32px;
`;

const ErrorIcon = styled(Error)`
  ${baseIconStyle}
  color: ${red[200]};
`;

const InfoIcon = styled(Info)`
  ${baseIconStyle}
  color: ${blue[200]};
`;

const SuccessIcon = styled(CheckCircle)`
  ${baseIconStyle}
  color: ${green[200]};
`;

const WarningIcon = styled(Warning)`
  ${baseIconStyle}
  color: ${yellow[500]};
`;
//#endregion

//#region constants
const TYPES = {
  error: {
    background: red[900],
    caption: 'Critical Issues',
    icon: <ErrorIcon />,
  },
  info: {
    background: blue[900],
    caption: 'Information',
    icon: <InfoIcon />,
  },
  success: {
    background: green[900],
    caption: 'Success',
    icon: <SuccessIcon />,
  },
  warning: {
    background: yellow[900],
    caption: 'Needs Review',
    icon: <WarningIcon />,
  },
};
//#endregion

/**
 * Represents a widget that shows a number (title), icon, and a caption
 * @namespace Widgets
 * @param {string} type - Type of the widget (error, info, success, warning)
 * @param {string} title - Title of the widget
 * @param {string} caption - Caption of the widget
 * @param {ReactNode} icon - Icon of the widget
 * @returns ReactJSXElement
 */
export default function AlertWidget(
  props: React.ComponentProps<typeof Stack> & {
    icon?: React.ReactNode;
    title?: string;
    type?: keyof typeof TYPES;
    caption?: string;
    to?: string;
    demo?: boolean;
  }
): React.ReactElement {
  //#region props
  const { type, icon, title, to, caption, demo: _, ...rest } = props;
  const alertType = (type ?? 'warning') as keyof typeof TYPES;
  const alertIcon = icon ?? TYPES[alertType].icon;
  const alertTitle = title ?? '0';
  const alertCaption = caption ?? TYPES[alertType].caption;
  //#endregion

  const link = to
    ? {
        component: PureLink,
        to,
      }
    : {};

  return (
    <Card gap={1} type={alertType} {...link} {...rest}>
      {alertIcon}
      <CardContent>
        <Typography variant="h3">{alertTitle}</Typography>
        <Typography variant="caption">{alertCaption}</Typography>
      </CardContent>
    </Card>
  );
}
