import useLogs from 'api/logs';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import PerformanceMetrics from 'api/types/performanceMetrics';
import ReportId from 'api/types/report/id';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { useContext, useEffect, useMemo } from 'react';

/**
 * The use performance metrics hook returns any performance metrics found
 * in the report, either inside an insight or as a performance object.
 */
export default function usePerformanceMetrics(reportId: ReportId): PerformanceMetrics | ApiError | ApiLoading {
  const { log, logObject } = useLogs();

  const context = useContext(ReportContext);

  const userContext = useContext(CurrentUserContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const performanceMetrics: PerformanceMetrics | ApiLoading = useMemo(
    () =>
      !isErrored(currentReport) && !isLoading(currentReport)
        ? {
            passedCount: currentReport.vendors.meta.pass.count,
            tagManagementSystems:
              typeof currentReport.summary.json.tms === 'string' ? [] : currentReport.summary.json.tms,
            tagsCount: currentReport.summary.json.vendors.length,
            averagePageLoadTime: currentReport.performance.json.avg_page_load_time,
            averagePerceivedPageLoadTime: currentReport.performance.json.avg_perceived_page_load_time,
          }
        : loading(),
    [currentReport]
  );

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: usePerformanceMetrics');
    }
    if (performanceMetrics) {
      log('[API] get: performanceMetrics => CACHE HIT');
      logObject('performanceMetrics', performanceMetrics);
    }
  }, [performanceMetrics, context, log, logObject]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  return performanceMetrics;
}
