// type FeatureFlagsProviderValue = {
//   test: FeatureFlags;
// };

import { MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import { useEffect, useReducer } from 'react';

import FeatureFlags from '.';

// const FeatureFlagContext = createContext({} as FeatureFlagsProviderValue);

// export default function FeatureFlagsProvider({ children }: React.PropsWithChildren) {
//   FeatureFlags.providerUpdate();

//   const value = useMemo<FeatureFlagsProviderValue>(
//     () => ({
//       test: FeatureFlags,
//     }),
//     []
//   );
//   return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
// }

const eventOptions = {
  namespace: 'Flags',
  source: 'middleware',
} as const;

export function useFlags(parentEvent?: MiddlewareEvent) {
  const [_, update] = useReducer((x) => x + 1, 0);

  const initEvent = useMiddlewareHookInit(eventOptions.namespace, 'useFlags()', parentEvent?.id);
  const updateEvent = useMiddlewareHookUpdate(eventOptions.namespace, 'useFlags()', initEvent?.id);

  useEffect(() => {
    const event = new MiddlewareEvent({
      ...eventOptions,
      name: 'Attach listener',
      path: 'useEffect()',
      parent: updateEvent.id, // to record the init AND the update event
    });

    const listener = FeatureFlags.listenForUpdates();

    event.status = 'completed';

    if (listener.id) {
      event.resultText = `Listener '${listener.id}' attached and listening for all flag updates.`;
    }

    listener.onUpdate = update;

    return () => {
      const disposeEvent = new MiddlewareEvent({
        ...eventOptions,
        name: 'Dispose listener',
        path: 'return',
        parent: event.id,
      });

      listener.dispose();

      disposeEvent.status = 'completed';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  updateEvent.status = 'completed';
  return FeatureFlags;
}

export function useFlag(flagId: string, parentEvent?: MiddlewareEvent) {
  const [_, update] = useReducer((x) => x + 1, 0);

  const initEvent = useMiddlewareHookInit(eventOptions.namespace, `useFlag('${flagId}')`, parentEvent?.id);
  const updateEvent = useMiddlewareHookUpdate(eventOptions.namespace, `useFlag('${flagId}')`, initEvent?.id);

  useEffect(() => {
    const event = new MiddlewareEvent({
      ...eventOptions,
      name: 'Attach listener',
      path: 'useEffect()',
      parent: updateEvent.id, // to record the init AND the update event
    });

    const listener = FeatureFlags.listenForUpdates(flagId);

    event.status = 'completed';

    if (listener.id) {
      event.resultText = `Listener '${listener.id}' attached and listening for all flag updates.`;
    }

    listener.onUpdate = update;

    return () => {
      const disposeEvent = new MiddlewareEvent({
        ...eventOptions,
        name: 'Dispose listener',
        path: 'return',
        parent: event.id,
      });

      listener.dispose();

      disposeEvent.status = 'completed';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagId]);

  return FeatureFlags.isFlagEnabled(flagId);
}
