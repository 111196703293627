import { ApiBoardIdInsightSchema, ApiBoardIdSchema, ApiBoardIdVendorSchema } from 'api/types/report/api/boardId';
import { ApiDashboardSchema } from 'api/types/report/api/dashboard';
import { ApiInsightSchema } from 'api/types/report/api/insight';
import { ApiTopFindingsSchema } from 'api/types/report/api/topFindings';
import BoardId from 'api/types/report/boardId';
import { VendorWithInsights } from 'api/types/vendors';
import { refine } from 'api/utils';
import { z } from 'zod';

import { ApiPrivacyInsightsVendorSchema } from './vendor';

export const ApiPrivacyInsightsKnownPropsSchema = z.object({
  [0]: ApiDashboardSchema,
  topFindings: ApiTopFindingsSchema,
  insight: z.record(ApiBoardIdSchema, ApiInsightSchema),
  vendor: z.record(ApiBoardIdVendorSchema, ApiPrivacyInsightsVendorSchema),
});

/*
 * MOVE THE TRANFORM FUNCTIONS TO A SEPARATE FILE

















 * MOVE THE TRANFORM FUNCTIONS TO A SEPARATE FILE
 */

export const ApiPrivacyInsightsSchema = z
  .preprocess((args: any) => {
    const knownPropertyKeySchema = ApiPrivacyInsightsKnownPropsSchema.keyof();

    // extract known properties
    const [known, insightsVendorsOthers] = refine(args, (key) => knownPropertyKeySchema.safeParse(key).success);

    // extract any known vendor properties, vendor{number}
    const [vendors, insightsOthers] = refine(
      insightsVendorsOthers,
      (key) => ApiBoardIdVendorSchema.safeParse(key).success
    );

    // extract any known insight properties, insight{number} or any known named properties
    const [insights, _others] = refine(insightsOthers, (key) => ApiBoardIdInsightSchema.safeParse(key).success);

    // in a perfect scenario, _other should be empty

    return {
      ...known,
      insight: insights,
      vendor: vendors,
    };
  }, ApiPrivacyInsightsKnownPropsSchema)
  .transform((args) => {
    const { 0: dashboard, vendor: vendorRecord, ...rest } = args;

    const counts = {
      pass: 0,
      review: 0,
      critical: 0,
    };

    const categories = new Set<string>();
    const validBoardIds = new Set<BoardId>();

    const vendorsRecord = Object.keys(rest.insight).reduce((acc, key) => {
      const vendor = vendorRecord[`vendor${rest.insight[key].vendorId}`];
      if (!vendor) return acc;
      // get stored vendor data or create a new one
      const currentVendor = acc[vendor.boardId] ?? {
        ...vendor,
        name: vendor.title,
      };

      const criticalInsights = currentVendor.criticalInsights ?? [];
      const reviewInsights = currentVendor.reviewInsights ?? [];
      const passInsights = currentVendor.passInsights ?? [];
      const allInsights = currentVendor.allInsights ?? [];

      const currentInsight = rest.insight[key];

      // use as because we know 100% that the insight has a boardId
      validBoardIds.add(currentInsight.boardId as BoardId);
      currentInsight.category && categories.add(currentInsight.category);

      if (currentInsight.status === '4') {
        const newInsight = {
          ...currentInsight,
          name: currentInsight.title,
          result: 'critical',
        } as any;
        criticalInsights.push(newInsight);
        allInsights.push(newInsight);
        counts.critical += 1;
      } else if (currentInsight.status === '3') {
        const newInsight = {
          ...currentInsight,
          name: currentInsight.title,
          result: 'review',
        } as any;
        reviewInsights.push(newInsight);
        allInsights.push(newInsight);
        counts.review += 1;
      } else if (currentInsight.status === '1') {
        const newInsight = {
          ...currentInsight,
          name: currentInsight.title,
          result: 'pass',
        } as any;
        passInsights.push(newInsight);
        allInsights.push(newInsight);
        counts.pass += 1;
      }

      const newVendorData = {
        ...currentVendor,
        criticalInsights,
        reviewInsights,
        passInsights,
        allInsights,
      };

      return {
        ...acc,
        [vendor.boardId]: newVendorData,
      };
    }, {} as Record<string, VendorWithInsights>);

    // eslint-disable-next-line sentinelinsights/no-mutations
    const vendorsWithInsights = Object.values(vendorsRecord).sort((a, b) => a.name.localeCompare(b.name));

    const vendors = {
      vendorsWithInsights,
      meta: {
        pass: {
          count: counts.pass,
        },
        review: {
          count: counts.review,
        },
        critical: {
          count: counts.critical,
        },
        validBoardIds,
        categories,
      },
    };

    return {
      ...rest,
      vendors,
    };
  });

type ApiPrivacyInsights = z.infer<typeof ApiPrivacyInsightsSchema>;

export default ApiPrivacyInsights;
