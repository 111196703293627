import { valueTypes } from 'pages/internal/admin/components/SourceOfTruthModalPage/components/AddCustomizationModal';

const ValueType: Record<(typeof valueTypes)[number], string> = {
  s: 'Static',
  r: 'Regex',
  f: 'Function',
  e: 'Exists',
};

export default ValueType;
