import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { withoutProps } from 'utils';

import { ScrollContainerProps, VariantStyles } from './ScrollContainer.types';

export const Container = styled(
  Stack,
  withoutProps('variant')
)(
  ({ theme, variant }: ScrollContainerProps) => `
    gap: 8px;
    min-width: 0;
    padding: 16px 8px;
    align-items: center;
    border-radius: 16px;
    flex-direction: row;
    ${VariantStyles[variant ?? 'outlined'](theme)}
  `
);

export const Scroller = styled(
  Stack,
  withoutProps('scrollType')
)(
  ({ scrollType }: Partial<ScrollContainerProps>) => `
    position: relative;
    flex-direction: row;
    scrollbar-width: none;
    overflow: ${scrollType === 'latched' ? 'hidden' : 'auto'};

    &::-webkit-scrollbar {
      display: none;
    }
  `
);
