import { z } from 'zod';

export const NotificationTimePeriods = {
  minute: {
    label: 'Minutes',
    value: 'minute',
    hint: 'Minimum 15 minutes',
    min: 15,
    max: 59,
    disabled: false,
  },
  hour: {
    label: (value: number) => `${value === 1 ? 'Hour' : 'Hours'}`,
    value: 'hour',
    hint: 'Maximum 23 hours',
    min: 1,
    max: 23,
    disabled: false,
  },
  day: {
    label: (value: number) => `${value === 1 ? 'Day' : 'Days'}`,
    value: 'day',
    hint: '1 day only',
    min: 1,
    max: 1,
    disabled: true,
  },
} as const;

type NotificationTimePeriods = keyof typeof NotificationTimePeriods;

export const NotificationTimePeriodSchema = z.object({
  window: z.number().positive().int(),
  unit: z.enum(Object.keys(NotificationTimePeriods) as [NotificationTimePeriods, ...NotificationTimePeriods[]]),
});

type NotificationTimePeriod = z.infer<typeof NotificationTimePeriodSchema>;

export default NotificationTimePeriod;
