import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import useExperiments from 'api/experiments';
import { setEnabledFeatureFlags } from 'api/utils';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export default function InternalToolsFeatureFlagsPage() {
  const { counter, store, refresh, explicitlyEnabledExperiments, setExplicitlyEnabledExperiments } = useExperiments();

  const flags = useMemo(() => {
    // update when counter changes
    const _ = counter;
    // eslint-disable-next-line sentinelinsights/no-mutations
    return Object.entries(store)
      .map(([key, value]) => ({
        name: key,
        enabled: value,
      }))
      .filter((x) => ['user.isInternal'].includes(x.name) === false)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [counter, store]);

  return (
    <Stack padding={3} overflow="auto">
      <Typography variant="h5">Feature Flags</Typography>
      <Breadcrumbs>
        <Link to="/internal/tools">Internal Tools</Link>
        <Typography>Feature Flags</Typography>
      </Breadcrumbs>
      <br />
      <Typography variant="h6">All Feature Flags</Typography>
      <br />
      <Container maxWidth="md">
        <Card variant="outlined">
          <CardContent>
            <List dense>
              <Divider />
              {flags.map((x) => (
                <React.Fragment key={x.name}>
                  <ListItemButton
                    onClick={() => {
                      if (!store[x.name]) {
                        store[x.name] = true;
                        setExplicitlyEnabledExperiments(explicitlyEnabledExperiments.concat(x.name));
                      } else {
                        store[x.name] = false;
                        setExplicitlyEnabledExperiments(explicitlyEnabledExperiments.filter((y) => y !== x.name));
                      }
                      refresh();
                    }}
                  >
                    <Checkbox checked={x.enabled} sx={{ pointerEvents: 'none' }} />
                    <ListItemText primary={x.name} />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                setEnabledFeatureFlags(
                  Object.entries(store)
                    .filter(([, value]) => value)
                    .map((x) => x[0])
                );
              }}
            >
              Save Flag States
            </Button>
            <Button
              color="error"
              onClick={() => {
                setEnabledFeatureFlags([]);
              }}
            >
              Delete Saved Flag States
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Stack>
  );
}
