import ChipToggle, { ChipItem } from 'components/ChipToggle';
import React, { useMemo } from 'react';

type BaseChipToggleProps = React.ComponentProps<typeof ChipToggle>;

type ChipTab = {
  label: string;
  component: React.ReactNode;
};

export type ChipTabsProps = {
  tabs: ChipTab[];
  value?: BaseChipToggleProps['value'];
  onChange?: BaseChipToggleProps['onChange'];
} & Omit<BaseChipToggleProps, 'value' | 'onChange' | 'children'>;

export default function ChipTabs(props: ChipTabsProps) {
  const { tabs, value, onChange, ...rest } = props;

  const [selectedTab, setSelectedTab] = React.useState(0);

  const chips = useMemo<ChipItem[]>(() => tabs.map((x) => ({ label: x.label })), [tabs]);

  return (
    <>
      <ChipToggle
        {...rest}
        items={chips}
        value={value ?? selectedTab}
        onChange={(i) => (onChange ? onChange(i) : setSelectedTab(i))}
      />
      {tabs[value ?? selectedTab].component}
    </>
  );
}
