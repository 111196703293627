import { z } from 'zod';

export const ApiNotificationGroupPathsSchema = z.object({
  groupPath: z.string(),
  friendlyName: z.string(),
});

export type ApiNotificationGroupPaths = z.infer<typeof ApiNotificationGroupPathsSchema>;

export const ApiNextNotificationGroupPathsSchema = z.object({
  groupPath: z.string(),
  friendlyName: z.string(),
  technicalName: z.string(),
});

export type ApiNextNotificationGroupPaths = z.infer<typeof ApiNextNotificationGroupPathsSchema>;
