import { z } from 'zod';

/**
 * # This is a temporary solution.
 * Supported operands for the evaluatePostfix function.
 * These should be used in the 'rule' property of the operands array.
 * Pulled from the data sentinel utils package.
 * @deprecated Use the @data-sentinel-ai/utils/evaluatePostfix/index.js 'operandsProcessor' when you can.
 */
const SupportedOperands = {
  // Case Insensitive
  equalsCI: 'equalsCI',
  containsCI: 'containsCI',
  startsWithCI: 'startsWithCI',
  endsWithCI: 'endsWithCI',
  isOneOfCI: 'isOneOfCI',
  isTypeOfCI: 'isTypeOfCI',
  isTruthy: 'isTruthy',
  isFalsy: 'isFalsy',
  greaterThan: 'greaterThan',
  greaterThanEquals: 'greaterThanEquals',
  lessThan: 'lessThan',
  lessThanEquals: 'lessThanEquals',
  matchesRegex: 'matchesRegex',

  // Not Case Insensitive
  doesNotEqualCI: 'doesNotEqualCI',
  doesNotStartWithCI: 'doesNotStartWithCI',
  doesNotContainCI: 'doesNotContainCI',
  doesNotEndWithCI: 'doesNotEndWithCI',
  isNotOneOfCI: 'isNotOneOfCI',
  isNotTypeOfCI: 'isNotTypeOfCI',

  // Case Sensitives
  equals: 'equals',
  contains: 'contains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isOneOf: 'isOneOf',
  isTypeOf: 'isTypeOf',

  // Not
  doesNotEqual: 'doesNotEqual',
  doesNotContain: 'doesNotContain',
  doesNotStartWith: 'doesNotStartWith',
  doesNotEndWith: 'doesNotEndWith',
  isNotOneOf: 'isNotOneOf',
  isNotTypeOf: 'isNotTypeOf',
  doesNotMatchRegex: 'doesNotMatchRegex',
} as const;

export type SupportedOperandsType = keyof typeof SupportedOperands;

export const SupportedOperandsSchema = z.enum(
  Object.keys(SupportedOperands) as [SupportedOperandsType, ...SupportedOperandsType[]]
);

export const SupportedOperandsKeys = Object.keys(SupportedOperands) as SupportedOperandsType[];

export const SupportedOperandsGrouped = {
  // Case Insensitive
  normal: {
    equalsCI: 'equalsCI',
    containsCI: 'containsCI',
    startsWithCI: 'startsWithCI',
    endsWithCI: 'endsWithCI',
    isOneOfCI: 'isOneOfCI',
    isTypeOfCI: 'isTypeOfCI',
    isTruthy: 'isTruthy',
    isFalsy: 'isFalsy',
    greaterThan: 'greaterThan',
    greaterThanEquals: 'greaterThanEquals',
    lessThan: 'lessThan',
    lessThanEquals: 'lessThanEquals',
    matchesRegex: 'matchesRegex',
  },
  normalNot: {
    doesNotEqualCI: 'doesNotEqualCI',
    doesNotStartWithCI: 'doesNotStartWithCI',
    doesNotContainCI: 'doesNotContainCI',
    doesNotEndWithCI: 'doesNotEndWithCI',
    isNotOneOfCI: 'isNotOneOfCI',
    isNotTypeOfCI: 'isNotTypeOfCI',
  },
  caseSensitive: {
    equals: 'equals',
    contains: 'contains',
    startsWith: 'startsWith',
    endsWith: 'endsWith',
    isOneOf: 'isOneOf',
    isTypeOf: 'isTypeOf',
  },
  caseSensitiveNot: {
    doesNotEqual: 'doesNotEqual',
    doesNotContain: 'doesNotContain',
    doesNotStartWith: 'doesNotStartWith',
    doesNotEndWith: 'doesNotEndWith',
    isNotOneOf: 'isNotOneOf',
    isNotTypeOf: 'isNotTypeOf',
    doesNotMatchRegex: 'doesNotMatchRegex',
  },
} as const;
// const SupportedOperands = {
//   equals: 'equals',
//   doesNotEqual: 'doesNotEqual',
//   equalsCI: 'equalsCI',
//   doesNotEqualCI: 'doesNotEqualCI',

//   isOneOf: 'isOneOf',
//   isNotOneOf: 'isNotOneOf',
//   isOneOfCI: 'isOneOfCI',
//   isNotOneOfCI: 'isNotOneOfCI',

//   contains: 'contains',
//   doesNotContain: 'doesNotContain',
//   containsCI: 'containsCI',
//   doesNotContainCI: 'doesNotContainCI',

//   startsWith: 'startsWith',
//   doesNotStartWith: 'doesNotStartWith',
//   startsWithCI: 'startsWithCI',
//   doesNotStartWithCI: 'doesNotStartWithCI',

//   endsWith: 'endsWith',
//   doesNotEndWith: 'doesNotEndWith',
//   endsWithCI: 'endsWithCI',
//   doesNotEndWithCI: 'doesNotEndWithCI',

//   greaterThan: 'greaterThan',
//   greaterThanEquals: 'greaterThanEquals',
//   lessThan: 'lessThan',
//   lessThanEquals: 'lessThanEquals',

//   isTypeOf: 'isTypeOf',
//   isNotTypeOf: 'isNotTypeOf',
//   isTypeOfCI: 'isTypeOfCI',
//   isNotTypeOfCI: 'isNotTypeOfCI',

//   isTruthy: 'isTruthy',
//   isFalsy: 'isFalsy',

//   matchesRegex: 'matchesRegex',
//   doesNotMatchRegex: 'doesNotMatchRegex',
// } as const;

export default SupportedOperands;
