import { SupportedOperands } from 'api/apiTypes/const';

const SupportedOperandsLabels: Record<keyof typeof SupportedOperands | 'customFunction', string> = {
  // Case Insensitive
  equalsCI: 'Equals',
  containsCI: 'Contains',
  startsWithCI: 'Starts With',
  endsWithCI: 'Ends With',
  isOneOfCI: 'Is One Of',
  isTypeOfCI: 'Is Type Of',
  isTruthy: 'Is Truthy',
  isFalsy: 'Is Falsy',
  greaterThan: 'Greater Than',
  greaterThanEquals: 'Greater Than or Equal To',
  lessThan: 'Less Than',
  lessThanEquals: 'Less Than or Equal To',
  matchesRegex: 'Matches RegEx',

  // Not Case Insensitive
  doesNotEqualCI: 'Does Not Equal',
  doesNotStartWithCI: 'Does Not Start With',
  doesNotContainCI: 'Does Not Contain',
  doesNotEndWithCI: 'Does Not End With',
  isNotOneOfCI: 'Is Not One Of',
  isNotTypeOfCI: 'Is Not Type Of',

  // Case Sensitives
  equals: 'Equals (Case Sensitive)',
  contains: 'Contains (Case Sensitive)',
  startsWith: 'Starts With (Case Sensitive)',
  endsWith: 'Ends With (Case Sensitive)',
  isOneOf: 'Is One Of (Case Sensitive)',
  isTypeOf: 'Is Type Of (Case Sensitive)',

  // Not
  doesNotEqual: 'Does Not Equal (Case Sensitive)',
  doesNotContain: 'Does Not Contain (Case Sensitive)',
  doesNotStartWith: 'Does Not Start With (Case Sensitive)',
  doesNotEndWith: 'Does Not End With (Case Sensitive)',
  isNotOneOf: 'Is Not One Of (Case Sensitive)',
  isNotTypeOf: 'Is Not Type Of (Case Sensitive)',
  doesNotMatchRegex: 'Does Not Match RegEx',

  // Custom function
  customFunction: 'Custom Function (Returns True)',
} as const;

export default SupportedOperandsLabels;
