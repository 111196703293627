export function refine<T extends object>(obj: T, predicate: (key: keyof T) => boolean) {
  const refined: Partial<T> = {};
  const other: Partial<T> = {};
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => {
      if (predicate(key as keyof T)) {
        refined[key as keyof T] = obj[key as keyof T];
      } else {
        other[key as keyof T] = obj[key as keyof T];
      }
    })
  );
  return [refined, other];
}

export function refineArray<T>(obj: T[], predicate: (obj: T) => boolean) {
  const refined: T[] = [];
  const other: T[] = [];
  obj.forEach((item) => {
    if (predicate(item)) {
      refined.push(item);
    } else {
      other.push(item);
    }
  });
  return [refined, other];
}
