import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { z } from 'zod';

import {
  completeTimeId,
  DashboardStatsModel,
  DashboardStatsModelSchema,
  perceivedTimeId,
  tagsCountId,
  tmsFoundId,
} from './stats.model';

export const DashboardStatsViewModelSchema = z.object({
  tmsFound: z.array(z.string()).default([]),
  tagsFound: z.number().optional(),
  completePageLoadTime: z.number().optional(),
  perceivedPageLoadTime: z.number().optional(),
});

export type DashboardStatsViewModel = z.infer<typeof DashboardStatsViewModelSchema>;

export function dashboardStatsModelToViewModel(input: unknown, parentEvent?: MiddlewareEvent) {
  const validationEvent = new MiddlewareEvent({
    namespace: 'Dashboard',
    source: 'validate',
    name: 'Convert Model to ViewModel',
    path: `dashboardStatsModelToViewModel()`,
    data: input,
    parent: parentEvent?.id, // to record the init AND the update event
  });

  let model: DashboardStatsModel;

  try {
    model = DashboardStatsModelSchema.parse(input);

    validationEvent.status = 'completed';
    validationEvent.resultText = JSON.stringify(model);
  } catch (error) {
    validationEvent.status = 'failed';
    validationEvent.resultText = `${error}`;

    const middlewareError = new MiddlewareError({
      cause: error instanceof Error ? error : new Error(`${error}`),
      event: validationEvent,
      viewType: 'data',
    });

    throw middlewareError;
  }

  const viewModel: DashboardStatsViewModel = {
    tmsFound: model[tmsFoundId]?.data.flat() ?? [],
    tagsFound: model[tagsCountId]?.data?.[0]?.[0] !== undefined ? +model[tagsCountId].data[0][0] : undefined,
    completePageLoadTime:
      typeof model[completeTimeId]?.data[0][0] === 'string'
        ? +model[completeTimeId].data[0][0].slice(0, -1)
        : undefined,
    perceivedPageLoadTime:
      typeof model[perceivedTimeId]?.data[0][0] === 'string'
        ? +model[perceivedTimeId].data[0][0].slice(0, -1)
        : undefined,
  };

  return viewModel;
}
