import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

import { INotify } from './types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Represents notification component.
 * @function
 * @name Notify
 * @param {string} title - alert title.
 * @param {function} onClose - onclick close button.
 * @param {function} openErrorAlert - open alert box.
 * @return {ReactJSXElement} jsx component
 */
export default function Notify({ title, onClose, openErrorAlert }: INotify) {
  return (
    <>
      <Snackbar
        data-testid="notify"
        open={openErrorAlert}
        autoHideDuration={5000}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
    </>
  );
}
