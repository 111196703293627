import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getKnowledgeBaseRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.knowledgeBase.enabled')) return <></>;
  const KnowledgeBasePage = lazily('knowledge-base');
  return <Route path={AppRoutes.knowledgeBase.index()} element={<KnowledgeBasePage />} />;
};

export default getKnowledgeBaseRoutes;
