export function getLocalStorageItem<T>(key: string, defaultJson = '{}'): T {
  return JSON.parse(window.localStorage.getItem(key) ?? defaultJson) as T;
}

export function setLocalStorageItem<T>(key: string, value: T): void {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
}

export function appendLocalStorageItem<T>(key: string, value: T): void {
  const previous: T = getLocalStorageItem<T>(key);
  const newValue: T = { ...previous, ...value };
  setLocalStorageItem<T>(key, newValue);
}
