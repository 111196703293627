import defaultFeatureFlags from './defaults/feature';
import defaultMetaFlags from './defaults/meta';
import defaultPageFlags from './defaults/page';
import defaultRoleFlags from './defaults/role';
import { FlagReason, FlagSourcePriority } from './types/base';
import { FeatureFlagFromDefinition } from './types/feature';
import { MetaFlagFromDefinition } from './types/meta';
import { PageFlagFromDefinition } from './types/page';
import { RoleFlagFromDefinition } from './types/role';
import { Flag, FlagDefinition } from './types';

type MapOf<T> = Record<string, T | undefined>;
// update listener is different from event listener because
// it provides no data.
type UpdateListener = FlagUpdateListener;

type UpdateListenerMap = MapOf<UpdateListener[]>;

class Flags {
  private static store: MapOf<Flag> = {};
  private static isInitialized = false;

  // all update listener is for if a function wants to listen to EVERY flag update
  private static allUpdateListeners: UpdateListener[] = [];

  // flag update listeners is for if a function wants to listen to a specific flag update
  private static flagUpdateListeners: UpdateListenerMap = {};

  private static addFlag(flag: Flag, updateParent = true) {
    this.store[flag.id] = flag;

    if (updateParent && flag.parent) {
      const parent = this.getFlag(flag.parent.id);
      if (parent) {
        parent.children = parent.children ?? [];
        parent.children.push({ id: flag.id });
      }
    }
  }

  private static fireUpdateEvent(flagId: string) {
    for (const listener of this.flagUpdateListeners[flagId] ?? []) {
      listener.update();
    }

    for (const listener of this.allUpdateListeners) {
      listener.update();
    }
  }

  public static getAllFlags() {
    return Object.values(this.store);
  }

  public static registerFlag(flag: FlagDefinition & { type: Flag['type'] }, reason?: FlagReason) {
    const flags: Flag[] = [];

    switch (flag.type) {
      case 'feature':
        flags.push(...FeatureFlagFromDefinition(flag));
        break;
      case 'meta':
        flags.push(...MetaFlagFromDefinition(flag));
        break;
      case 'page':
        flags.push(...PageFlagFromDefinition(flag));
        break;
      case 'role':
        flags.push(...RoleFlagFromDefinition(flag));
        break;
    }

    for (const flag of flags) {
      this.addFlag({
        ...flag,
        children: [],
        reasons: [
          reason ?? {
            source: 'default', // default is the lowest precedence
            value: flag.enabled,
          },
        ],
      });
    }

    this.fireUpdateEvent(flag.id);
  }

  public static init() {
    if (this.isInitialized) return;
    this.isInitialized = true;

    // Load stuffs
    const defaultFlags: Flag[] = [];

    for (const flag of defaultFeatureFlags) {
      defaultFlags.push(...FeatureFlagFromDefinition(flag));
    }

    for (const flag of defaultMetaFlags) {
      defaultFlags.push(...MetaFlagFromDefinition(flag));
    }

    for (const flag of defaultPageFlags) {
      defaultFlags.push(...PageFlagFromDefinition(flag));
    }

    for (const flag of defaultRoleFlags) {
      defaultFlags.push(...RoleFlagFromDefinition(flag));
    }

    const parents = defaultFlags
      .filter((flag) => flag.parent)
      .reduce(
        (acc, flag) => ((acc[flag.parent!.id] = acc[flag.parent!.id] ?? []), acc[flag.parent!.id].push(flag), acc),
        {} as Record<string, Flag[]>
      );

    for (const flag of defaultFlags) {
      if (this.store[flag.id]) {
        // if flag exists, update it using precedence
        this.updateFlag(flag.id, {
          source: 'default',
          value: flag.enabled,
        });
      } else {
        this.addFlag(
          {
            ...flag,
            children: [],
            reasons: [
              {
                source: 'default',
                value: flag.enabled,
              },
            ],
          },
          false
        );
      }

      if (parents[flag.id]) {
        this.store[flag.id]!.children = parents[flag.id].map((x) => ({ id: x.id }));
      }

      this.fireUpdateEvent(flag.id);
    }
  }

  public static providerUpdate() {
    this.init();
  }

  public static listenForUpdates(flagId?: string) {
    const listener = new FlagUpdateListener((id) => {
      if (flagId) {
        this.flagUpdateListeners[flagId] = this.flagUpdateListeners[flagId]?.filter((flag) => flag.id !== id) ?? [];
      } else {
        this.allUpdateListeners = this.allUpdateListeners.filter((flag) => flag.id !== id);
      }
    });

    // initialize the flag update listeners array
    if (flagId) {
      this.flagUpdateListeners[flagId] = this.flagUpdateListeners[flagId] || [];

      this.flagUpdateListeners[flagId]?.push(listener);
    } else {
      this.allUpdateListeners = this.allUpdateListeners || [];

      this.allUpdateListeners.push(listener);
    }

    return listener;
  }

  public static getFlag(flagId: string) {
    return this.store[flagId];
  }

  public static isFlagEnabled(flagId: string) {
    return this.getFlag(flagId)?.enabled ?? false;
  }

  public static updateFlag(flagId: string, request: FlagReason): boolean {
    const flag = this.getFlag(flagId);

    if (!flag) {
      this.registerFlag(
        {
          type: 'feature',
          description: 'Generated at runtime, please add a default definition for this runtime-generated flag',
          id: flagId,
          name: flagId,
          enabled: request.value,
        },
        request
      );

      return true;
    }

    // flag exists, update it

    const latestReason = flag.reasons[flag.reasons.length - 1];

    if (FlagSourcePriority[request.source] >= FlagSourcePriority[latestReason.source]) {
      // if the request has higher precedence than the latest reason, update the flag

      flag.enabled = request.value;
      flag.reasons.push(request);

      this.fireUpdateEvent(flagId);

      for (const child of flag.children ?? []) {
        // update children flags with reason of parent
        this.updateFlag(child.id, {
          source: 'parent',
          value: request.value,
        });
      }

      return true;
    }

    return false;
  }
}

type FlagUpdateListenerDisposeFunction = (id: string) => void;

export class FlagUpdateListener {
  public id: string;
  public onUpdate?: () => void;
  private _dispose: FlagUpdateListenerDisposeFunction;

  constructor(dispose: FlagUpdateListenerDisposeFunction) {
    this.id = window.crypto.randomUUID();
    this._dispose = dispose;
  }

  public update() {
    this.onUpdate?.();
  }

  public dispose() {
    this._dispose(this.id);
  }
}

window.Flags = Flags;

export default Flags;
