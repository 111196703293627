import NotificationsErrors from './errors';
import EventTypesLabels from './eventTypes';
import SupportedOperandsLabels from './supportedOperands';
import ThresholdLabels from './thresholdLabels';

const NotificationsLabels = {
  name: 'Alerting Settings',
  threshold: ThresholdLabels,
  errors: NotificationsErrors,
  eventTypes: EventTypesLabels,
  supportedOperands: SupportedOperandsLabels,
  pageSubheader: 'Please outline how you would like to be alerted when we find issues',
} as const;

export default NotificationsLabels;
