import CustomizationType from './customizationType';
import PageSpecificSOTOperators from './pageSpecific';
import ValueType from './valueType';

const SourceOfTruth = {
  customizationType: CustomizationType,
  valueType: ValueType,
  pageSpecificSOTOperators: PageSpecificSOTOperators,
};

export default SourceOfTruth;
