import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function useScroll() {
  const [isAtTop, setIsAtTop] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const scrollerRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    if (!scrollerRef.current) return;
    setIsAtTop(scrollerRef.current.scrollTop === 0);
    const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  }, []);

  const resizeObserver = useRef(new ResizeObserver(onScroll));

  useEffect(() => {
    return setupScrollAndResizeListeners(scrollerRef, onScroll, resizeObserver);
  }, [onScroll]);

  const scroll = (amount: number) => {
    if (!scrollerRef.current) return;
    scrollerRef.current.scrollBy({ left: amount, behavior: 'smooth' });
  };

  const scrollLeft = () => canScrollLeft && scroll(-300);
  const scrollRight = () => canScrollRight && scroll(300);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onScroll(), []);

  return {
    isAtTop,
    scrollLeft,
    scrollRight,
    scrollerRef,
    canScrollLeft,
    canScrollRight,
  };
}

function setupScrollAndResizeListeners(
  scrollerRef: React.RefObject<HTMLDivElement>,
  onScroll: () => void,
  resizeObserver: React.RefObject<ResizeObserver>
) {
  const c = scrollerRef.current;
  c?.addEventListener('scroll', onScroll);

  const r = resizeObserver.current;
  if (r && c) r.observe(c);

  return () => {
    c?.removeEventListener('scroll', onScroll);
    r?.disconnect();
  };
}
