import useExperiments from 'api/experiments';
import React from 'react';

import { SourceOfTruthProvider } from './sourceOfTruth/provider/SourceOfTruthProvider';

export default function InternalAdminProvider(props: React.PropsWithChildren) {
  const { isExperimentEnabled } = useExperiments();
  if (!isExperimentEnabled('pages.internalAdmin.enabled')) return <>{props.children}</>;
  return <SourceOfTruthProvider>{props.children}</SourceOfTruthProvider>;
}
