import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';

import * as Styled from './CaptionedSelect.styles';

export { Demo } from './CaptionedSelect.demo';

type CaptionedSelectVariants = React.ComponentProps<typeof TextField>['variant'] | 'minimal';

export type CaptionedSelectProps = {
  caption?: string;
  variant?: CaptionedSelectVariants;
} & Omit<React.ComponentProps<typeof TextField>, 'variant'>;

/**
 * A select component with a caption above it, also adds a minimal (no border) variant
 */
export default function CaptionedSelect(props: CaptionedSelectProps) {
  const { caption, variant, ...rest } = props;
  const isMinimal = variant === 'minimal';
  const baseVariant = isMinimal ? 'standard' : variant;

  return (
    <Stack direction="row" pl={1} alignItems="center" gap={2} data-testid="CaptionedSelect">
      {caption && (
        <Typography variant="overline" color="primary" flexShrink={0}>
          {caption}
        </Typography>
      )}
      <Styled.TextField
        select
        fullWidth
        variant={baseVariant}
        removeBorder={isMinimal}
        sx={isMinimal ? {} : { minWidth: 250 }}
        {...rest}
      />
    </Stack>
  );
}
