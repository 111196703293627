import { BaseFlag } from './base';

export type RoleFlag = BaseFlag & {
  type: 'role';
};

export type RoleFlagDefinition = Omit<RoleFlag, 'reasons' | 'type'>;

export function RoleFlagFromDefinition(definition: RoleFlagDefinition): RoleFlag[] {
  return [
    {
      ...definition,
      id: `role.${definition.id}`,
      type: 'role',
      reasons: [],
    },
  ];
}
