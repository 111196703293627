import styled from '@emotion/styled';
import {
  Drawer as MuiDrawer,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Theme,
} from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * The styled drawer component itself.
 */
export const Drawer = styled(MuiDrawer)(
  ({ theme }: { theme?: Theme }) => `
    .MuiDrawer-paper {
      display: flex;
      width: inherit;
      align-items: stretch;
      flex-direction: column;
      justify-content: space-between;
      background: ${theme?.palette?.background?.verylight};
    }
  `
);

/**
 * The styled logo component.
 */
export const Logo = styled.img``;

/**
 * The styled list item button component (clickable list items)
 */
export const ListItemButton = styled(MuiListItemButton)(
  ({ theme }: { theme?: Theme }) => `
    &.active {
      background-color: ${theme?.palette?.primary[400]};
      .MuiListItemIcon-root {
        color: ${theme?.palette?.primary?.contrastText};
      }
      .MuiListItemText-root {
        color: ${theme?.palette?.primary?.contrastText};
      }
    }
  `
);

/**
 * The styled list item icon component.
 */
export const ListItemIcon = styled(MuiListItemIcon)(
  ({ theme }: { theme?: Theme }) => `
    color: ${theme?.palette?.text?.primary};
  `
);

/**
 * The styled list item text component.
 */
export const ListItemText = styled(MuiListItemText)(
  ({ theme }: { theme?: Theme }) => `
    color: ${theme?.palette?.text?.primary};
  `
);

export const ImageLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    width: 70%;
  }
`;
