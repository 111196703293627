import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;

  &:not(#a):hover {
    cursor: pointer;
  }
`;

export default function PureLink(props: React.ComponentProps<typeof Link> & { href?: string }) {
  const { href, to, ...rest } = props;
  return <StyledLink role="button" to={to ?? href} {...rest} />;
}
