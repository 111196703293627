import { z } from 'zod';

import { PostfixFunctionSchema } from '../postfixLogic';

export const ApiSourceOfTruthQualifierSchema = z.object({
  name: z.string(),
  id: z.string(),
  defaultForAll: z.boolean(),
  qualifier: PostfixFunctionSchema,
});

type ApiSourceOfTruthQualifier = z.infer<typeof ApiSourceOfTruthQualifierSchema>;

export default ApiSourceOfTruthQualifier;
