import { EnvironmentId, TagId } from 'api/apiTypes';
import Notification, {
  NotificationSchema,
  NotificationWithId,
  NotificationWithIdSchema,
  NotificationWithoutEnvGuidWithIdSchema,
} from 'api/apiTypes/notification';
import {
  ApiNextNotificationGroupPaths,
  ApiNextNotificationGroupPathsSchema,
} from 'api/apiTypes/notification/groupPaths';
import useLogs from 'api/logs';
import useRawApiResponse from 'api/raw';
import routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import Report from 'api/types/report';
import ReportId from 'api/types/report/id';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, useRegisterMiddlewareHook, withoutError } from 'api/utils';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import NotificationId from './types/id';
import NotificationContextType from './types/provider';

export const NotificationContext = createContext({} as NotificationContextType);

export function NotificationsProvider(props: React.PropsWithChildren) {
  const { get, post, delete: reqDelete, put } = useAxios();
  const [hasConsumer, setHasConsumer] = useState(false);
  const [localStore, setLocalStore] = useState<NotificationContextType['notifications']>({});
  const [groupPaths, setGroupPaths] = useState<NotificationContextType['groupPaths']>({});
  const notificationsStore = useRef(new Set<ReportId>());

  // debugging
  const rawStore = useRawApiResponse();

  const getNotifications = useCallback(
    async (report: Report) => {
      if (notificationsStore.current.has(report.id)) return;
      notificationsStore.current.add(report.id);

      // get notifications from api
      const url = routes.notifications.get(report.summary.json.environmentId);

      const response = await get(url, { withCredentials: true });

      // store into debugging context
      try {
        rawStore[report.id] = {
          ...rawStore[report.id],
          notifications: response.data,
        };
      } catch (_) {}

      try {
        const notifications = NotificationWithIdSchema.array().parse(response.data);

        setLocalStore((prev) => ({
          ...prev,
          [report.id]: notifications,
        }));
      } catch (e: any) {
        setLocalStore((prev) => ({
          ...prev,
          [report.id]: error(['Failed to load notifications', e.toString()]),
        }));
      }
    },
    [get, rawStore]
  );

  const createNotification = useCallback(
    async (notification: Notification, reportId: ReportId) => {
      const payload = NotificationSchema.parse(notification);
      const response = await post(routes.notifications.create(), payload, { withCredentials: true });

      const createdNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);
      setLocalStore((prev) => ({
        ...prev,
        [reportId]: [...(withoutError(prev[reportId]) ?? []), createdNotification],
      }));
    },
    [post]
  );

  const updateNotification = useCallback(
    async (oldNotification: NotificationWithId, reportId: ReportId) => {
      const payload = NotificationSchema.parse(oldNotification);

      const response = await put(routes.notifications.update(oldNotification._id), payload, { withCredentials: true });

      const updatedNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);
      setLocalStore((prev) => ({
        ...prev,
        [reportId]: (withoutError(prev[reportId]) ?? []).map((notification) =>
          notification._id === oldNotification._id
            ? { ...updatedNotification, envGuid: notification.envGuid }
            : notification
        ),
      }));
    },
    [put]
  );

  const deleteNotification = useCallback(
    async (id: NotificationId, reportId: ReportId) => {
      const response = await reqDelete(routes.notifications.delete(id), { withCredentials: true });

      const deletedNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);

      setLocalStore((prev) => ({
        ...prev,
        [reportId]: (withoutError(prev[reportId]) ?? []).filter(
          (notification) => notification._id !== deletedNotification._id
        ),
      }));
    },
    [reqDelete]
  );

  const getGroupPaths = useCallback(
    async (report: Report, tagId: TagId) => {
      if (!report.summary.json.environmentId || !tagId) return;
      const key: `${EnvironmentId}-${TagId}` = `${report.summary.json.environmentId}-${tagId}`;
      if (!!groupPaths[key]) return;
      setGroupPaths((prev) => ({ ...prev, [key]: [] }));

      // get notifications from api
      const url = routes.notifications.groupPaths.get(report.summary.json.environmentId, tagId);

      const response = await get(url, { withCredentials: true });

      try {
        const rawSchema = z.record(
          z.object({
            friendlyName: z.string(),
            technicalName: z.string(),
          })
        );

        const rawNotifications = rawSchema.parse(response.data);
        const _notifications = Object.entries(rawNotifications).map(([groupPath, data]) => ({
          ...data,
          groupPath,
        }));
        const notifications = ApiNextNotificationGroupPathsSchema.array().parse(_notifications);

        setGroupPaths((prev) => ({
          ...prev,
          [key]: notifications,
        }));
      } catch (e: any) {
        setGroupPaths((prev) => ({
          ...prev,
          [key]: error(['Failed to load group paths', e.toString()]),
        }));
      }
    },
    [get, groupPaths]
  );

  const value: NotificationContextType = {
    groupPaths,
    hasConsumer,
    getGroupPaths,
    setHasConsumer,
    getNotifications,
    createNotification,
    updateNotification,
    deleteNotification,
    notifications: localStore,
    setNotifications: setLocalStore,
  };

  return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
}

export default function useNotifications(reportId: ReportId): NotificationWithId[] | ApiError | ApiLoading {
  const { log } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const notificationsContext = useContext(NotificationContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useNotifications');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  const notifications = notificationsContext.notifications[reportId];

  // isLoading is basically a check for whether it is "undefined" or not.
  if (isLoading(notifications)) {
    notificationsContext.getNotifications(currentReport);
  }

  return notifications;
}

export function useCreateNotification(
  reportId: ReportId
): ((notification: Notification) => void) | ApiError | ApiLoading {
  const { log } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const notificationsContext = useContext(NotificationContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useCreateNotifications');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  return (notification: Notification) => notificationsContext.createNotification(notification, reportId);
}

export function useDeleteNotification(reportId: ReportId): ((id: NotificationId) => void) | ApiError | ApiLoading {
  const { log } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const notificationsContext = useContext(NotificationContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useDeleteNotification');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  return (id: NotificationId) => notificationsContext.deleteNotification(id, reportId);
}

export function useUpdateNotification(
  reportId: ReportId
): ((notification: NotificationWithId) => void) | ApiError | ApiLoading {
  const { log } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const notificationsContext = useContext(NotificationContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useUpdateNotification');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  return (notification: NotificationWithId) => notificationsContext.updateNotification(notification, reportId);
}

export function useNotificationsGroupPaths(
  reportId: ReportId,
  tagId: TagId
): ApiNextNotificationGroupPaths[] | ApiError | ApiLoading {
  const { log } = useLogs();

  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const notificationsContext = useContext(NotificationContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useNotificationsGroupPaths');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  const key: `${EnvironmentId}-${TagId}` = `${currentReport.summary.json.environmentId}-${tagId}`;
  const groupPaths = notificationsContext.groupPaths[key];

  // isLoading is basically a check for whether it is "undefined" or not.
  if (isLoading(groupPaths)) {
    notificationsContext.getGroupPaths(currentReport, tagId);
    return loading();
  }

  if (isErrored(groupPaths)) {
    return groupPaths;
  }

  return groupPaths;
}

export function usePIIRules(reportId: ReportId): SWRResponse<string[]> {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('usePIIRules', reportId);

  return useSWR(
    routes.notifications.piiRules.get(report?.summary.json.environmentId ?? ('' as EnvironmentId)),
    async (url) => {
      if (!report) return [];
      return z.array(z.string()).parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );
    }
  );
}
