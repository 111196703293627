import { z } from 'zod';

import { SupportedOperands } from '../const';

export type SupportedOperandsType = keyof typeof SupportedOperands;
export * from './function';
export * from './regex';
export type { default as PostfixFunction } from './function';

export const PostfixLogicSchema = z.object({
  /*z.union([
    z
      .string()
      // this regex makes sure there is one number for every operator and a space between each number and operator
      // old: .regex(/\d+ |[|&] |(?<= )[|&]$/gm)
      // new proposed: /\d\s|[|&]\s|[|&]$/gm
      // there should always be one more number than operators
      .refine((val) => (val.match(/\d+/g)?.length ?? 0) === (val.match(/[|&]/g)?.length ?? 0) + 1, {
        message: 'Invalid logic',
      }),*/
  logic: z.array(z.string()),
  operands: z.array(
    z.object({
      rule: z.enum(Object.keys(SupportedOperands) as [SupportedOperandsType, ...SupportedOperandsType[]]),
      params: z.array(z.string()),
    })
  ),
});

type PostfixLogicType = z.infer<typeof PostfixLogicSchema>;

export default PostfixLogicType;
