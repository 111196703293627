import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a ReportId.
 */
export const TagIdSchema = z.string().min(1).brand('TagId');

/**
 * The ReportId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects a ReportId.
 */
type TagId = z.infer<typeof TagIdSchema>;

/**
 * Use the parse function in order to convert a string into a ReportId.
 */
export const NewTagIdSchema = z.string().length(6).brand('NewTagId');

/**
 * The ReportId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects a ReportId.
 */
export type NewTagId = z.infer<typeof NewTagIdSchema>;

export default TagId;
