import { useAxios } from 'api';
import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import routes from 'api/routes';
import moment, { Moment } from 'moment';
import useSWR, { useSWRConfig } from 'swr';

import { ClickHouseApiQueryVariables, ClickHouseApiQueryVariablesSchema } from '../schemas/clickhouse';

import { dashboardDetailsModelToViewModel } from './schemas/insights/details/details.viewmodel';
import { dashboardInsightsModelToViewModel } from './schemas/insights/leftnav/leftnav.viewmodel';
import { dashboardStatsModelToViewModel } from './schemas/stats/stats.viewmodel';

const eventOptions = {
  namespace: 'Dashboard',
  source: 'middleware',
} as const;

export function useDashboardStats(queryVariables: ClickHouseApiQueryVariables | null, parentEvent?: MiddlewareEvent) {
  const initEvent = useMiddlewareHookInit(
    eventOptions.namespace,
    `useDashboardInsights()`,
    parentEvent?.id,
    queryVariables
  );
  const updateEvent = useMiddlewareHookUpdate(
    eventOptions.namespace,
    `useDashboardInsights()`,
    initEvent?.id,
    queryVariables
  );
  const { post } = useAxios();

  const payloadValid = queryVariables && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  const swrResponse = useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.stats(), payload: queryVariables }) : null,
    async (json) => {
      const fetchEvent = new MiddlewareEvent({
        ...eventOptions,
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: json,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const { url } = JSON.parse(json);
        const response = await post(url, queryVariables, {
          withCredentials: true,
        });

        data = response.data;

        if (!data) throw new Error('Data is empty');
        fetchEvent.status = 'completed';
      } catch (error) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${error}`;

        const middlewareError = new MiddlewareError({
          cause: error instanceof Error ? error : new Error(`${error}`),
          event: fetchEvent,
          viewType: 'data',
        });

        throw middlewareError;
      }

      return dashboardStatsModelToViewModel(data, fetchEvent);
    },
    {
      errorRetryCount: 2,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      // revalidateOnMount: true,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );

  updateEvent.status = 'completed';

  return swrResponse;
}

// TODO: replace ALL swr calls with arrays for keys
export function useDashboardInsights(
  queryVariables: ClickHouseApiQueryVariables | null,
  parentEvent?: MiddlewareEvent
) {
  const initEvent = useMiddlewareHookInit(
    eventOptions.namespace,
    `useDashboardInsights()`,
    parentEvent?.id,
    queryVariables
  );
  const updateEvent = useMiddlewareHookUpdate(
    eventOptions.namespace,
    `useDashboardInsights()`,
    initEvent?.id,
    queryVariables
  );

  const { post } = useAxios();

  const payloadValid = queryVariables && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  const result = useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.insights(), payload: queryVariables }) : null,
    async (json) => {
      const fetchEvent = new MiddlewareEvent({
        ...eventOptions,
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: json,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const { url } = JSON.parse(json);
        const response = await post(url, queryVariables, {
          withCredentials: true,
        });

        data = response.data;

        if (!data) throw new Error('Data is empty');
        fetchEvent.status = 'completed';
      } catch (error) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${error}`;

        const middlewareError = new MiddlewareError({
          cause: error instanceof Error ? error : new Error(`${error}`),
          event: fetchEvent,
          viewType: 'data',
        });

        throw middlewareError;
      }

      return dashboardInsightsModelToViewModel(data, fetchEvent);
    },
    {
      errorRetryCount: 2,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      // revalidateOnMount: true,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );

  updateEvent.status = 'completed';

  return result;
}

export function useDashboardDetails(
  id: string | null,
  queryVariables: ClickHouseApiQueryVariables | null,
  parentEvent?: MiddlewareEvent
) {
  const initEvent = useMiddlewareHookInit(eventOptions.namespace, `useDashboardDetails()`, parentEvent?.id, {
    id,
    queryVariables,
  });
  const updateEvent = useMiddlewareHookUpdate(eventOptions.namespace, `useDashboardDetails()`, initEvent?.id, {
    id,
    queryVariables,
  });

  const { post } = useAxios();
  const { cache } = useSWRConfig();

  const payloadValid = id && queryVariables && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  const finalUrl = id ? routes.dashboard.details(id) : undefined;

  const result = useSWR(
    payloadValid ? JSON.stringify({ url: finalUrl, payload: queryVariables }) : null,
    async (json) => {
      const fetchEvent = new MiddlewareEvent({
        ...eventOptions,
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: json,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const { url } = JSON.parse(json);
        cache.set(finalUrl + ':lastUpdate', {
          data: moment(),
        });
        const response = await post(url, queryVariables, {
          withCredentials: true,
        });

        data = response.data;

        if (!data) throw new Error('Data is empty');
        fetchEvent.status = 'completed';
      } catch (error) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${error}`;

        const middlewareError = new MiddlewareError({
          cause: error instanceof Error ? error : new Error(`${error}`),
          event: fetchEvent,
          viewType: 'data',
        });

        throw middlewareError;
      }

      return dashboardDetailsModelToViewModel(data, fetchEvent);
    },
    {
      errorRetryCount: 2,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      // revalidateOnMount: true,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );

  updateEvent.status = 'completed';

  const lastUpdate: Moment | undefined = cache.get(finalUrl + ':lastUpdate')?.data ?? undefined;

  return {
    ...result,
    lastUpdate,
  };
}
