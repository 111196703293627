import { EnvironmentId, TagId } from 'api/apiTypes';
import NotificationId from 'api/notifications/useNotifications/types/id';
import { Moment } from 'moment';

const NotificationsRoutes = {
  get: (envGuid: EnvironmentId) => `/alerting-rules?envGuid=${envGuid}`,
  create: () => `/alerting-rules`,
  delete: (id: NotificationId) => `/alerting-rules/${id}`,
  update: (id: NotificationId) => `/alerting-rules/${id}`,
  groupPaths: {
    get: (envGuid: EnvironmentId, tagId: TagId, date?: Moment) =>
      `/alerting-rules/group-paths/${envGuid}/${tagId}${date ? `?date=${date.format('MM-DD')}` : ''}`,
  },
  piiRules: {
    get: (envGuid: EnvironmentId) => `/alerting-rules/pii-rules?envGuid=${envGuid}`,
  },
};

export default NotificationsRoutes;
