export const Dimensions = [
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: 'Standard',

    friendlyName: 'Page URL',
    id: 'PgeUR1',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Functional URL',
    id: 'pFnUrl',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Domain',
    id: 'pgD0MN',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Path',
    id: 'PgP4TH',
  },
  {
    group: 'Page URL',
    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Query string',
    id: 'pgQuRY',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Hash',
    id: 'PgH45H',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Page Protocol',
    id: 'PgPtcl',
  },
  {
    group: 'Page URL',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Virtual Page Name',
    id: 'vrtPNm',
  },
  {
    group: 'Page URL',

    additionalInput: 'Yes, input box to specify specific parameter',
    comparatorsVisible: true,
    type: 'string',
    inputType:
      'Two input boxes, one to specify the query string parameter (left of comparator) and another to specify a value',

    friendlyName: 'Tag Variable',
    id: 'T4Gvar',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Bot Name',
    id: 'B0Tnm3',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'number',
    inputType: '',

    friendlyName: 'Browser Height',
    id: 'brwsHt',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Browser Name',
    id: 'BRW5nm',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Browser User Agent',
    id: 'brwsUA',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Browser Version',
    id: 'brwsVr',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'number',
    inputType: '',

    friendlyName: 'Browser Width',
    id: 'brwsWd',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'number',
    inputType: '',

    friendlyName: 'Color Depth',
    id: 'clrDpt',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Device Model',
    id: 'dvcMdl',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Device Type',
    id: 'dvcTyp',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Device Vendor',
    id: 'dvcVnd',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'Boolean',
    inputType: 'Only allow them to select either true or false comparator, remove input box',

    friendlyName: 'Is Local Storage Enabled',
    id: 'LclStg',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Operating System Name',
    id: '05name',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Operating System Version',
    id: '05vrsn',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'Boolean',
    inputType: 'Only allow them to select either true or false comparator, remove input box',

    friendlyName: 'Is Performance Get Entries Enabled',
    id: 'prfGt3',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Referrer',
    id: 'pgeRFR',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Screen Resolution',
    id: '5crnR5',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'Boolean',
    inputType: 'Only allow them to select either true or false comparator, remove input box',

    friendlyName: 'Is Session Storage Enabled',
    id: '5snStg',
  },
  {
    group: 'Browser Attributes',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'Boolean',
    inputType: 'Only allow them to select either true or false comparator, remove input box',

    friendlyName: 'Is Touch Enabled',
    id: 'TchEnb',
  },
  {
    group: 'Connection Details',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'IP Address',
    id: 'ip4DDR',
  },
  {
    group: 'Connection Details',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Proxy Description',
    id: 'pxyDsc',
  },
  {
    group: 'Connection Details',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Proxy Type',
    id: 'pxyTyp',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'number',
    inputType: '',

    friendlyName: 'Latitude',
    id: 'L4tud3',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'number',
    inputType: '',

    friendlyName: 'Longitude',
    id: 'Lngtd3',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'City',
    id: 'usrCty',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Region',
    id: 'R3g10n',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Postal Code',
    id: 'pstlCd',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Country',
    id: 'C0ntry',
  },
  {
    group: 'Geo-Location',

    additionalInput: false,
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Continent',
    id: 'C0ntnt',
  },
  {
    group: 'Data Layer',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'Expected Value',
    id: '3xpV41',
  },
  {
    group: 'JavaScript Errors',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'JavaScript Error Message',
    id: 'J3RM5G',
  },
  {
    group: 'JavaScript Errors',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'JavaScript Error Stack',
    id: 'J3r5tk',
  },
  {
    group: 'Content Security Policy Errors',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'Blocked URL',
    id: 'bkdUr1',
  },
  {
    group: 'Content Security Policy Errors',

    additionalInput: false,
    comparatorsVisible: false,
    type: 'string',
    inputType: 'Only allow them to select either blocked or reported',

    friendlyName: 'Request Blocked or Reported',
    id: 'd1sp0s',
  },
  {
    group: 'Content Security Policy Errors',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'Original Policy',
    id: '0Gplcy',
  },
  {
    group: 'Content Security Policy Errors',

    additionalInput: false,
    comparatorsVisible: '',
    type: 'string',
    inputType: '',

    friendlyName: 'Violated Directive',
    id: 'VltDrv',
  },
  {
    group: 'Consent Violation',

    additionalInput: '',
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Category Name',
    id: 'CtgrNm',
  },
  {
    group: 'Consent Violation',

    additionalInput: '',
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Full Consent Value',
    id: 'F11CnVl',
  },
  {
    group: 'Consent Violation',

    additionalInput: '',
    comparatorsVisible: true,
    type: 'string',
    inputType: '',

    friendlyName: 'Consent Events Flow',
    id: 'CnstEF',
  },
];
