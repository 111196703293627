import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { withoutProps } from 'utils';

import type { SwissWidgetProps } from './';

export const Card = styled(
  Stack,
  withoutProps('variant', 'textColor', 'backgroundColor', 'passed')
)(
  ({ onClick, variant, textColor, backgroundColor }: SwissWidgetProps) => `
    width: fit-content;
    ${
      variant === 'small'
        ? `
      padding: 8px 12px;
      `
        : `
      padding: 10px 12px;
    `
    }
    border-radius: 16px;
    font-size: 12px;
    align-items: center;
    flex-shrink: 0;
    font-weight: 500;
    background-color: ${backgroundColor};
    color: ${textColor};
    ${
      onClick
        ? `
    &:hover {
      cursor: pointer;
    }
    `
        : ''
    }
  `
);

export const Caption = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

/* ICONS */
export const SuccessIcon = styled(CheckCircle)`
  width: 32px;
  height: 32px;
  color: ${green[900]};
`;

export const IconContainer = styled(Stack)`
  align-items: center;
  justify-content: center;
  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
`;
