import { CheckCircle, Error, Notifications, Public } from '@mui/icons-material';
import {
  Badge,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useReportId, useSOTPublishProgress } from 'api';
import React, { useEffect, useRef, useState } from 'react';

export function View() {
  const [open, setOpen] = useState(false);
  const elemRef = useRef<HTMLButtonElement | null>(null);
  const reportId = useReportId();
  const sotPublish = useSOTPublishProgress(reportId);
  let alertCount = 0;
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);

  const notifications: React.ReactNode[] = [];

  useEffect(() => {
    if (sotPublish.data) {
      if (sotPublish.data.isPending || sotPublish.data.inProgress) {
        if (refreshTimeout.current) {
          clearTimeout(refreshTimeout.current);
        }

        refreshTimeout.current = setTimeout(() => {
          sotPublish.mutate();
        }, 10000);
      }
    }
  }, [sotPublish]);

  if (sotPublish.data) {
    if (sotPublish.data.isFailed) {
      notifications.push(
        <ListItem key={notifications.length}>
          <ListItemIcon>
            <Public />
          </ListItemIcon>
          <ListItemText primary="Failed to publish rules" secondary={sotPublish.data.date.fromNow()} />
          <ListItemIcon>
            <Error color="error" />
          </ListItemIcon>
        </ListItem>
      );
    } else if (sotPublish.data.isComplete) {
      notifications.push(
        <ListItem key={notifications.length}>
          <ListItemIcon>
            <Public />
          </ListItemIcon>
          <ListItemText primary="Successfully published rules" secondary={sotPublish.data.date.fromNow()} />
          <ListItemIcon>
            <CheckCircle color="success" />
          </ListItemIcon>
        </ListItem>
      );
    } else {
      alertCount++;
      notifications.push(
        <ListItem key={notifications.length}>
          <Stack width="100%">
            <Typography>Publishing Rules</Typography>
            {sotPublish.data.page.available && (
              <Stack direction="row" alignItems="center">
                <ListItemText secondary="Page Rules" />
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="caption">
                    {sotPublish.data.page.isPending
                      ? 'Pending'
                      : `${sotPublish.data.page.progressCurrent}/${sotPublish.data.page.progressTotal}`}
                  </Typography>
                  {sotPublish.data.page.isComplete ? (
                    <CheckCircle color="success" />
                  ) : (
                    <CircularProgress
                      variant="determinate"
                      value={sotPublish.data.page.progress * 100}
                      size={20}
                      sx={{
                        borderRadius: '50%',
                        boxShadow: 'inset 0 0 1px 1px #ddd',
                        backgroundColor: 'transparent',
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            )}
            {sotPublish.data.global.available && (
              <Stack direction="row" alignItems="center">
                <ListItemText secondary="Global Rules" />
                <Stack direction="row" alignItems="center" gap={1}>
                  {sotPublish.data.global.isPending ? (
                    <Typography variant="caption">Pending</Typography>
                  ) : sotPublish.data.global.isComplete ? (
                    <CheckCircle color="success" />
                  ) : (
                    <CircularProgress value={50} size={20} />
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </ListItem>
      );
    }
  }

  return (
    <>
      <IconButton color="primary" ref={elemRef} onClick={() => setOpen(true)}>
        <Badge badgeContent={alertCount} variant="dot" color="error">
          <Notifications />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={elemRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack width={500} minHeight={350}>
          <Stack direction="row" py={1.5} px={2} alignItems="center" gap={1}>
            <Notifications color="action" />
            <Typography variant="h6">Notifications</Typography>
          </Stack>
          <Divider />
          {notifications.length === 0 ? (
            <Stack py={2} pb={6} px={2} flexGrow={1} justifyContent="center" alignItems="center">
              <Typography fontSize={64}>🎉</Typography>
              <Typography textAlign="center" variant="h6">{`You're all caught up!`}</Typography>
              <Typography textAlign="center" variant="caption">{`New notifications will appear here`}</Typography>
            </Stack>
          ) : (
            <List
              disablePadding
              sx={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '& .MuiListItemIcon-root': {
                  minWidth: 40,
                },
              }}
            >
              {notifications.map((notification, index) => [
                ...(index > 0 ? [<Divider key={`divider-${index}`} />] : []),
                notification,
              ])}
              <Divider />
            </List>
          )}
        </Stack>
      </Popover>
    </>
  );
}
