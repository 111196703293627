import { z } from 'zod';

export const ApiSourceOfTruthTagsSchema = z.object({
  /**
   * "125"
   */
  tagId: z.string(),
  /**
   * "Google Universal Analytics - Base Tag"
   */
  tagName: z.string(),
  /**
   * "fcg6f-c867g-cfgcfg890c-f87gfc"
   */
  technologyId: z.string().optional(),
  /**
   * "Google Universal Analytics"
   */
  technologyName: z.string().optional(),
});

export type ApiSourceOfTruthTags = z.infer<typeof ApiSourceOfTruthTagsSchema>;
