import { ApiStatusPRF } from 'api/types/report/api/status';
import { z } from 'zod';

import { ApiVendorInsightsTableDataInsightNameSchema } from './insightName';

export const ApiVendorInsightsTableDataSchema = z.object({
  category: z.string(),
  result: ApiStatusPRF.optional(), // MAKE OPTIONAL FOR EDGE CASES WHERE NO DATA IS RETURNED
  insightName: ApiVendorInsightsTableDataInsightNameSchema,
});

type ApiVendorInsightsTableData = z.infer<typeof ApiVendorInsightsTableDataSchema>;

export default ApiVendorInsightsTableData;
