import { z } from 'zod';

import { ReportId } from '../../id';

/** The partial reports object returned from the /profile endpoint */
export const PartialReportSchema = z.object({
  name: z.string(),
  id: z.string().uuid(),
  envGuid: z.union([z.string().uuid(), z.literal(false)]),
});

/** The partial reports object returned from the /profile endpoint */
interface PartialReport extends z.infer<typeof PartialReportSchema> {
  id: ReportId;
}

export default PartialReport;
