import { z } from 'zod';

import { ApiVendorInsightsTableDataSchema } from './data';
import { ApiVendorInsightsTableHeaderSchema } from './header';

export const ApiVendorInsightsTableSchema = z.object({
  header: z.array(ApiVendorInsightsTableHeaderSchema),
  data: z.array(ApiVendorInsightsTableDataSchema),
});

type ApiVendorInsightsTable = z.infer<typeof ApiVendorInsightsTableSchema>;

export default ApiVendorInsightsTable;
