import { z } from 'zod';

import { ApiBoardIdSchema } from '../../boardId';

export const ApiDashboardTableDataResultSchema = z.object({
  type: z.literal('json'),
  data: z.object({
    boardId: ApiBoardIdSchema,
    resultColumn: z.literal('result'),
  }),
});

type ApiDashboardTableDataResult = z.infer<typeof ApiDashboardTableDataResultSchema>;

export default ApiDashboardTableDataResult;
