import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getInsightsRoutes: RouteListGetter = (lazily, isFlagEnabled) => {
  if (!isFlagEnabled('page.insights')) return <></>;
  const InsightsPage = lazily('insights2');
  return <Route path="/api-insights" element={<InsightsPage />} />;
};

export default getInsightsRoutes;
