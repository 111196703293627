import styled from '@emotion/styled';
import { SentimentNeutral, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';
import { Chip, Theme } from '@mui/material';
import React from 'react';

//#region rypes
type SmileyChipProps = {
  score: number;
  percent?: boolean;
} & React.ComponentProps<typeof Chip>;

type Mood = keyof typeof MOODS;
//#endregion

//#region styles
const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'mood',
})(
  ({ theme, mood }: { theme?: Theme; mood: Mood }) => `
    color: ${MOODS[mood].getForeground(theme)};
    background-color: ${MOODS[mood].getBackground(theme)};
    font-weight: 500;

    .MuiChip-icon {
      color: inherit;
    }

    .MuiChip-label {
      padding: 0 8px;
    }
  `
);
//#endregion

//#region constants
const MOODS = {
  happy: {
    icon: <SentimentVerySatisfied />,
    getBackground: (theme?: Theme) => theme?.palette?.success[50],
    getForeground: (theme?: Theme) => theme?.palette?.success[900],
  },
  neutral: {
    icon: <SentimentNeutral />,
    getBackground: (theme?: Theme) => theme?.palette?.warning[50],
    getForeground: (theme?: Theme) => theme?.palette?.warning[900],
  },
  sad: {
    icon: <SentimentVeryDissatisfied />,
    getBackground: (theme?: Theme) => theme?.palette?.error[50],
    getForeground: (theme?: Theme) => theme?.palette?.error[900],
  },
};
//#endregion

//#region helpers
const getMood = (score: number): Mood => (score > 66 ? 'happy' : score > 33 ? 'neutral' : 'sad');
//#endregion

/**
 * SmileyChip is a component that displays a smiley face and a score along with a color.
 * @param score The score to display.
 * @returns {ReactJSXElement}
 */
export default function SmileyChip(props: SmileyChipProps) {
  const { score, percent, ...rest } = props;
  const mood = getMood(score);
  return <StyledChip icon={MOODS[mood].icon} mood={mood} label={`${score}${percent ?? false ? '%' : ''}`} {...rest} />;
}
