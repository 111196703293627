import { ReportId } from 'api/apiTypes';
import useLogs from 'api/logs';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import Report from 'api/types/report';
import useReport, { ReportContext } from 'api/useReport';
import { useContext, useEffect, useRef } from 'react';

export function error(messages?: string[]): ApiError {
  return {
    error: true,
    messages: messages ?? [],
  };
}

export function loading(): ApiLoading {
  return undefined;
}

/**
 * Checks if the current response is loading.
 */
export function isLoading(object: unknown): object is ApiLoading {
  return object === loading();
}

/**
 * Checks if the current response is errored.
 */
export function isErrored(object: unknown): object is ApiError {
  if (object === null) return true;
  if (object && object.hasOwnProperty('error')) return true;
  return false;
}

/**
 * Checks if the current response is errored, and if so, simply returns undefined.
 * This function is useful for when you want to use the response in a function or whatnot.
 */
export function withoutError<T>(object: T | ApiError): T | undefined {
  if (isErrored(object)) return undefined;
  return object;
}

/** Verification Hooks */
export function useRegisterMiddlewareHook(middlewareName: string, reportId: ReportId): Report | ApiLoading {
  const { log, logObject } = useLogs();

  const context = useContext(ReportContext);

  const currentReport = useReport(reportId);
  //const _privacyContext = useExperimentalContext(PrivacyContext);
  //const privacyContext = withoutError(_privacyContext);

  /*
  useEffect(() => {
    if (reportId) privacyContext?.getPrivacy(currentReport);
  }, [reportId, context, privacyContext, currentReport]);
  */

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log(`[API] invoke: ${middlewareName}`);
    }
  }, [currentReport, context, log, logObject, middlewareName]);

  //if (isLoading(currentReport) || isErrored(currentReport)) return currentReport;

  //if (isErrored(_privacyContext)) return _privacyContext;

  //const privacyResponse = privacyContext?.privacyCache[reportId];

  //if (isLoading(privacyResponse)) return loading();
  //if (isErrored(privacyResponse)) return error(privacyResponse.messages);

  //return privacyResponse;
  if (isErrored(currentReport)) return loading();
  return currentReport;
}

export function useDeferrer(delay = 500) {
  const time = useRef(new Date().getTime());

  if (new Date().getTime() - time.current > delay) {
    time.current = new Date().getTime();
    return true;
  }

  return false;
}
