import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a ReportId.
 */
export const ReportIdSchema = z.string().uuid().brand('ReportId');

/**
 * The ReportId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects a ReportId.
 */
type ReportId = z.infer<typeof ReportIdSchema>;

export default ReportId;
