import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

export default function LoaderPage() {
  return (
    <Stack key="page-loader" alignItems="center" justifyContent="center" width="100%" height="100%">
      <CircularProgress />
    </Stack>
  );
}
