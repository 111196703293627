import { z } from 'zod';

import { ApiPrivacyConsentConfigurationCategoriesSchema } from './categories';
import { ApiPrivacyConsentConfigurationLocateSchema } from './locate';
import { ApiPrivacyConsentConfigurationMappingsSchema } from './mappings';

export const ApiPrivacyConsentConfigurationSchema = z.object({
  /** Wether this cofiguration needs a confirmation dialog to confirm the mappings */
  confirmed: z.boolean(),
  locate: ApiPrivacyConsentConfigurationLocateSchema,
  mappings: z.array(ApiPrivacyConsentConfigurationMappingsSchema),
  categories: z.array(ApiPrivacyConsentConfigurationCategoriesSchema),
});

type ApiPrivacyConsentConfiguration = z.infer<typeof ApiPrivacyConsentConfigurationSchema>;

export default ApiPrivacyConsentConfiguration;
