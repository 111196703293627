import ApiProvider from 'api/provider';
import { Header } from 'components';
import React from 'react';
import { Outlet } from 'react-router-dom';

import LoaderPage from 'pages/loader';

/**
 * @summary AuthenticatedRoute is a component which navigate to user back to login page in user is not loggedIn
 * @function
 * @name AuthenticatedRoute
 * @return {ReactJSXElement} jsx component
 */
const AuthenticatedRoute = () => {
  return (
    <ApiProvider>
      <Header>
        <React.Suspense fallback={<LoaderPage />}>
          <Outlet />
        </React.Suspense>
      </Header>
    </ApiProvider>
  );
};

export default AuthenticatedRoute;
