import { z } from 'zod';

import { ApiBoardIdVendorSchema } from '../../boardId';

import { ApiVendorInsightsTableSchema } from './table';

export const ApiVendorInsightsSchema = z.object({
  boardId: ApiBoardIdVendorSchema,
  score: z.number().min(0).max(100).optional(),
  title: z.string(),
  table: ApiVendorInsightsTableSchema,
});

type ApiVendorInsights = z.infer<typeof ApiVendorInsightsSchema>;

export default ApiVendorInsights;
