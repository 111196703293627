import { ApiBoardIdSchema } from 'api/types/report/api/boardId';
import { z } from 'zod';

export const ApiVendorInsightsTableDataInsightNameSchema = z.object({
  name: z.string(),
  boardId: ApiBoardIdSchema,
  type: z.literal('link'),
});

type ApiVendorInsightsTableDataInsightName = z.infer<typeof ApiVendorInsightsTableDataInsightNameSchema>;

export default ApiVendorInsightsTableDataInsightName;
