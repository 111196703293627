import ReportId from 'api/types/report/id';

import { REPORT_ID_PARAM } from './config';
import useAppGeneralParams from './general';

export default function useAppReportParams() {
  const { params, setParam } = useAppGeneralParams();

  /**
   * The current reportId parameter, it is not only limited to the dashboard and insights page
   * Note: Use this carefully as it will download an entirely new report if the report is not cached
   * i.e. do not change the reportId multiple times unless the user is the one changing it
   */
  const reportId = params.get(REPORT_ID_PARAM) as ReportId | null;
  /** Set the current reportId */
  const setId = (id: ReportId) => setParam(REPORT_ID_PARAM, id);

  return {
    id: reportId,
    setId,
  };
}
