import { Autocomplete, AutocompleteProps } from '@mui/material';
import React, { useEffect } from 'react';

import * as Styled from 'pages/data-export/components/Segmentation/segmentation.styles';

type Props = Omit<AutocompleteProps<string, boolean, false, true>, 'options' | 'renderInput'> & {
  label?: string;
  error?: string | boolean;
};

export default function MultipleNumberInput(props: Props) {
  const { error, multiple, label, onChange, value, ...rest } = props;
  const [tempValue, setTempValue] = React.useState<string>('');

  useEffect(() => {
    if (tempValue !== null) setTempValue(`${value}`);
  }, [tempValue, value]);

  return (
    <Autocomplete
      {...rest}
      freeSolo
      fullWidth
      options={[]}
      onChange={onChange}
      inputValue={tempValue}
      onInputChange={(e, value) => {
        if (value.trim().length === 0) {
          if (!multiple && onChange) {
            onChange(e, null, 'createOption');
            return;
          }
        }
        const numberRegex = /^[0-9]*\.?[0-9]*$/;
        if (value !== null && value.match(numberRegex)) {
          setTempValue(value);
          if (!multiple && onChange) {
            onChange(e, value, 'createOption');
          }
        }
      }}
      multiple={multiple}
      renderInput={(params) => (
        <Styled.TextField
          {...params}
          label={label ?? 'Value'}
          error={!!error}
          helperText={typeof error === 'string' ? error : undefined}
        />
      )}
    />
  );
}
