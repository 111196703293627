import ReportId from 'api/types/report/id';
import useCurrentUser from 'api/useCurrentUser';
import useReports from 'api/useReports';
import { isErrored, withoutError } from 'api/utils';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { REPORT_ID_PARAM } from 'utils/params/config';

/**
 * Returns the selected report id from the url param, or
 * user default reportid, or the first report id in the
 * users reports.
 */
export function useReportId(): ReportId {
  const reports = useReports();
  const currentUser = useCurrentUser();
  const [params, setParams] = useSearchParams();

  const reportIdFromParams = reports?.find((x) => x.id === params.get(REPORT_ID_PARAM))?.id;

  useEffect(() => {
    // make sure reports and users are loaded, then make the assumption that
    // the report id in the URL is not valid
    if (!reportIdFromParams && reports?.length && withoutError(currentUser) && params.get(REPORT_ID_PARAM) !== null)
      setParams((prev) => (prev.delete(REPORT_ID_PARAM), prev));
  }, [currentUser, params, reportIdFromParams, reports?.length, setParams]);

  const reportId = (reportIdFromParams ||
    reports?.find((x) => (isErrored(currentUser) ? false : x.envGuid === currentUser?.defaultEnvironment))?.id ||
    reports?.[0]?.id ||
    undefined) as ReportId;

  return reportId;
}
