// Type definitions
// page flags - these flags are relevant to frontend pages
// role flags - these flags are relevant to user roles
// feature flags - these flags are relevant to individual features
// meta flags - these flags are relevant to the frontend in general and are not really used by users

type FlagType = 'page' | 'role' | 'feature' | 'meta';

type FlagSource = 'default' | 'custom' | 'user' | 'page' | 'env' | 'role' | 'override' | 'parent';

// Source definitions
// there are four main groups, defaults, standard, logical, and overrides
// the defaults are just there to have a value to begin with
// standard is where most feature flags operate
// logical is where we have flags that are derived from other flags
// overrides are where we can force a flag to be a certain value

// default - The default value as compiled by the frontend
// env - A value set by the environment variables at compile time

// page - A value set by the page's specific feature flags (returned by API)
// user - A value set by the user's specific feature flags (returned by API)

// parent - A value set by a parent feature flag
// role - A value set by the user's role (computed by frontend at runtime)

// custom - A value set by ANY function for whatever reason
// override - A value set by internal tools to override a feature flag

export const FlagSourcePriority: Record<FlagSource, number> = {
  default: 0,
  env: 1,
  page: 2,
  user: 3,
  parent: 4,
  role: 5,
  custom: 6,
  override: 7,
} as const;

export type FlagReason = {
  source: FlagSource;
  value: boolean;
};

type FlagRelationship = {
  id: string;
};

export type BaseFlag = {
  id: string;
  name: string;
  parent?: FlagRelationship;
  children?: FlagRelationship[];
  enabled: boolean;
  description: string;
  type: FlagType;
  reasons: FlagReason[];
};
