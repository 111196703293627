import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';

import { Unlisted } from './PasswordIndicator.style';

/**
 * The Password Validator object
 * @typedef {Object} PasswordValidity
 * @property {string} minChar - min charactoer validation of 8 letter.
 * @property {string} isCapital - atleast one capital letter.
 * @property {string} isSmall - atleast one small letter.
 * @property {number} number - atleast one numeric letter.
 */

/**
 * Represents Password Indicator component.
 * @memberof SignUp
 * @function
 * @name PasswordIndicator
 * @param {PasswordValidity} passwordValidity - validation varible
 * @return {ReactJSXElement} jsx component
 */
export default function PasswordIndicator({ passwordValidity }: any) {
  const PasswordStrengthIndicatorItem = ({ isValid, text }: any) => {
    if (isValid) {
      return (
        <li className="text-success">
          <CheckIcon style={{ verticalAlign: 'bottom' }} />
          {text}
        </li>
      );
    } else if (isValid !== null) {
      return (
        <li className="text-danger">
          <ClearIcon style={{ verticalAlign: 'bottom' }} />
          {text}
        </li>
      );
    } else {
      return <li>{text}</li>;
    }
  };

  return (
    <div data-testid="password">
      <p>Password must contain:</p>
      <Unlisted>
        <PasswordStrengthIndicatorItem isValid={passwordValidity?.minChar} text="Have at least 8 characters" />
        <PasswordStrengthIndicatorItem isValid={passwordValidity?.isCapital} text="Have at least 1 uppercase" />
        <PasswordStrengthIndicatorItem isValid={passwordValidity?.isSmall} text="Have at least 1 lowercase" />
        <PasswordStrengthIndicatorItem isValid={passwordValidity?.number} text="Have at least 1 number" />
        <PasswordStrengthIndicatorItem
          isValid={passwordValidity?.specialChar}
          text="Have at least 1 special character"
        />
      </Unlisted>
    </div>
  );
}
