import styled from '@emotion/styled';
import { Card, Theme } from '@mui/material';
import React from 'react';

const StyledCard = styled(Card)(
  ({ theme }: { theme?: Theme }) => `
    border-radius: 16px;
    border: 1px solid ${theme?.palette?.divider};
  `
);

export default function BorderedCard(props: React.ComponentProps<typeof Card>) {
  return <StyledCard {...props} variant="outlined" />;
}
