import { z } from 'zod';

import { ApiPerformanceJsonTagLoadTimesSchema } from './tagLoadTimes';

export const ApiPerformanceJsonSchema = z.object({
  avg_page_load_time: z.string().endsWith('s').optional(),
  avg_perceived_page_load_time: z.string().endsWith('s').optional(),
  tag_load_times: z
    .object({
      fast: ApiPerformanceJsonTagLoadTimesSchema.optional(),
      medium: ApiPerformanceJsonTagLoadTimesSchema.optional(),
    })
    .optional(),
});

type ApiPerformanceJson = z.infer<typeof ApiPerformanceJsonSchema>;

export default ApiPerformanceJson;
