import styled from '@emotion/styled';
import { Button as MuiButton, Switch as MuiSwitch, Theme } from '@mui/material';
import { withoutProps } from 'utils';

import { BigBoySwitchProps } from '.';

/**
 * This button is a specially styled "pill" button that has a border when unchecked,
 * but a background color when checked.
 */
export const Button = styled(MuiButton)(
  ({ theme, checked }: Partial<BigBoySwitchProps> & { theme?: Theme }) => `
  width: 100%;
  display: flex;
  flex: 1 0 100%;
  padding: 8px 16px;
  border-radius: 16px;
  align-items: center;
  text-transform: none;
  justify-content: flex-start;

    ${
      checked
        ? `
        border: 1px solid transparent;
        color: ${theme?.palette?.primary?.main};
        background: ${theme?.palette?.primary[50]};
      `
        : `
        color: ${theme?.palette?.primary?.main};
        border: 1px solid ${theme?.palette?.primary[50]};
        background: ${theme?.palette?.background?.default};
      `
    }

  &.Mui-disabled {
    color: unset;
  }
  `
);

/**
 * This switch is only a visual indicator for the user, it is not clickable, hence the pointer-events: none.
 */
export const Switch = styled(
  MuiSwitch,
  withoutProps('isToggleable')
)(
  ({ isToggleable }: { isToggleable?: boolean }) => `
    pointer-events: none;

    ${
      isToggleable
        ? ''
        : `
          width: 0;
          opacity: 0;
          padding: 0;
        `
    }
  `
);
