export const drawer = {
  widths: {
    normal: 240,
    mini: 48,
    none: 0,
    breakpoints: {
      xl: 240,
      lg: 240,
      md: 240,
      sm: 48,
      xs: 0,
    },
  },
};

export const environmentSelector = {
  maxWidths: {
    normal: 350,
    breakpoints: {
      xl: 350,
      lg: 350,
      md: 350,
      sm: 350,
      xs: 350,
    },
  },
};
