import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a UserId.
 */
export const UserIdSchema = z.string().uuid().brand('UserId');

/**
 * The UserId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects a UserId.
 */
type UserId = z.infer<typeof UserIdSchema>;

export default UserId;
