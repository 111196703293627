import BoardId, { VendorBoardId } from 'api/types/report/boardId';
import { StatusPRC } from 'api/types/report/status';
import { useCallback, useMemo } from 'react';

import {
  BOARD_ID_PARAM,
  CATEGORY_PARAM,
  LABEL_PARAM,
  SEARCH_PARAM,
  SORT_PARAM,
  STATUS_PARAM,
  VENDOR_ID_PARAM,
} from './config';
import useAppGeneralParams from './general';

type SortParam = 'name' | 'size';

type VendorId = VendorBoardId | 'Any';

/** The hook used to manipulate URL parameters on the insights page */
export default function useAppInsightsParams() {
  const { params, setParam } = useAppGeneralParams();

  /** The current boardId parameter */
  const boardId = params.get(BOARD_ID_PARAM) as BoardId | null;
  /** Function to set the current boardId parameter */
  const setBoardId = useCallback((id: BoardId | null) => setParam(BOARD_ID_PARAM, id), [setParam]);

  /** The current status in pass, review, critical format */
  const status = (params.get(STATUS_PARAM) as StatusPRC | null) ?? 'critical';
  /** Function to set the current status in pass, review, critical format */
  const setStatus = useCallback((status: StatusPRC | null) => setParam(STATUS_PARAM, status), [setParam]);

  /** The selected category for filtering */
  const category = params.get(CATEGORY_PARAM) ?? 'Any';
  /** Function to set the category */
  const setCategory = useCallback((category: string) => setParam(CATEGORY_PARAM, category), [setParam]);

  /** The sort method */
  const sort = (params.get(SORT_PARAM) as SortParam | null) ?? 'name';
  /** Function to set the sort method */
  const setSort = useCallback((sort: SortParam) => setParam(SORT_PARAM, sort), [setParam]);

  /** The current vendor to show results for */
  const vendorId = (params.get(VENDOR_ID_PARAM) as VendorId | null) ?? 'Any';
  /** Function to set the current vendor to show results for */
  const setVendorId = useCallback((vendorId: VendorId) => setParam(VENDOR_ID_PARAM, vendorId), [setParam]);

  /** The current vendor to show results for */
  const search = (params.get(SEARCH_PARAM) as string | null) ?? '';
  /** Function to set the current vendor to show results for */
  const setSearch = useCallback((search: string) => setParam(SEARCH_PARAM, search), [setParam]);

  /**
   * Labels are stored in the url as labelId=insightId1%3D%26insightId2%3D
   * the value part is url decoded to insightId1=&insightId2= which then is parsed by URLSearchParams
   * as ['insightId1', 'insightId2']
   */

  const labels = useMemo(() => Array.from(new URLSearchParams(params.get(LABEL_PARAM) ?? '').keys()), [params]);
  const setLabels = useCallback(
    (labels: string[]) => setParam(LABEL_PARAM, new URLSearchParams(labels.map((x) => [x, ''] as any)).toString()),
    [setParam]
  );

  return {
    boardId,
    setBoardId,

    status,
    setStatus,

    category,
    setCategory,

    sort,
    setSort,

    vendorId,
    setVendorId,

    labels,
    setLabels,

    search,
    setSearch,
  };
}
