import { z } from 'zod';

// used for mongodb functions
export const PostfixFunctionSchema = z.object({
  $function: z.string(),
});

type PostfixFunction = z.infer<typeof PostfixFunctionSchema>;

export default PostfixFunction;
