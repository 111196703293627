import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import React, { ImgHTMLAttributes } from 'react';
import { withoutProps } from 'utils';

const LOGO_PATH = '/logo.svg';
const LOGO_ICON_PATH = '/logo_icon.svg';
const LOGO_DARK_PATH = '/logo_dark.svg';

const StyledImage = styled(
  'img',
  withoutProps('fullWidth', 'fullHeight', 'marginY', 'marginX', 'minWidth', 'small')
)(
  ({ fullWidth, fullHeight, marginX, marginY, minWidth, small }: LogoProps) => `
    display: flex;

    margin: ${marginY} ${marginX};

    min-width: ${typeof minWidth === 'number' ? `${minWidth}px` : minWidth};

    ${
      fullWidth
        ? `
          width: calc(100% - ${marginX} - ${marginX});
          height: auto;`
        : fullHeight
        ? `
          width: auto;
          height: calc(100% - ${marginY} - ${marginY});
        `
        : ``
    }

    ${small ? `` : ``}
  `
);

type LogoProps = {
  small?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  marginX?: number | string;
  marginY?: number | string;
  minWidth?: number | string;
} & ImgHTMLAttributes<HTMLImageElement>;

export default function Logo(props: LogoProps) {
  const theme = useTheme();

  const { marginX: marginXprop, marginY: marginYprop, small, ...rest } = props;

  const marginXpixels = marginXprop ?? 0;
  const marginYpixels = marginYprop ?? 0;

  const marginX = typeof marginXpixels === 'number' ? `${marginXpixels}px` : marginXpixels;
  const marginY = typeof marginYpixels === 'number' ? `${marginYpixels}px` : marginYpixels;

  const url = small ? LOGO_ICON_PATH : theme.palette.mode === 'dark' ? LOGO_DARK_PATH : LOGO_PATH;

  return <StyledImage {...rest} src={url} marginX={marginX} marginY={marginY} small={small ?? false} />;
}
