import { z } from 'zod';

export const ApiSourceOfTruthCustomizationVariablesToIgnoreSchema = z.object({
  groupPath: z.string(),
  match: z.union([z.string(), z.boolean()]).optional(),
  localGroupPathMap: z.record(z.string()).optional(),
  groupPathFriendlyName: z.string().optional(),
});

export type ApiSourceOfTruthCustomizationVariablesToIgnore = z.infer<
  typeof ApiSourceOfTruthCustomizationVariablesToIgnoreSchema
>;
