import { EventTypesShortCodes } from 'api/apiTypes/const';

const EventTypesLabels: Record<keyof typeof EventTypesShortCodes, string> = {
  debugArray: 'Debug',
  customArray: 'Custom',
  dataLayerArray: 'Data Layer',
  dataLayerEventArray: 'Data Layer Event',
  domEventArray: 'DOM Event',
  jsErrorArray: 'JavaScript Error',
  requestArray: 'Request',
  requestFailedArray: 'Request Failed',
  generalErrorArray: 'General Error',
  anomalyArray: 'Anomaly',
  piiArray: 'PII',
  insightArray: 'Insight',
  interactionArray: 'Interaction',
  unknownRequestArray: 'Unknown Request',
  newVariableArray: 'New Variable',
  consentArray: 'Consent',
  unknownCookieArray: 'Unknown Cookie',
  missingRequests: 'Missing Requests',
  serverSideInsightArray: 'Server Side Insight',
  cspArray: 'Content Security Policy',
} as const;

export default EventTypesLabels;
