import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

import PublicRoute from '.';

const getPublicRoutes: RouteListGetter = (lazily) => {
  // const Login = lazily('login');
  const SSO = lazily('sso');
  const SSOAuth = lazily('sso/auth');
  const ForgetPassword = lazily('forget-password');
  const ResetPassword = lazily('reset-password');
  const SignUp = lazily('sign-up');
  const NotFound = lazily('404');

  return (
    <Route path="/" element={<PublicRoute />}>
      {/*<Route path={AppRoutes.LOGIN} element={<Login />} />*/}
      <Route path={AppRoutes.FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={AppRoutes.SIGN_UP} element={<SignUp />} />
      <Route path={AppRoutes.LOGIN} element={<SSO />} />
      <Route path={AppRoutes.SSOAuth} element={<SSOAuth />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  );
};

export default getPublicRoutes;
