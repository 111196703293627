import ReportId from 'api/types/report/id';
import { StatusPRC } from 'api/types/report/status';

export const AppRoutes = {
  /* eslint-disable @typescript-eslint/naming-convention */
  ACCOUNT: '/account',
  COMPONENTS: '/components',
  DASHBOARD: '/',
  FORGET_PASSWORD: '/forget-password',
  NOTIFICATIONS: '/alerting-settings',
  NOTIFICATIONS_CREATE: '/alerting-settings/create',
  NOTIFICATIONS_DUPLICATE: '/alerting-settings/create/:id',
  NOTIFICATIONS_VIEW: '/alerting-settings/view',
  TECHNOLOGY: '/technology',
  INSIGHTS: '/insights',
  PRIVACY: '/privacy',
  PRIVACY_INSIGHTS: '/privacy/insights',
  ADMIN: '/admin',
  INTERNAL_ADMIN: '/rule-configuration',
  HELP: '/help',
  LOGIN: '/login',
  REPORT: '/report/:reportId',
  RESET_PASSWORD: '/reset-password',
  SIGN_UP: '/activate-user',
  SSO: '/sso',
  SSOAuth: '/auth',
  /* eslint-enable */
  ruleConfiguration: {
    base: '/rule-configuration',
    index() {
      return this.base;
    },
  },
  explore: {
    base: '/explore',
    index() {
      return this.base;
    },
  },
  knowledgeBase: {
    base: '/knowledge-base',
    index() {
      return this.base;
    },
    article(articleId: string) {
      return `${this.base}?articleId=${articleId}`;
    },
  },
  dataExport: {
    base: '/data-export',
    index() {
      return this.base;
    },
    download(jobId: string) {
      return `${this.base}/${jobId}`;
    },
  },
};

/*
 * Build functions are used to create proper and consistent URLs in the app.
 * This prevents us from manually concatenating strings and risking typos / bugs.
 */

export function BuildInsightURL(params: {
  boardId?: string;
  reportId: ReportId;
  vendorId?: string;
  status?: StatusPRC;
  category?: string;
}) {
  const rest = params;
  return `${AppRoutes.INSIGHTS}?${new URLSearchParams(rest).toString()}`;
}

export function BuildDashboardURL(params: { reportId: ReportId }) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('reportId', params.reportId);
  return `${AppRoutes.DASHBOARD}?${searchParams.toString()}`;
}

export function BuildPrivacyInsightsURL(params: {
  boardId?: string;
  reportId: ReportId;
  vendorId?: string;
  status?: StatusPRC;
}) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('reportId', params.reportId);
  params.boardId && searchParams.set('boardId', params.boardId);
  params.vendorId && searchParams.set('vendorId', params.vendorId);
  params.status && searchParams.set('status', params.status);
  return `${AppRoutes.PRIVACY_INSIGHTS}?${searchParams.toString()}`;
}

export function BuildNotificationsViewURL(params: { id: string }) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('id', params.id);
  return `${AppRoutes.NOTIFICATIONS_VIEW}?${searchParams.toString()}`;
}
