const MISSING_REQUESTS_ENDPOINT = '/missing-tag-rules';

export const MissingTagRulesRoutes = {
  get: (envGuid: string) => `${MISSING_REQUESTS_ENDPOINT}/${envGuid}`,
  create: (envGuid: string, tagId: string) => `${MISSING_REQUESTS_ENDPOINT}/${envGuid}/tags/${tagId}`,
  update: (envGuid: string, tagId: string, ruleId: string) =>
    `${MISSING_REQUESTS_ENDPOINT}/${envGuid}/tags/${tagId}/rules/${encodeURIComponent(ruleId)}`,
  delete: (envGuid: string, tagId: string, ruleId: string) =>
    `${MISSING_REQUESTS_ENDPOINT}/${envGuid}/tags/${tagId}/rules/${encodeURIComponent(ruleId)}`,
};
