import { ListSubheader, MenuItem, Stack } from '@mui/material';
import React from 'react';

export type MenuItem = {
  key?: string;
  value: string | null;
  label: string;
  url?: string;
  props?: any;
};

export default function MenuItems(menuitems: MenuItem[]) {
  return menuitems.map((item, index) => {
    let props = item.props ?? {};
    let key = item.key;
    if ('key' in props) {
      const { key: propKey, ...rest } = props;
      props = rest;
      if (!key) {
        key = propKey;
      }
    }

    if (!key) {
      key = item.value ?? index.toString();
    }

    return typeof item.value === 'string' ? (
      <MenuItem key={key} value={item.value} {...props}>
        <Stack direction="row" alignItems="center" gap={1}>
          {!!item.url?.length && <img src={item.url} alt={item.label} width="20" height="20" />}
          {item.label}
        </Stack>
      </MenuItem>
    ) : (
      <ListSubheader
        key={item.key ?? index}
        sx={{
          borderTopColor: 'divider',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
        }}
      >
        {item.label}
      </ListSubheader>
    );
  });
}
