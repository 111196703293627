import { z } from 'zod';

export const ApiSourceOfTruthCustomizationSummarySchema = z.record(
  z.union([
    z.string(),
    z.object({
      types: z.array(z.string()),
      totalPagesWithVariable: z.number().optional(),
      totalPagesWithAutoRulesForVariable: z.number().optional(),
      totalPagesHaving25OrMoreValuesForVariable: z.number().optional(),
      totalPagesHavingMoreThan25PageViewsWithVariable: z.number().optional(),
      totalPagesHavingMoreThanOneValueForVariable: z.number().optional(),
      totalPagesNeededCustomRulesForVariable: z.number().optional(),
      totalPagesHavingCustomRulesForVariable: z.number().optional(), // summary % custom
      groupPathFriendly: z.string().optional(),
    }),
  ])
);

export type ApiSourceOfTruthCustomizationSummary = z.infer<typeof ApiSourceOfTruthCustomizationSummarySchema>;
