import { Portal } from '@mui/base';
import { Close, Download, KeyboardArrowDown, KeyboardArrowUp, Troubleshoot } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useReports } from 'api';
import { ReportId } from 'api/apiTypes';
import useExperiments from 'api/experiments';
import useRawApiResponse from 'api/raw';
import { ReportContext } from 'api/useReport';
import { isErrored, isLoading, withoutError } from 'api/utils';
import ObjectViewer from 'components/ObjectViewer';
import React, { useContext, useEffect, useRef, useState } from 'react';

export default function InternalToolsOverlaysCurrentlyDownloadedReports() {
  const context = useContext(ReportContext);
  const reports = useReports();
  const rawStore = useRawApiResponse();
  // Bottom part of the sidedrawer
  const [el, setEl] = useState<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);
  const { counter } = useExperiments();

  useEffect(() => {
    const _ = counter;
    const component = document.querySelector('#root .MuiDrawer-root .MuiDrawer-paper > div:nth-child(2)');
    if (component) {
      const element = document.createElement('div');
      component.prepend(element);
      setEl(element);
    }
  }, [counter]);

  const [debugReport, setDebugReport] = useState<keyof typeof context.reportCache | null>(null);

  rawStore.Any = {
    ...rawStore.Any,
    setDebugReportId: setDebugReport,
  };

  const debugReportData = debugReport ? withoutError(context.reportCache[debugReport]) : null;

  return (
    <>
      {el && (
        <Portal container={el}>
          <List dense>
            <Divider />
            {open ? (
              <>
                <ListItemButton onClick={() => setOpen(false)} sx={{ justifyContent: 'center' }}>
                  <KeyboardArrowDown fontSize="small" />
                </ListItemButton>
                <Divider />
                {Object.keys(context.reportCache).map((x) => {
                  const report = context.reportCache[x as keyof typeof context.reportCache];
                  const partialReport = reports.find((y) => y.id === x);
                  return (
                    <React.Fragment key={x}>
                      <ListItem>
                        <ListItemText
                          primary={partialReport?.name ?? 'N/A'}
                          secondary={
                            isErrored(report) ? (
                              <Typography color="error.main" variant="overline" lineHeight={1} fontWeight={500}>
                                Error
                              </Typography>
                            ) : isLoading(report) ? (
                              <Typography color="warning.main" variant="overline" lineHeight={1} fontWeight={500}>
                                Loading
                              </Typography>
                            ) : (
                              <Typography color="success.main" variant="overline" lineHeight={1} fontWeight={500}>
                                Cached
                              </Typography>
                            )
                          }
                          //eslint-disable-next-line @typescript-eslint/naming-convention
                          sx={{ '*': { fontSize: '0.7em', wordBreak: 'break-all' } }}
                        />
                        <Tooltip title="Inspect & Debug Report Data">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => setDebugReport(x as keyof typeof context.reportCache)}
                          >
                            <Troubleshoot fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <ListItemButton onClick={() => setOpen(true)} sx={{ justifyContent: 'center' }}>
                  <Stack alignItems="center">
                    <KeyboardArrowUp fontSize="small" />
                    <Typography variant="overline" display="block" align="center">
                      Cached Report Info
                    </Typography>
                  </Stack>
                </ListItemButton>
                <Divider />
              </>
            )}
          </List>
        </Portal>
      )}
      {debugReport && (
        <Modal
          open={debugReport !== null}
          onClose={() => setDebugReport(null)}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Card
            elevation={4}
            sx={{
              minWidth: '45%',
              maxWidth: 1200,
              maxHeight: '80%',
              overflowY: 'auto',
              // eslint-disable-next-line
             ':focus-visible': { outline: 'none' }
            }}
          >
            <CardHeader
              title={debugReportData?.name}
              subheader={
                <>
                  Report ID: {debugReport}
                  <br />
                  Client (DB) ID: {debugReportData?.summary.json.clientGuid}
                  <br />
                  Environment ID: {debugReportData?.summary.json.environmentId}
                </>
              }
              action={
                <>
                  <ExportReport reportId={debugReport} />
                  <IconButton color="primary" onClick={() => setDebugReport(null)}>
                    <Close />
                  </IconButton>
                </>
              }
            />
            <CardContent>
              {debugReportData ? (
                <div>Data</div>
              ) : (
                <>
                  <Typography>
                    We are unable parse this report, a raw object viewer has been provided instead.
                  </Typography>
                </>
              )}
              <ObjectViewer
                object={rawStore[debugReport]?.reportData}
                errors={rawStore[debugReport]?.reportErrors?.errors?.map((x) => ({
                  path: x.path.join('.').replace(/^insight\./g, ''),
                  message: x.message,
                }))}
              />
            </CardContent>
          </Card>
        </Modal>
      )}
    </>
  );
}

function ExportReport({ reportId }: { reportId: ReportId }) {
  const _d = reportId;
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const rawStore = useRawApiResponse();

  const exportd = (all = false) => {
    setOpen(false);
    const data = all
      ? Object.entries(rawStore)
          .filter(([, value]) => value?.reportErrors?.errors?.length)
          .map(([key, value]) => ({
            [`reportId:${key}, envId:${rawStore[key as any].reportData?.summary?.json?.environmentId}`]:
              value?.reportErrors?.errors,
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : rawStore[reportId].reportErrors.errors;
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${all ? 'all' : reportId}-errors.json`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Button ref={anchorEl} endIcon={<Download />} onClick={() => setOpen(true)}>
        Export Errors
      </Button>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorEl.current}>
        <MenuItem onClick={() => exportd(false)}>Errors for this report</MenuItem>
        <MenuItem onClick={() => exportd(true)}>Errors in all reports (downloaded only)</MenuItem>
      </Menu>
    </>
  );
}
