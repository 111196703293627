import { z } from 'zod';

export const ApiVendorInsightsTableHeaderSchema = z.object({
  key: z.union([z.literal('category'), z.literal('insightName'), z.literal('result')]),
  name: z.union([z.literal('Insight Category'), z.literal('Insight Name'), z.literal('Result')]),
});

type ApiVendorInsightsTableHeader = z.infer<typeof ApiVendorInsightsTableHeaderSchema>;

export default ApiVendorInsightsTableHeader;
