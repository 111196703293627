import { Alert, Container, CssBaseline, Grid } from '@mui/material';
import { PureLink } from 'components';
import Logo from 'components/Logo';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Paper } from './PublicRouter.style';

const rawBannerAlert = {
  text: process.env.REACT_APP_TOP_BANNER_ALERT_TEXT,
  icon: process.env.REACT_APP_TOP_BANNER_ALERT_ICON,
  severity: process.env.REACT_APP_TOP_BANNER_ALERT_SEVERITY,
};

type BannerAlert = {
  text: string;
  icon?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
};

const bannerAlert: BannerAlert = {
  text: rawBannerAlert.text || '',
  icon: rawBannerAlert.icon || undefined,
  severity: undefined,
};

if (
  rawBannerAlert.severity &&
  // use individual if statements to make typescript happy
  (rawBannerAlert.severity === 'info' ||
    rawBannerAlert.severity === 'warning' ||
    rawBannerAlert.severity === 'error' ||
    rawBannerAlert.severity === 'success')
) {
  bannerAlert.severity = rawBannerAlert.severity;
}

/**
 * Represents public routes.
 * @function
 * @name PublicRoute
 * @return {ReactJSXElement} jsx component
 */
const PublicRoute = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <Grid item container direction="column" justifyContent="center">
          <PureLink to="https://www.sentinelinsights.com/">
            <Logo fullWidth marginX={64} marginY={24} />
          </PureLink>
          {bannerAlert.text.length > 0 && (
            <Alert severity={bannerAlert.severity ?? 'info'} icon={bannerAlert.icon} sx={{ alignItems: 'center' }}>
              {bannerAlert.text}
            </Alert>
          )}
        </Grid>
        <Outlet />
      </Paper>
    </Container>
  );
};

export default PublicRoute;
