import { z } from 'zod';

export const ApiTopFindingsJsonSummarySchema = z.object({
  threat: z.literal('warning').or(z.literal('danger')),
  title: z.string(),
  description: z.string(),
});

type ApiTopFindingsJsonSummary = z.infer<typeof ApiTopFindingsJsonSummarySchema>;

export default ApiTopFindingsJsonSummary;
