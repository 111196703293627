import styled from '@emotion/styled';
import { DragIndicator as MuiDragIndicatorIcon } from '@mui/icons-material';
import { Card as MuiCard, Stack, Theme } from '@mui/material';
import { withoutProps } from 'utils';

export const Card = styled(MuiCard)(
  ({ theme }: { theme?: Theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    box-shadow: none;
    border-radius: 16px;
    align-items: stretch;
    flex-direction: column;
    justify-content: stretch;
    border: 1px solid ${theme?.palette?.divider};
  `
);

export const CardActions = styled(Stack)`
  gap: 8px;
  margin-left: 16px;
  flex-direction: row;
  align-items: center;
`;

export const CardContent = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
    height: 100%;
    min-height: 0;
    flex: 0 1 100%;
    overflow-y: auto;

    :not(#a#a)::-webkit-scrollbar{
      width: 0.25em;
      height: 0.25em;
    }

    :not(#a#a)::-webkit-scrollbar-track {
      border-radius: 100px;
      background: transparent;
    }

    :not(#a#a)::-webkit-scrollbar-thumb {
      border: none;
      border-radius: 100px;
      background: ${theme?.palette?.divider};
    }
  `
);

export const DraggableHeader = styled(
  Stack,
  withoutProps('dragIndicatorVisible')
)(
  ({ dragIndicatorVisible }: { dragIndicatorVisible: boolean }) => `
    cursor: grab;
    flex-grow: 1;
    padding: 16px 0;
    align-items: center;
    flex-direction: row;

    &:active {
      cursor: grabbing;
    }
    
    ${
      !dragIndicatorVisible
        ? `
    &:hover .MuiSvgIcon-root {
      width: 1em;
      margin-right: 0.25em;
    }
    `
        : ''
    }
  `
);

export const DragIndicator = styled(MuiDragIndicatorIcon)(
  ({ theme }: { theme?: Theme }) => `
    width: 0;
    margin-right: 0;
    color: ${theme?.palette?.primary[200]};
    transition: width 200ms ease, margin-right 200ms ease;
  `
);

export const Header = styled(
  Stack,
  withoutProps('dropShadowVisible')
)(
  ({ dropShadowVisible, theme }: { dropShadowVisible: boolean; theme?: Theme }) => `
    flex: 0 0 auto;
    user-select: none;
    align-items: center;
    flex-direction: row;
    padding: 0 9px 0 16px;
    transition: box-shadow 200ms ease;
    ${
      dropShadowVisible
        ? `
        box-shadow: none;
        `
        : `
        ${theme?.palette?.mode === 'light' ? `box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);` : ''}
        border-bottom: 1px solid ${theme?.palette?.divider};
    `
    }
  `
);
