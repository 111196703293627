import { Close, Speed } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Stateful } from 'utils';

type RegexTesterProps = {
  regex: string;
  setRegex: Stateful<string>;
  values: string[];
};

export default function RegexTester(props: RegexTesterProps) {
  const { regex, setRegex, values } = props;
  const [open, setOpen] = useState(false);

  const [matchedValues, setMatchedValues] = useState<string[]>([]);

  useEffect(() => {
    try {
      if (regex.length === 0) {
        setMatchedValues(values);
      } else {
        const regexp = new RegExp(regex, 'i');
        setMatchedValues(values.filter((x) => regexp.test(x)));
      }
    } catch (_) {}
  }, [regex, values]);

  return (
    <>
      <Tooltip title="Test regex">
        <IconButton onClick={() => setOpen(true)}>
          <Speed />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            flex: '0 1 700px',
            maxHeight: '90vh',
            overflow: 'visible',
          }}
        >
          <CardHeader
            title="Regex tester"
            action={
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            }
          />
          <CardContent>
            <Stack gap={1}>
              <TextField
                size="small"
                label="Regular Expression"
                placeholder="\d*"
                helperText={`Matching ${regex.length === 0 ? 0 : matchedValues.length} out of ${values.length} values`}
                fullWidth
                value={regex}
                onChange={(e) => setRegex(e.target.value)}
              />
              <Divider />
              <List
                disablePadding
                sx={{
                  borderColor: 'divider',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  maxHeight: '50vh',
                  overflow: 'auto',
                }}
              >
                {matchedValues.map((x, i) => [
                  i !== 0 && <Divider key={x + '-divider'} />,
                  <ListItem key={x}>
                    <ListItemText primary={x} />
                  </ListItem>,
                ])}
              </List>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}
