import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import type { CardProps } from '..';

import useMenu from './useMenu';
import useScroll from './useScroll';

export default function useCard(props: CardProps) {
  const {
    menu,
    skeleton,
    className,
    adornerLeft,
    resizeHandle,
    style: _style,
    header: _header,
    showScrollButtons,
    ...rest
  } = props;

  const { menuOpen, menuAnchor, openMenu, closeMenu, anchorOrigin, transformOrigin } = useMenu();
  const { isAtTop, canScrollLeft, canScrollRight, scrollLeft, scrollRight, scrollerRef } = useScroll();

  const [offset, setOffset] = useState(10);

  useEffect(() => setOffset(0), []);

  const style = transformStyles(_style, offset);
  const dragIndicatorVisible = !!adornerLeft;
  const header = typeof _header === 'string' ? <Typography variant="h6">{_header}</Typography> : _header;

  return {
    style,
    header,
    ...rest,
    className,
    adornerLeft,

    resizeHandle,
    dragIndicatorVisible,
    dropShadowVisible: isAtTop,

    showSkeleton: skeleton,

    scroller: {
      ref: scrollerRef,
      buttonsVisible: showScrollButtons,
      leftButton: {
        disabled: !canScrollLeft,
        onClick: scrollLeft,
      },
      rightButton: {
        disabled: !canScrollRight,
        onClick: scrollRight,
      },
    },
    menu: {
      hasContent: !!menu,
      content: menu,
      open: menuOpen,
      anchorEl: menuAnchor,
      onClose: closeMenu,
      onClick: openMenu,
      anchorOrigin,
      transformOrigin,
    },
  };
}

function transformStyles(style: React.CSSProperties | undefined, offset: number): React.CSSProperties {
  const { top, left, ...restStyle } = style ?? {};
  return {
    ...restStyle,
    marginTop: top, //offset ? `calc(${top} + ${offset}px)` : top,
    marginLeft: offset ? `calc(${left} + ${offset}px)` : left,
  };
}
