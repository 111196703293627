import { SupportedOperands } from 'api/apiTypes';
import { EventTypesKeys } from 'api/apiTypes/const/eventTypes';
import { z } from 'zod';

import { PostfixLogicSchema } from '../postfixLogic';

export type EventTypesKeysTypes = keyof typeof EventTypesKeys;

export type SupportedOperandsType = keyof typeof SupportedOperands;

export const NotificationWhatSchema = PostfixLogicSchema.merge(
  z.object({
    eventType: z.enum(Object.keys(EventTypesKeys) as [EventTypesKeysTypes, ...EventTypesKeysTypes[]]),
  })
);

type NotificationWhat = z.infer<typeof NotificationWhatSchema>;

export default NotificationWhat;
