import styled from '@emotion/styled';
import { ListItemButton as MuiListItemButton } from '@mui/material';
import { borderRadius } from 'utils';

import { environmentSelector } from 'config/responsive';

export const ListItemButton = styled(MuiListItemButton)`
  flex: 0 1 auto;
  ${borderRadius(2)}
  max-width: ${environmentSelector.maxWidths.normal}px;
`;
