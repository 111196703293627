import { z } from 'zod';

export const ApiSourceOfTruthPageFilterSchema = z.object({
  dimension: z.union([z.literal('PAGE_URL'), z.literal('GROUP_VARIABLE')]),
  operator: z.union([
    z.literal('EQUALS'),
    z.literal('NOT_EQUALS'),
    z.literal('CONTAINS'),
    z.literal('NOT_CONTAINS'),
    z.literal('MATCHES_REGEX'),
    z.literal('NOT_MATCHES_REGEX'),
  ]),
  value: z.string(),
});

export type ApiSourceOfTruthPageFilter = z.infer<typeof ApiSourceOfTruthPageFilterSchema>;
