import { z } from 'zod';

// used for mongodb functions
export const PostfixRegexSchema = z.object({
  $regularExpression: z.object({
    pattern: z.string(),
    options: z.string(),
  }),
});

export type PostfixRegex = z.infer<typeof PostfixRegexSchema>;
