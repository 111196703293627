import {
  ApiSourceOfTruthCustomizationValuesToIgnore,
  ApiSourceOfTruthCustomizationValuesToIgnoreSchema,
  ReportId,
  TagId,
} from 'api/apiTypes';
import routes from 'api/routes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import { useContext, useEffect } from 'react';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthIgnoredValues(
  reportId: ReportId,
  tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;
  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  useEffect(() => {
    if (currentReport && tagId && !ignoredValues) sourceOfTruthContext.getIgnoredValues(currentReport, tagId, match);
  }, [currentReport, reportId, sourceOfTruthContext, tagId, match, ignoredValues]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredValues)) return loading();
  if (isErrored(ignoredValues)) return error(ignoredValues.messages);

  return ignoredValues;
}

export function useCreateSourceOfTruthIgnoredValue(reportId: ReportId, tagId: TagId, match: string | boolean) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { post } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;
  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const create = async (ignoredValues: ApiSourceOfTruthCustomizationValuesToIgnore, match: string | boolean) => {
    const data = ApiSourceOfTruthCustomizationValuesToIgnoreSchema.parse(ignoredValues);
    await post(
      routes.sourceOfTruth.ignoredValues.create(
        currentReport.summary.json.environmentId,
        tagId,
        encodeURIComponent(match)
      ),
      data,
      {
        withCredentials: true,
      }
    );

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }
  };

  return create;
}

export function useUpdateSourceOfTruthIgnoredValue(reportId: ReportId, tagId: TagId, match: string | boolean) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (ignoredValues: ApiSourceOfTruthCustomizationValuesToIgnore, match: string | boolean) => {
    const data = ApiSourceOfTruthCustomizationValuesToIgnoreSchema.parse(ignoredValues);
    await patch(
      routes.sourceOfTruth.ignoredValues.update(
        currentReport.summary.json.environmentId,
        tagId,
        encodeURIComponent(match)
      ),
      data,
      {
        withCredentials: true,
      }
    );

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }
  };

  return update;
}

export function useDeleteSourceOfTruthIgnoredValue(reportId: ReportId, tagId: TagId, match: string | boolean) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteReq } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const customizationKey = withoutError(currentReport)?.summary.json.environmentId + tagId;

  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (match: string | boolean, groupPath: string) => {
    await deleteReq(
      routes.sourceOfTruth.ignoredValues.delete(
        currentReport.summary.json.environmentId,
        tagId,
        encodeURIComponent(match),
        groupPath
      ),
      {
        withCredentials: true,
      }
    );

    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
  };

  return update;
}
