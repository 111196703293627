import { pageSpecificSOTOperators } from 'pages/internal/admin/components/SourceOfTruthModalPage/components/PageSpecificFilterView';

const PageSpecificSOTOperators: Record<keyof typeof pageSpecificSOTOperators, string> = {
  EQUALS: 'Equals',
  CONTAINS: 'Contains',
  //eslint-disable-next-line
  MATCHES_REGEX: 'Matches regex',
  //eslint-disable-next-line
  NOT_EQUALS: 'Does not equal',
  //eslint-disable-next-line
  NOT_CONTAINS: 'Does not contain',
  //eslint-disable-next-line
  NOT_MATCHES_REGEX: 'Does not match regex',
};

export default PageSpecificSOTOperators;
