import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getDashboardRoutes: RouteListGetter = (lazily, isFlagEnabled) => {
  if (!isFlagEnabled('page.insights')) return <></>;

  const DashboardPage2 = lazily('dashboard2');

  return (
    <>
      <Route path="/api-dashboard" element={<DashboardPage2 />} />
    </>
  );
};

export default getDashboardRoutes;
