import { z } from 'zod';

import { ApiBoardIdSchema } from '../boardId';

import { ApiInsightTableSchema } from './tableData';

export const ApiInsightSchema = z.object({
  /** The title of the insight */
  title: z.string(),
  /** The data associated with the insight */
  table: ApiInsightTableSchema,
  /**
   * The id of the board the insight is associated with
   * @example "insight123"
   */
  boardId: ApiBoardIdSchema,
  /** The last time we saw data for this insight. If set to 0, use the timestamp that is located in summary.json.scan_timestamp. */
  date: z.union([z.string(), z.number()]).optional(),
  /** The score of the vendor, only set if the insight is a vendor insight */
  score: z.number().optional(),
  /** A ctegory assigned to the insight to be used in the "Category" filter dropdowns. */
  vendorId: z.string().optional(),
  /** A category assigned to the insight to be used in the "Category" filter dropdowns. */
  category: z.string().optional(),
  /** Used by the feedback rules. If this variable exists in the insight, the value is passed as part of the creation request for the feedback loop rule. */
  eventType: z.string().optional(),
  /** Flag used to determine if the "export" button should be shown for an insight */
  showExport: z.boolean().optional(),
  /** The assigned status of the report. "4" = "Critical", "3" = "Needs Review", "2" = Not used for now, "1" = "Pass" */
  status: z.string().optional(), // ApiStatusSchema.optional(), //TODO: CHANGE THIS TO ApiStatusSchema.optional() WHEN IT IS FIXED
  /** Friendly description of the insight and what it does. */
  description: z.string().optional(),
  /** Flag used to determine if the "refresh data" button should be shown for an insight */
  showRefresh: z.boolean().optional(),
  /** Friendly description of the impact the insight has on customer data. */
  businessImpact: z.string().optional(),
  /** Friendly recommendation on what they should do to fix the problem. */
  recommendation: z.string().optional(),
});

type ApiInsight = z.infer<typeof ApiInsightSchema>;

export default ApiInsight;
