import React from 'react';

import InternalToolsOverlaysCurrentlyDownloadedReports from './CurrentlyDownloadedReports';
import InternalToolsOverlaysFlagsOverlay from './Flags';

export default [
  {
    name: 'Flags',
    id: 'flags',
    description: 'This overlay allows for viewing and editing frontend flags.',
    location: 'Popup',
    icon: 'F',
    component: <InternalToolsOverlaysFlagsOverlay />,
  },
  {
    name: 'Currently Downloaded Reports',
    id: 'currently-downloaded-reports',
    description: "This overlay will show you the names and id's of the currently downloaded (cached) reports.",
    location: 'Navbar',
    icon: 'C',
    component: <InternalToolsOverlaysCurrentlyDownloadedReports />,
  },
];
