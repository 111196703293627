/* eslint-disable @typescript-eslint/naming-convention */
import enUS from './en-US';

// supported locales will be added here

// all locales branch off of the en-US locale
const Locales: Record<string, typeof enUS> = {
  'en-US': enUS,
} as const;

export default Locales;
