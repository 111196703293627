import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';
import React from 'react';
import { withoutProps } from 'utils';

const StyledMuiTextField = styled(
  MuiTextField,
  withoutProps('removeBorder')
)(
  ({ removeBorder }: { removeBorder: boolean }) => `
  ${
    removeBorder
      ? `
  .MuiInputBase-root {
    &:before,
    &:hover:not(#a):before {
      border-width: 0;
    }
    &.Mui-focused .MuiInput-input {
      background: none;
    }
  }
  `
      : ''
  }
  `
);

type MuiTextFieldProps = React.ComponentProps<typeof MuiTextField>;

type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
  variant?: MuiTextFieldProps['variant'] | 'minimal';
};

export default function TextField(props: TextFieldProps) {
  const { variant: _variant, ...rest } = props;
  const variant = _variant === 'minimal' ? 'standard' : _variant;

  return <StyledMuiTextField {...rest} removeBorder={_variant === 'minimal'} variant={variant} />;
}
