import { z } from 'zod';

/**
 * Use the parse function in order to convert a string into a ReportId.
 */
export const PrivacyConsentRuleIdSchema = z.string().brand('PrivacyConsentRuleId');

/**
 * The EnvironmentId type is simply a string, however, using a brand
 * will prevent us from accidentally passing a normal string into
 * a function that expects an EnvironmentId.
 */
type PrivacyConsentRuleId = z.infer<typeof PrivacyConsentRuleIdSchema>;

export default PrivacyConsentRuleId;
