import { NAME_PARAM } from 'config/params';
import { useSetupParams } from 'utils/params';

export function useParams() {
  const [name, setName] = useSetupParams<string>(NAME_PARAM);

  return {
    name,
    setName,
  };
}
