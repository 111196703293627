import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import ReportId from 'api/types/report/id';
import React, { createContext, useState } from 'react';

import PrivacyInsights from './types';

type PrivacyInsightsContextType = {
  insightsCache: Record<ReportId, PrivacyInsights | ApiError | ApiLoading>;
};

export const PrivacyInsightsContext = createContext({} as PrivacyInsightsContextType);

export default function PrivacyInsightsProvider(props: React.PropsWithChildren) {
  //eslint-disable-next-line
  const [insightsCache, setInsightsCache] = useState({} as PrivacyInsightsContextType['insightsCache']);

  const value: PrivacyInsightsContextType = {
    insightsCache,
  };

  return <PrivacyInsightsContext.Provider value={value}>{props.children}</PrivacyInsightsContext.Provider>;
}
