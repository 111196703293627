import { BaseFlag } from './base';

export type FeatureFlag = BaseFlag & {
  // parent: string; // Not sure why this was required, but it should allowed to be independent
  type: 'feature';
};

export type FeatureFlagDefinition = Omit<FeatureFlag, 'reasons' | 'type'>;

export function FeatureFlagFromDefinition(definition: FeatureFlagDefinition): FeatureFlag[] {
  return [
    {
      ...definition,
      id: `feature.${definition.id}`,
      type: 'feature',
      reasons: [],
    },
  ];
}
