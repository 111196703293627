import { z } from 'zod';

import { ApiDashboardTableDataSchema } from './data';
import { ApiDashboardTableHeaderSchema } from './header';

export const ApiDashboardTableSchema = z.object({
  data: z.array(ApiDashboardTableDataSchema),
  header: ApiDashboardTableHeaderSchema,
});

type ApiDashboardTable = z.infer<typeof ApiDashboardTableSchema>;

export default ApiDashboardTable;
