import { z } from 'zod';

// +----------+
// | Response |
// +----------+

//  details section

const DashboardInsightDetailsTableDataHeaderModelSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export const DashboardInsightDetailsTableDataModelSchema = z.object({
  meta: z.array(DashboardInsightDetailsTableDataHeaderModelSchema),
  data: z.array(z.array(z.any())),
});

export type DashboardInsightDetailsTableDataModel = z.infer<typeof DashboardInsightDetailsTableDataModelSchema>;
