import { z } from 'zod';

export const ApiScoreSchema = z.object({
  name: z.string(),
  score: z.number(),
});

type ApiScore = z.infer<typeof ApiScoreSchema>;

export default ApiScore;
