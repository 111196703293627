import { Color, PaletteColor } from '@mui/material';
import { common, green, grey, indigo, orange, pink, red } from '@mui/material/colors';
import { createTheme, PaletteColorOptions, ThemeOptions } from '@mui/material/styles';
import React, { ForwardedRef } from 'react';
import { Link } from 'react-router-dom';

import color from './color.json';

type ColorPartial = Partial<Color>;

declare module '@mui/material' {
  interface PaletteColor extends ColorPartial {
    light: string;
  }
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    verylight?: string;
  }
  interface PaletteOptions {
    responsive: {
      grey: PaletteColorOptions;
    };
  }

  interface Palette {
    responsive: {
      grey: PaletteColor;
    };
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export const sentinelGreen: Required<PaletteColor> = {
  900: '#005e18',
  800: '#197d2b',
  700: '#258e35',
  600: '#31a041',
  500: '#3aaf4a',
  400: '#5abb65',
  300: '#78c780',
  200: '#a0d6a4',
  100: '#c5e6c8',
  50: '#e7f5e8',
  // variables
  main: '#3aaf4a', // 500
  light: '#5abb65', // 400
  dark: '#258e35', // 700
  contrastText: common.white,
  // other
  A100: '#c5e6c8', // 100
  A200: '#a0d6a4', // 200
  A400: '#78c780', // 300
  A700: '#197d2b', // 800
};
/* eslint-enable @typescript-eslint/naming-convention */

const CustomLinkComponent = React.forwardRef(function CustomLinkComponent(
  props: { href: string } & React.PropsWithChildren,
  ref: React.ForwardedRef<any>
) {
  const { href, ...rest } = props;
  if (href) return <Link ref={ref as ForwardedRef<HTMLAnchorElement>} to={href} {...rest} />;
  else return <div ref={ref as any} {...rest} />;
});

const typography = {
  fontFamily: 'Poppins',
};

const themeBase: ThemeOptions = {
  color,
  typography,
  palette: {
    action: {
      active: indigo[300],
    },
    primary: {
      ...indigo,
      main: indigo[900],
      light: indigo[400],
    },
    secondary: pink,
    error: red,
    warning: orange,
    success: green,
    text: {
      primary: indigo[900],
      secondary: indigo[400],
      disabled: indigo[100],
    },
    background: {
      verylight: indigo[50],
    },
    responsive: {
      grey: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        50: grey[50],
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        LinkComponent: CustomLinkComponent,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        // eslint-disable-next-line
        // @ts-ignore
        component: CustomLinkComponent,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: indigo[200],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: indigo[100],
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rounded',
      },
      styleOverrides: {
        root: {
          backgroundColor: indigo[50],
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '& a': {
            color: indigo[200],
            textDecoration: 'none',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          transitionDuration: '200ms',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) ',
          transitionDelay: '0ms',
          transitionProperty: 'background-color, color',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            backgroundColor: indigo[50],
            color: indigo[600],
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&.Mui-selected': {
            backgroundColor: indigo[900],
            color: common.white,
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&.Mui-selected:hover': {
            backgroundColor: indigo[700],
            color: common.white,
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeBase);

export const themeDark = createTheme({
  ...themeBase,
  palette: {
    ...themeBase.palette,
    mode: 'dark',
    primary: {
      ...indigo,
      main: indigo[200],
      contrastText: common.white,
    },
    text: {
      primary: indigo[50],
      disabled: grey[700],
    },
    background: {
      verylight: grey[900],
    },
    responsive: {
      grey: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        50: '#252525',
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        LinkComponent: CustomLinkComponent,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        // eslint-disable-next-line
        // @ts-ignore
        component: CustomLinkComponent,
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rounded',
      },
      styleOverrides: {
        root: {
          backgroundColor: grey[800],
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '& a': {
            color: grey[500],
            textDecoration: 'none',
          },
        },
      },
    },
  },
});

if (process.env.NODE_ENV !== 'production') {
  //eslint-disable-next-line
  console.log('Current Theme',theme);
  //eslint-disable-next-line
  //console.warn("Note: The current theme is not properly setup. Dark mode, contrast ratios, and many other properties are yet to be configured.");
  //eslint-disable-next-line
  //console.warn("Note: fix typescript for component override in theme MuiButtonBase.");
}
