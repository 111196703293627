import type { Stack, Theme } from '@mui/material';

export type ScrollContainerProps = React.ComponentProps<typeof Stack> & {
  theme?: Theme;
  children?: React.ReactNode;
  scrollType?: 'latched' | 'fixed';
  fixedScrollAmount?: number;
  variant?: ScrollContainerVariant;
  rootStyle?: React.CSSProperties;
};

export const VariantStyles = {
  outlined: (theme?: Theme) => `
    border: 1px solid ${theme?.palette?.divider};
  `,
  contained: (theme?: Theme) => `
    background: ${theme?.palette?.primary[50]};
  `,
};

export type ScrollContainerVariant = keyof typeof VariantStyles;
