import BoardId, { VendorBoardId } from 'api/types/report/boardId';
import { useCallback } from 'react';

import { BOARD_ID_PARAM, VENDOR_ID_PARAM } from './config';
import useAppGeneralParams from './general';

type VendorId = VendorBoardId | 'Any';

/** The hook used to manipulate URL parameters on the insights page */
export default function useAppTechnologyParams() {
  const { params, setParam } = useAppGeneralParams();

  /** The current boardId parameter */
  const boardId = params.get(BOARD_ID_PARAM) as BoardId | null;
  /** Function to set the current boardId parameter */
  const setBoardId = useCallback((id: BoardId | null) => setParam(BOARD_ID_PARAM, id), [setParam]);

  /** The current vendor to show results for */
  const vendorId = (params.get(VENDOR_ID_PARAM) as VendorId | null) ?? 'Any';
  /** Function to set the current vendor to show results for */
  const setVendorId = useCallback((vendorId: VendorId) => setParam(VENDOR_ID_PARAM, vendorId), [setParam]);

  return {
    boardId,
    setBoardId,

    vendorId,
    setVendorId,
  };
}
