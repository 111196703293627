import { BaseFlag } from './base';

export type PageFlag = BaseFlag & {
  type: 'page';
};

export type PageFlagDefinition = Omit<PageFlag, 'reasons' | 'type'>;

export function PageFlagFromDefinition(definition: PageFlagDefinition): PageFlag[] {
  // generate two flags for each page flag
  // one for enabled, one for visible
  return [
    {
      ...definition,
      reasons: [],
      type: 'page',
      id: `page.${definition.id}`,
    },
    {
      ...definition,
      reasons: [],
      type: 'page',
      id: `page.${definition.id}.visible`,
      parent: { id: `page.${definition.id}` },
    },
  ];
}
