import { ApiUser, ApiUserPending, ApiUserPendingSchema, ApiUserSchema } from 'api/apiTypes';
import useLogs from 'api/logs';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { error, isErrored, isLoading, loading, once } from 'api/utils';
import React, { createContext, useContext, useEffect, useState } from 'react';

import type UseUsersContextType from './types/context';

const UseUsersContext = createContext({} as UseUsersContextType);

export function UseUsersProvider(props: React.PropsWithChildren) {
  const [hasConsumer, setHasConsumer] = useState(false);
  const [users, setUsers] = useState<UseUsersContextType['users']>(loading());

  once(
    () => {
      (async () => {
        try {
          const payload = (await import('./sampledata')).default;
          // emulate network latency
          await new Promise((resolve) => setTimeout(resolve, 1300));
          // eslint-disable-next-line sentinelinsights/no-mutations
          const data = ApiUserSchema.or(ApiUserPendingSchema)
            .array()
            .parse(payload)
            .sort((a, b) =>
              a.role === 'admin' && b.role === 'viewer' ? -1 : a.role === 'viewer' && b.role === 'admin' ? 1 : 0
            );

          setUsers(data);
        } catch (e: any) {
          setUsers(error(["Couldn't load users", e?.message]));
        }
      })();
    },
    [setUsers],
    hasConsumer
  );

  const value: UseUsersContextType = {
    users,
    hasConsumer,
    setHasConsumer,
  };

  return <UseUsersContext.Provider value={value} {...props} />;
}

export default function useUsers(): (ApiUser | ApiUserPending)[] | ApiLoading | ApiError {
  const { log, logObject } = useLogs();

  const context = useContext(UseUsersContext);

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useReports');
    }
    if (!isLoading(context.users) && !isErrored(context.users)) {
      log('[API] get: reports => CACHE HIT');
      logObject('reports', context.users);
    }
  }, [context, log, logObject]);

  return context.users;
}
