import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import React from 'react';

const typeColors: Record<
  'string' | 'number' | 'boolean' | 'object' | 'undefined' | 'function' | 'bigint' | 'symbol' | 'array',
  React.ComponentProps<typeof Typography>['color']
> = {
  array: 'coral',
  string: 'lightgreen',
  number: 'orchid',
  boolean: 'green',
  object: 'cyan',
  undefined: 'brown',
  function: 'orange',
  bigint: 'orange',
  symbol: 'orange',
};

type Errors = {
  path: string;
  message: string;
}[];

export default function ObjectViewer({ object, errors }: { object: any; errors?: Errors }) {
  const renderObject = (object: any, depth: number, parent: string) => {
    if (typeof object === 'object') {
      return Object.entries(object)
        .sort(([x], [y]) => x.localeCompare(y))
        .map(([key, value]) => {
          const _normalParent = parent.startsWith('@.')
            ? parent.slice(2)
            : parent.startsWith('@')
            ? parent.slice(1)
            : parent;
          const normalParent = _normalParent.length ? `${_normalParent}.` : '';
          const isPartOfError = errors?.some((x) => x.path.startsWith(`${normalParent}${key}`));
          const isErrored = errors?.find((x) => x.path === `${normalParent}${key}`);
          return (
            <TreeItem
              key={`${parent}.${key}`}
              itemId={`${parent}.${key}`}
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography color={isPartOfError ? 'white' : 'orange'} bgcolor={isPartOfError ? 'red' : ''}>
                    {key}:
                  </Typography>
                  <Typography color={typeColors[Array.isArray(value) ? 'array' : typeof value]} variant="caption">
                    {Array.isArray(value) ? 'array' : typeof value}
                  </Typography>
                  {isErrored && (
                    <Typography color="red" variant="caption">
                      - {isErrored.message}
                    </Typography>
                  )}
                </Stack>
              }
            >
              {renderObject(value, depth + 1, `${parent}.${key}`) ?? JSON.stringify(value)}
            </TreeItem>
          );
        });
    }
    if (typeof object === 'string') {
      return (
        <TreeItem
          itemId={`${parent}.${object}`}
          label={
            <Typography variant="body2" color="lightgreen">
              &quot;{object}&quot;
            </Typography>
          }
        />
      );
    }
    return null;
  };

  return (
    <TreeView
      slotProps={{
        collapseIcon: <ExpandMore />,
        expandIcon: <ChevronRight />,
      }}
      sx={{ bgcolor: 'black', color: 'white' }}
    >
      {renderObject(object, 0, '@')}
    </TreeView>
  );
}
