import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { IconButton, Stack, Theme, Toolbar as MuiToolbar, Typography } from '@mui/material';
import { Logo } from 'components';
import React from 'react';

const Toolbar = styled(MuiToolbar)(
  ({ theme }: { theme?: Theme }) => `
    height: 72px;
    display: grid;
    min-height: 72px;
    max-height: 72px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 1px solid ${theme?.palette.divider};

    & > * {
      min-height: 0;
    }
  `
);

type ModalHeaderProps = {
  title?: React.ReactNode;
  logoVisible?: boolean;
  closeButtonVisible?: boolean;
  closeButtonOnClick?: () => void;
} & Omit<React.ComponentProps<typeof Toolbar>, 'title'>;

/**
 * The top header that appears on the fullscreen modal, shows a logo, title, and a close button.
 */
export default function ModalHeader(props: ModalHeaderProps) {
  const {
    title,
    closeButtonOnClick,
    logoVisible: _logoVisible,
    closeButtonVisible: _closeButtonVisible,
    ...rest
  } = props;

  const logoVisible = _logoVisible ?? true;
  const closeButtonVisible = _closeButtonVisible ?? true;

  return (
    <Toolbar {...rest}>
      {logoVisible ? <Logo fullHeight marginY={12} /> : <br />}

      {!title || typeof title === 'string' ? (
        <Typography variant="caption" align="center">
          {title}
        </Typography>
      ) : (
        title
      )}

      {closeButtonVisible ? (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton color="primary" onClick={closeButtonOnClick}>
            <Close />
          </IconButton>
        </Stack>
      ) : (
        <br />
      )}
    </Toolbar>
  );
}
