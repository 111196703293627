import { z } from 'zod';

import { LabelIdSchema } from '../id';

export const ApiInsightLabelSchema = z.object({
  _id: LabelIdSchema,
  name: z.string(),
  color: z.string(),
  insights: z.array(z.string()),
});

export type ApiInsightLabel = z.infer<typeof ApiInsightLabelSchema>;

export const ApiInsightLabelCreateSchema = z.object({
  name: z.string(),
  color: z.string(),
  insight: z.string(),
});

export type ApiInsightLabelCreate = z.infer<typeof ApiInsightLabelCreateSchema>;

export const ApiInsightLabelUpdateSchema = z.object({
  name: z.string().optional(),
  color: z.string().optional(),
  insight: z.string().optional(),
  insightAdd: z.boolean().optional(),
});

export type ApiInsightLabelUpdate = z.infer<typeof ApiInsightLabelUpdateSchema>;
