import { z } from 'zod';

import { SupportedOperands } from '../const';

export type SupportedOperandsType = keyof typeof SupportedOperands;

export const NotificationWhereSchema = z.object({
  logic: z.array(z.literal('0')).max(1),
  operands: z.array(
    z.object({
      rule: z.enum(Object.keys(SupportedOperands) as [SupportedOperandsType, ...SupportedOperandsType[]]),
      params: z.array(z.string()).length(2),
    })
  ),
});

type NotificationWhere = z.infer<typeof NotificationWhereSchema>;

export default NotificationWhere;
