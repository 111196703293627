import useExperiments from 'api/experiments';
import React from 'react';

import { NotificationsProvider as BaseNotificationsProvider } from './useNotifications/index';

export default function NotificationsProvider(props: React.PropsWithChildren) {
  const { isExperimentEnabled } = useExperiments();
  if (!isExperimentEnabled('pages.notifications.enabled')) return <>{props.children}</>;
  return <BaseNotificationsProvider>{props.children}</BaseNotificationsProvider>;
}
