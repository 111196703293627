import { z } from 'zod';

export const ApiDashboardTableHeaderVendorSchema = z.object({
  key: z.literal('vendor'),
  name: z.literal('Vendor'),
});

export type ApiDashboardTableDataHeaderVendor = z.infer<typeof ApiDashboardTableHeaderVendorSchema>;

export const ApiDashboardTableHeaderResultSchema = z.object({
  key: z.literal('result'),
  name: z.literal('Result'),
});

export type ApiDashboardTableDataHeaderResult = z.infer<typeof ApiDashboardTableHeaderResultSchema>;

export const ApiDashboardTableHeaderSchema = z.array(
  ApiDashboardTableHeaderResultSchema.or(ApiDashboardTableHeaderVendorSchema)
);

type ApiDashboardTableHeader = z.infer<typeof ApiDashboardTableHeaderSchema>;

export default ApiDashboardTableHeader;
