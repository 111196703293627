import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getDataExportRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('pages.dataExport.enabled')) return <></>;

  const DataExportPage = lazily('data-export');
  const DataExportDownloadPage = lazily('data-export/download');

  return (
    <>
      <Route path={AppRoutes.dataExport.index()} element={<DataExportPage />} />
      <Route path={AppRoutes.dataExport.download(':jobId')} element={<DataExportDownloadPage />} />
    </>
  );
};

export default getDataExportRoutes;
