import {
  ApiSourceOfTruth,
  ApiSourceOfTruthSchema,
  ApiSourceOfTruthTags,
  ApiSourceOfTruthTagsSchema,
  EnvironmentId,
  ReportId,
  TagId,
} from 'api/apiTypes';
import { ApiNotificationGroupPaths, ApiNotificationGroupPathsSchema } from 'api/apiTypes/notification/groupPaths';
import { ApiSourceOfTruthPageFilter } from 'api/apiTypes/sourceOfTruth/pageFilters';
import { ApiSourceOfTruthPublishResponseSchema } from 'api/apiTypes/sourceOfTruth/publish';
import routes from 'api/routes';
import useReports from 'api/useReports';
import { isLoading, loading, useRegisterMiddlewareHook } from 'api/utils';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

export function useNextGlobalSourceOfTruth(reportId: ReportId) {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('useNextGlobalSourceOfTruth', reportId);

  if (isLoading(report)) return loading();

  return async (tagId: TagId, _page: number, _limit: number, date?: Moment): Promise<ApiSourceOfTruth> => {
    const url = routes.sourceOfTruth.getGlobalSourceOfTruth(
      report?.summary.json.environmentId ?? ('' as EnvironmentId),
      tagId,
      date
    );
    if (!report || !tagId || !date) throw new Error('Missing report or tagId or date');
    const response = await get(url, {
      withCredentials: true,
    });
    const data = ApiSourceOfTruthSchema.parse(response.data);
    const newSotData = {
      ...data,
      rules: data.rules.map((x) => ({
        ...x,
        groupPathFriendly: `${x.groupPathTranslations.friendlyName} (${x.groupPath})`,
      })),
    };
    return newSotData;
  };
}

export function useNextPageSourceOfTruth(reportId: ReportId) {
  const { post } = useAxios();
  const report = useRegisterMiddlewareHook('useNextPageSourceOfTruth', reportId);

  if (isLoading(report)) return loading();

  return async (
    tagId: TagId,
    filters: ApiSourceOfTruthPageFilter[],
    page: number,
    limit: number,
    date?: Moment
  ): Promise<ApiSourceOfTruth> => {
    const url = routes.sourceOfTruth.getPageSourceOfTruth(
      report?.summary.json.environmentId ?? ('' as EnvironmentId),
      tagId,
      page,
      limit,
      undefined,
      date
    );
    if (!report || !tagId || !date || !filters || !filters.length) throw new Error('Missing report or tagId or date');
    return ApiSourceOfTruthSchema.parse(
      (
        await post(
          url,
          {
            filters,
          },
          {
            withCredentials: true,
          }
        )
      ).data
    );
  };
}

export function useNextTagIds(
  reportId: ReportId,
  date?: Moment | string,
  manual?: boolean
): SWRResponse<ApiSourceOfTruthTags[]> {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('useNextTagIds', reportId);

  const hook = useSWR(
    routes.sourceOfTruth.getTagIds(
      report?.summary.json.environmentId ?? ('' as EnvironmentId),
      manual ? undefined : date ?? 'any'
    ),
    async (url) => {
      if (!report) return [];
      // eslint-disable-next-line sentinelinsights/no-mutations
      return ApiSourceOfTruthTagsSchema.array()
        .parse(
          (
            await get(url, {
              withCredentials: true,
            })
          ).data
        )
        .sort((a, b) => a.tagName.localeCompare(b.tagName));
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (isLoading(report))
    return {
      isLoading: true,
      data: undefined,
      error: undefined,
      isValidating: false,
      mutate: async () => undefined,
    };

  return hook;
}

export function useNextGroupPaths(reportId: ReportId) {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('useNextGroupPaths', reportId);

  if (isLoading(report)) return loading();

  return useCallback(
    async (tagId: TagId, date?: Moment): Promise<ApiNotificationGroupPaths[]> => {
      const url = routes.notifications.groupPaths.get(
        report?.summary.json.environmentId ?? ('' as EnvironmentId),
        tagId,
        date
      );

      if (!report || !date) return [];
      return ApiNotificationGroupPathsSchema.array().parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );
    },
    [get, report]
  );
}

export function useNextPageUrls(reportId: ReportId) {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('useNextGroupPaths', reportId);

  return async (tagId: TagId, date: Moment, search: string) => {
    const url = routes.sourceOfTruth.getPageUrls(
      report?.summary.json.environmentId ?? ('' as EnvironmentId),
      tagId,
      date,
      search
    );
    if (!report || !tagId || !date) return [];
    return z
      .string()
      .array()
      .parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );
  };
}

export function useSOTPublishProgress(reportId: ReportId, pastDay?: boolean) {
  const { get } = useAxios();
  const reports = useReports();

  const currentReport = useMemo(() => reports.find((r) => r.id === reportId), [reports, reportId]);

  return useSWR(
    currentReport?.envGuid
      ? routes.sourceOfTruth.publish.getProgress(currentReport?.envGuid as EnvironmentId, pastDay)
      : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      }).catch(() => null);

      return ApiSourceOfTruthPublishResponseSchema.parse(response?.data);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    }
  );
}
