import { PageFlagDefinition } from '../types/page';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "page." DURING POST PROCESSING
const defaultPageFlags: PageFlagDefinition[] = [
  {
    id: 'insights',
    name: 'Insights',
    enabled: false,
    description: 'The dashboard page',
    parent: {
      id: 'role.internalUser',
    },
  },
];

export default defaultPageFlags;
