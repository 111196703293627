import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';

const getPrivacyRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  const Loader = lazily('loader');
  if (!isExperimentEnabled('pages.privacy.enabled'))
    return (
      <>
        <Route path={AppRoutes.PRIVACY} element={<Loader />} />
        <Route path={AppRoutes.PRIVACY_INSIGHTS} element={<Loader />} />
      </>
    );

  const PrivacyPage = lazily('privacy');
  const PrivacyInsightsPage = lazily('privacy/insights');
  return (
    <>
      <Route path={AppRoutes.PRIVACY} element={<PrivacyPage />} />
      <Route path={AppRoutes.PRIVACY_INSIGHTS} element={<PrivacyInsightsPage />} />
    </>
  );
};

export default getPrivacyRoutes;
