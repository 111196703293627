import type { PopoverOrigin } from '@mui/material';
import { useRef, useState } from 'react';

export default function useMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuAnchor = useRef<HTMLButtonElement>(null);

  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  return {
    menuOpen,
    openMenu,
    closeMenu,
    menuAnchor,
    anchorOrigin,
    transformOrigin,
  };
}
