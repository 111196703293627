import { z } from 'zod';

import { UserIdSchema } from '../id/user';
import { PartialReportSchema } from '../report/partial';

export const ApiUserPendingSchema = z.object({
  id: UserIdSchema,
  organization: z.string(),
  email: z.string().email(),
  environments: z.array(z.string()).optional(),
  role: z.union([z.literal('admin'), z.literal('viewer')]).optional(),
});

export type ApiUserPending = z.infer<typeof ApiUserPendingSchema>;

/** The user object returned from the /auth/profile . */
export const ApiUserSchema = ApiUserPendingSchema.merge(
  z.object({
    last_name: z.string(),
    first_name: z.string(),
    reports: z.array(PartialReportSchema),
    defaultEnvironment: z.union([z.string().uuid(), z.literal(false)]),
    is_power_user: z.boolean(),
  })
);

/** The user object returned from the API. */
type ApiUser = z.infer<typeof ApiUserSchema>;

export default ApiUser;
