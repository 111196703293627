import styled from '@emotion/styled';
import { Add, RemoveCircleOutline } from '@mui/icons-material';
import { alpha, Autocomplete, Button, IconButton, MenuItem, Stack, TextField, Theme, Typography } from '@mui/material';
import { SupportedOperandsType } from 'api/apiTypes';
import PostfixLogicType from 'api/apiTypes/postfixLogic';
import CaptionedSelect from 'components/CaptionedSelect';
import Label from 'label';
import React, { useEffect, useMemo, useState } from 'react';

import { AllOperands, StringOperands } from './operands';

type Dimension = {
  label: string;
  value: string;
};

type PostfixProps = {
  value: PostfixLogicType;
  dimensions: Dimension[];
  onError: (errored: boolean) => void;
  allowedOperators?: 'all' | 'string';
  onChange: (rule: PostfixLogicType) => void;
};

const ColoredStack = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
    gap: ${theme?.spacing(2)};
    padding: ${theme?.spacing(2)};
    border-radius: ${theme?.shape.borderRadius}px;
    border: 1px solid ${theme?.palette.divider};
    background-color: ${alpha(theme?.palette.background.verylight ?? '', 0.35)};
  `
);

const ColoredTextField = styled(TextField)(
  ({ theme }: { theme?: Theme }) => `
    background-color: ${theme?.palette.background.paper};
  `
);

export default function Postfix(props: PostfixProps) {
  const { value, allowedOperators, dimensions, onChange, onError } = props;
  const OperatorList = useMemo(() => {
    switch (allowedOperators) {
      case 'string':
        return StringOperands;
      case 'all':
      default:
        return AllOperands;
    }
  }, [allowedOperators]);

  const [boolean, setBoolean] = useState<'and' | 'or'>((value.logic?.[2] ?? '&') === '&' ? 'and' : 'or');

  const [operands, setOperands] = useState<PostfixLogicType['operands']>(
    value.operands ?? [
      {
        rule: Object.keys(OperatorList)[0] as any,
        params: [dimensions[0].value, ''],
      },
    ]
  );

  useEffect(() => {
    for (const operand of operands) {
      if (isArrayOperator(operand.rule)) {
        if (operand.params.filter((x) => x?.trim().length).length < 2) {
          onError(true);
          return;
        }
      } else if ((operand.params[1]?.trim().length ?? 0) === 0 && !isStandaloneOperator(operand.rule)) {
        onError(true);
        return;
      }
    }
    // generate this:
    // 1 2 | 3 | 4 |
    const rule: PostfixLogicType = {
      logic: new Array(operands.length)
        .fill(0)
        .flatMap((_, i) => (i === 0 ? [`${i}`] : [`${i}`, boolean === 'and' ? '&' : '|'])),
      operands,
    };
    onError(false);
    onChange(rule);
  }, [boolean, onChange, onError, operands]);

  return (
    <ColoredStack>
      {operands.map((operand, index) => (
        <Stack key={index} gap={2} direction="row" alignItems="center" width="100%">
          {index === 1 && (
            <CaptionedSelect
              caption=""
              variant="minimal"
              value={boolean}
              onChange={(e) => setBoolean(e.target.value as any)}
            >
              <MenuItem value="and">AND</MenuItem>
              <MenuItem value="or">OR</MenuItem>
            </CaptionedSelect>
          )}
          {index > 1 && (
            <Typography pl={2} pr={3} textTransform="uppercase">
              {boolean}
            </Typography>
          )}
          <ColoredTextField
            select
            fullWidth
            label="Dimension"
            value={operand.params[0] ?? dimensions[0].value}
            onChange={(e) =>
              setOperands((prev) => {
                const newRule = structuredClone(prev);
                newRule[index].params[0] = e.target.value;
                return newRule;
              })
            }
          >
            {dimensions.map((dimension) => (
              <MenuItem key={dimension.value} value={dimension.value}>
                {dimension.label}
              </MenuItem>
            ))}
          </ColoredTextField>
          <CaptionedSelect
            caption=""
            variant="minimal"
            value={operand.rule}
            onChange={(e) =>
              setOperands((prev) => {
                const newRule = structuredClone(prev);
                newRule[index].rule = e.target.value as any;
                if (isArrayOperator(e.target.value as any)) {
                  newRule[index].params = newRule[index].params.filter((x) => x.length);
                }
                return newRule;
              })
            }
          >
            {Object.keys(OperatorList).map((key) => (
              <MenuItem key={key} value={key}>
                {Label.notifications.supportedOperands[key as keyof typeof OperatorList] ?? key}
              </MenuItem>
            ))}
          </CaptionedSelect>
          {isArrayOperator(operand.rule) ? (
            <Autocomplete
              freeSolo
              multiple
              fullWidth
              options={[] as string[]}
              value={operand.params.slice(1)}
              onChange={(_, newValue) =>
                setOperands((prev) => {
                  const newRule = structuredClone(prev);
                  newRule[index].params = [newRule[index].params[0], ...newValue];
                  return newRule;
                })
              }
              renderInput={(params) => (
                <ColoredTextField
                  {...params}
                  label="Value"
                  error={operand.params.length < 2}
                  placeholder="Press enter to confirm"
                />
              )}
            />
          ) : (
            <ColoredTextField
              fullWidth
              label="Value"
              value={operand.params[1]}
              error={(operand.params[1]?.trim().length ?? 0) === 0 && !isStandaloneOperator(operand.rule)}
              disabled={isStandaloneOperator(operand.rule)}
              onChange={(e) =>
                setOperands((prev) => {
                  const newRule = structuredClone(prev);
                  newRule[index].params[1] = e.target.value;
                  return newRule;
                })
              }
            />
          )}
          <IconButton
            color="error"
            disabled={index === 0}
            sx={{ opacity: index === 0 ? '0' : '1' }}
            onClick={() => setOperands((prev) => prev.filter((_, i) => i !== index))}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Stack>
      ))}
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startIcon={<Add />}
          onClick={() =>
            setOperands((prev) => [
              ...prev,
              {
                rule: Object.keys(OperatorList)[0] as any,
                params: [dimensions[0].value, ''],
              },
            ])
          }
        >
          Add Dimension
        </Button>
      </Stack>
    </ColoredStack>
  );
}

const arrayOperators: Partial<Record<SupportedOperandsType, boolean>> = {
  isOneOf: true,
  isNotOneOf: true,
  isOneOfCI: true,
  isNotOneOfCI: true,
  isTypeOf: true,
  isNotTypeOf: true,
  isTypeOfCI: true,
  isNotTypeOfCI: true,
} as const;

function isArrayOperator(operator: string) {
  return arrayOperators[operator as SupportedOperandsType] ?? false;
}

const standaloneOperators: Partial<Record<SupportedOperandsType, boolean>> = {
  isTruthy: true,
  isFalsy: true,
} as const;

function isStandaloneOperator(operator: string) {
  return standaloneOperators[operator as SupportedOperandsType] ?? false;
}
