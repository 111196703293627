import { ApiPrivacyInsightsSchema } from 'api/privacy/usePrivacyInsights/types/api';
import { z } from 'zod';

import { ApiPrivacyConsentConfigurationSchema } from './consentConfiguration';

/* SUCCESS OBJECTS */
export const ApiPrivacySchema = z
  .object({
    /** This property is returned as a string representation of a float, i.e. "98.80" */
    percentBannerDeployed: z.number(),
    cookiesCount: z.number().int().min(0),
    localStorageCount: z.number().int().min(0),
    sessionStorageCount: z.number().int().min(0),
    consentConfiguration: ApiPrivacyConsentConfigurationSchema,
    report: ApiPrivacyInsightsSchema,
  })
  .deepPartial();

export type ApiPrivacy = z.infer<typeof ApiPrivacySchema>;

export const ApiPrivacyResponseSuccessSchema = z
  .object({
    status: z.literal(true),
    data: ApiPrivacySchema,
  })
  .strict(); // response should not contain any other properties

type ApiPrivacyResponse = z.infer<typeof ApiPrivacyResponseSuccessSchema>;

/* ERRORED OBJECTS */
export const ApiPrivacyErrorSchema = z.object({
  error: z.string(),
  message: z.array(z.string()),
  statusCode: z.number().int().min(9),
});

export type ApiPrivacyError = z.infer<typeof ApiPrivacyErrorSchema>;

export const ApiPrivacyResponseErrorSchema = z.object({
  path: z.string(),
  method: z.string(),
  message: z.string(),
  timestamp: z.date(),
  code: z.number().int().min(0),
  customResponse: ApiPrivacyErrorSchema,
});

export type ApiPrivacyResponseError = z.infer<typeof ApiPrivacyResponseErrorSchema>;

export default ApiPrivacyResponse;
