import { z } from 'zod';

import ReportId from './id';

/** The partial reports object returned from the /profile endpoint */
export const PartialReportSchema = z.object({
  name: z.string(),
  id: z.string().uuid(),
  envGuid: z.string().uuid().or(z.literal(false)),
  dbGuid: z.custom<string | undefined>((value) => {
    try {
      return z.string().parse(value);
    } catch {
      return undefined;
    }
  }),
  api_dashboard_enabled: z.boolean().default(false),
});

/** The partial reports object returned from the /profile endpoint */
interface PartialReport extends z.infer<typeof PartialReportSchema> {
  id: ReportId;
}

export default PartialReport;
