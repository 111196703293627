import { EnvironmentIdSchema } from 'api/apiTypes';
import { z } from 'zod';

import { ApiVendorSchema } from '../vendor';

/** The summary.json object returned from the API. */
export const ApiSummaryJsonSchema = z.object({
  client_name: z.string(),
  base_url: z.string(), //.url(), leave this out for now because of zoom
  data_layer: ApiVendorSchema,
  clientGuid: z.string() /*.uuid()*/, // TODO: CHANGE THIS TO .uuid() WHEN IT IS FIXED IN THE BACKEND!!!!
  tms: z.union([z.array(ApiVendorSchema), z.literal('No TMS Found')]),
  environmentId: EnvironmentIdSchema,
  scan_timestamp: z.number().min(0),
  unique_visitors: z.number().min(0),
  privacyEnabled: z.boolean().optional(),
  vendors: z.array(z.string().or(ApiVendorSchema)),
  clientTimezoneOffset: z.number().min(-12).max(12).optional(),
  featureFlags: z.record(z.boolean()).optional(),
});

/** The summary.json object returned from the API. */
type ApiSummaryJson = z.infer<typeof ApiSummaryJsonSchema>;

export default ApiSummaryJson;
