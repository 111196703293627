import { z } from 'zod';

export * from './tags';
export * from './customRule';
export * from './valuesToAdd';
export * from './valuesToIgnore';
export * from './variablesToIgnore';
export * from './summary';
export * from './qualifier';

/**
 * Source of truth rule schema.
 */
export const ApiSourceOfTruthRuleSchema = z.object({
  groupPath: z.string(),
  groupPathFriendly: z.string().optional(),
  groupPathTranslations: z.object({
    friendlyName: z.string(),
    technicalName: z.string().optional(),
  }),
  types: z.array(z.string()),
  values: z.array(z.any()),
  pageUrl: z.string().optional(),
});

export type ApiSourceOfTruthRule = z.infer<typeof ApiSourceOfTruthRuleSchema>;

/**
 * Source of truth schema. I'm not sure what most of the fields are.
 */
export const ApiSourceOfTruthSchema = z.object({
  rules: z.array(ApiSourceOfTruthRuleSchema),
  totalPages: z.union([z.number().optional(), z.null()]),
  totalRows: z.union([z.number().optional(), z.null()]),
});

type ApiSourceOfTruth = z.infer<typeof ApiSourceOfTruthSchema>;

export default ApiSourceOfTruth;
