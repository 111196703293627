import useLogs from 'api/logs';
import PartialReport from 'api/types/report/partialReport';
import { CurrentUserContext } from 'api/useCurrentUser';
import { useContext, useEffect } from 'react';

export default function useReports(): PartialReport[] {
  const { log, logObject } = useLogs();

  const context = useContext(CurrentUserContext);

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
      log('[API] invoke: useReports');
    }
    if (context.reports && context.reports.length) {
      log('[API] get: reports => CACHE HIT');
      logObject('reports', context.reports);
    }
  }, [context, log, logObject]);

  return context.reports;
}
