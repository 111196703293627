import { theme } from 'theme';

import type * as Types from './types/styled';

/**
 * A @emotion/styled helper function to easily generate a simple { shouldForwardProps: !array.includes }
 * object based on a string or array of strings.
 * @param {string | string[]} props A string or array of strings to exclude from forwarding.
 * @returns {StyledOptions} A partial styled options object.
 */
export function withoutProps(...props: string[]): Types.StyledOptionsShouldForwardProp {
  return {
    shouldForwardProp: (prop: string) => !props.includes(prop),
  };
}

export function borderRadius(number: number) {
  return `border-radius: ${number * theme.shape.borderRadius}px;`;
}
