import { z } from 'zod';

import { ApiTopFindingsJsonSchema } from './json';

export const ApiTopFindingsSchema = z.object({
  boardId: z.literal('topFindings'),
  json: z.object({
    danger: z.array(ApiTopFindingsJsonSchema).optional(),
    warning: z.array(ApiTopFindingsJsonSchema).optional(),
  }),
});

type ApiTopFindings = z.infer<typeof ApiTopFindingsSchema>;

export default ApiTopFindings;
