import { z } from 'zod';

import { ApiBoardIdSchema } from '../boardId';

/**
 * This is the type for the vendor object returned from the API.
 */
export const ApiVendorSchema = z.object({
  name: z.string(),
  type: z.literal('link'),
  boardId: ApiBoardIdSchema,
});

/** This is the type for the vendor object returned from the API. */
type ApiVendor = z.infer<typeof ApiVendorSchema>;

export default ApiVendor;
