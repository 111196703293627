import ApiError from 'api/types/base/apiError';
import Report from 'api/types/report';
import ApiDashboard from 'api/types/report/api/dashboard';
import ApiVendorInsights from 'api/types/report/api/vendor/insights';
import BoardId, { VendorBoardId } from 'api/types/report/boardId';
import ReportId from 'api/types/report/id';
import { StatusPRF, transformPRFtoStatus } from 'api/types/report/status';
import { VendorInsight, VendorMeta, VendorWithInsights } from 'api/types/vendors';
import VendorListAndInsights from 'api/types/vendors/context';
import { refineArray } from 'api/utils';

import { BuildInsightURL } from 'config/app-routes';

// typescript type guard function
function insightsWithResult<T extends { result?: StatusPRF }>(insight: T[]) {
  const newInsights: (Omit<T, 'result'> & { result: StatusPRF })[] = [];
  for (const x of insight) {
    if (x.result) newInsights.push(x as any);
  }
  return newInsights;
}

/**
 * The exported type of the transformer function.
 */
type TransformVendorsFunction = (
  vendorList: ApiDashboard,
  vendorInsights: Record<string, ApiVendorInsights>,
  insight: Report['insight'],
  id: ReportId
) => Exclude<VendorListAndInsights, ApiError | undefined>;

/**
 * This function transforms the data from the API json schema
 * to the custom middleware schema. This way the data is prepared
 * for all the frontend components.
 */
const transformVendors: TransformVendorsFunction = (vendorList, vendorInsights, insight, reportId) => {
  const transformedVendorList = vendorList.table.data.map((x) => ({
    name: x.vendor.name,
    /* 
      type cast from string to boardid, since zod already 
      validated that the boardId is a valid boardId
    */
    boardId: x.vendor.boardId as VendorBoardId,
  }));

  const meta: VendorMeta = {
    categories: new Set<string>(),
    validBoardIds: new Set<BoardId>(),
    validVendorIds: new Set<VendorBoardId>(),
    pass: {
      count: 0,
      href: BuildInsightURL({ reportId, status: 'pass' }),
    },
    review: {
      count: 0,
      href: BuildInsightURL({ reportId, status: 'review' }),
    },
    critical: {
      count: 0,
      href: BuildInsightURL({ reportId, status: 'critical' }),
    },
  };

  const transformedVendorInsights: VendorWithInsights[] = transformedVendorList
    .filter((x) => x.boardId in vendorInsights)
    .map((x) => {
      const vendorInfo = vendorInsights[x.boardId];
      const validVendorBoardIds = new Set<BoardId>();

      const data: VendorInsight[] = insightsWithResult(vendorInfo.table.data).map((y) => {
        if (y.category) meta.categories.add(y.category);

        /* Cast boardid to boardid type because they are the same */
        const boardId = y.insightName.boardId as BoardId;
        const result = transformPRFtoStatus(y.result);

        if (!insight[boardId].vendorId) insight[boardId].vendorId = x.boardId;

        meta.validBoardIds.add(boardId);
        validVendorBoardIds.add(boardId);
        return {
          result,
          boardId,
          category: y.category,
          name: y.insightName.name,
          href: BuildInsightURL({ reportId, boardId: y.insightName.boardId, status: result }),
        };
      });
      const [passInsights, reviewCriticalInsights] = refineArray(data, (x) => x.result === 'pass');
      const [reviewInsights, criticalOtherInsights] = refineArray(reviewCriticalInsights, (x) => x.result === 'review');
      const [criticalInsights] = refineArray(criticalOtherInsights, (x) => x.result === 'critical');

      meta.pass.count += passInsights.length;
      meta.review.count += reviewInsights.length;
      meta.critical.count += criticalInsights.length;
      meta.validVendorIds.add(x.boardId);

      const lowestStatus = criticalInsights.length ? 'critical' : reviewInsights.length ? 'review' : 'pass';

      const url = BuildInsightURL({
        reportId,
        vendorId: x.boardId,
        status: lowestStatus,
      });

      return {
        ...x,
        href: url,
        passInsights,
        reviewInsights,
        criticalInsights,
        allInsights: data,
        score: vendorInfo.score ?? 0,
        validBoardIds: validVendorBoardIds,
      };
    });

  return {
    /** Pre computed meta data for this object. */
    meta,
    vendorList: transformedVendorList,
    vendorInsightsList: transformedVendorInsights,
  };
};

export default transformVendors;
