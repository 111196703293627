import styled from '@emotion/styled';
import { CopyAll } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Menu, Theme } from '@mui/material';
import { DataGridPro, GridCell, GridCellProps } from '@mui/x-data-grid-pro';
import React, { createContext, useContext, useState } from 'react';
import { Stateful } from 'utils';

const BaseDataGridStyled = styled(DataGridPro)(
  ({ theme }: { theme?: Theme }) => `
    background-color: ${theme?.palette.background.paper};  

    .MuiDataGrid-columnHeaders {
      background-color: ${theme?.palette.background.verylight};
    }
  `
);

type MenuParams = {
  anchorEl: null | HTMLElement;
  content: any;
};

const RenderCellContext = createContext<{
  menuParams: MenuParams | null;
  setMenuParams: Stateful<MenuParams | null>;
}>({
  menuParams: null,
  setMenuParams: () => {
    return;
  },
});

export function DataGridStyled(props: React.ComponentProps<typeof BaseDataGridStyled>) {
  const [menuParams, setMenuParams] = useState<MenuParams | null>(null);
  return (
    <>
      <RenderCellContext.Provider value={{ menuParams, setMenuParams }}>
        <BaseDataGridStyled
          {...props}
          components={{
            ...props.components,
            Cell: CustomCell,
          }}
        />
      </RenderCellContext.Provider>
      <Menu anchorEl={menuParams?.anchorEl} open={!!menuParams} onClose={() => setMenuParams(null)}>
        <ListItemButton
          dense
          onClick={() => {
            const currentValue = menuParams?.content;
            setMenuParams(null);
            navigator.clipboard.writeText(currentValue ?? '');
          }}
        >
          <ListItemIcon>
            <CopyAll />
          </ListItemIcon>
          <ListItemText primary="Copy Cell Content" />
        </ListItemButton>
      </Menu>
    </>
  );
}

function CustomCell(props: GridCellProps) {
  const { setMenuParams } = useContext(RenderCellContext);
  return (
    <GridCell
      {...props}
      onContextMenu={(e: React.MouseEvent) => {
        e.preventDefault();
        try {
          if (props?.value === null || props?.value === undefined) return;

          const str = JSON.stringify(props?.value);
          if (!str.length) return;

          setMenuParams({ content: str, anchorEl: e.target as HTMLElement });
        } catch (_) {}
      }}
    />
  );
}
