import type ApiTopFindings from 'api/types/report/api/topFindings';
import ReportId from 'api/types/report/id';
import { StatusRC, transformStatusFromDWS } from 'api/types/report/status';
import TopFindings from 'api/types/report/topFindings';

import { BuildInsightURL } from 'config/app-routes';

const transformTopFindings = (topFindings: ApiTopFindings, reportId: ReportId): TopFindings => {
  const danger = topFindings.json.danger ?? [];
  const warning = topFindings.json.warning ?? [];
  return danger.concat(warning).map((finding) => {
    const status = transformStatusFromDWS(finding.summary.threat);
    return {
      boardId: finding.boardId,
      // Using as to filter down from StatusPRC to StatusRC
      status: status as StatusRC,
      title: finding.summary.title,
      description: finding.summary.description,
      href: BuildInsightURL({ reportId, boardId: finding.boardId, status }),
    };
  });
};

export default transformTopFindings;
