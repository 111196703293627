import { ReportId } from 'api/apiTypes';
import React from 'react';
import { ZodError } from 'zod';

type RawApiContextType = Record<
  ReportId | 'Any',
  {
    reportData: any;
    notifications: any;
    reportErrors: ZodError;
    setDebugReportId: (reportId: ReportId) => void;
    setNotifications: (notifications: any) => void;
  }
>;

const RawApiContext = React.createContext({} as RawApiContextType);

export function RawApiProvider({ children }: any) {
  // no states to prevent useless rerenders, instead each component will have to refresh itself
  const store = React.useRef({} as RawApiContextType);

  return <RawApiContext.Provider value={store.current}>{children}</RawApiContext.Provider>;
}

export default function useRawApiResponse() {
  return React.useContext(RawApiContext);
}
