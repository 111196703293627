import { PostfixFunctionSchema } from 'api/apiTypes/postfixLogic';
import { z } from 'zod';

export const ApiPrivacyConsentConfigurationLocateSchema = z.object({
  content: z.string(),
  generatedFunction: PostfixFunctionSchema.optional(),
  option: z.enum(['auto', 'cookie', 'localStorage', 'sessionStorage', 'jsVariable', 'customFunction']),
});

/**
 * The locate object is used to tell us where we should look for the consent string
 * @property {} content - The content of the locate object
 * @property {} generatedFunction - The backend generated function for this locate object
 * @property {} option - The option for this locate object
 */
type ApiPrivacyConsentConfigurationLocate = z.infer<typeof ApiPrivacyConsentConfigurationLocateSchema>;

export default ApiPrivacyConsentConfigurationLocate;
