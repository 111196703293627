import { useCallback } from 'react';

import useAppGeneralParams from './general';
import useAppInsightsParams from './insights';
import usePrivacyInsightsParams from './privacyInsights';
import useAppReportParams from './report';
import useAppTechnologyParams from './technology';

/**
 * TO BE USED INTERNALLY ONLY
 * This hook is used to update the URL parameters internally.
 * It will NOT trigger a re-render. Any persistent states should be
 * updated using the react-router Link component or by directly
 * using react-router hooks.
 */
export default function useAppParams() {
  const reportParams = useAppReportParams();
  const insightsParams = useAppInsightsParams();
  const technologyParams = useAppTechnologyParams();
  const privacyInsightsParams = usePrivacyInsightsParams();

  return {
    reportParams,
    insightsParams,
    technologyParams,
    privacyInsightsParams,
  };
}

/** The hook used to manipulate URL parameters on the insights page */
export function useSetupParams<T extends string = string>(name: string): [T | null, (value: T | null) => void] {
  const { params, setParam } = useAppGeneralParams();

  const get = params.get(name) as T | null;
  const set = useCallback((value: T | null) => setParam(name, value), [name, setParam]);

  return [get, set];
}
