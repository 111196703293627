import { Avatar as MuiAvatar } from '@mui/material';
import React, { useMemo } from 'react';

type MuiAvatarProps = React.ComponentProps<typeof MuiAvatar>;

type AvatarProps = MuiAvatarProps & {
  string?: string;
};

export default function Avatar(props: AvatarProps) {
  const { string, ...rest } = props;

  const stringProps = useMemo(() => (string ? stringAvatar(string) : {}), [string]);

  return <MuiAvatar {...rest} {...stringProps} />;
}

function stringToColor(str: string, range?: number[][], predefinedColors?: string[]) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    if (range) {
      value = Math.floor((value * (range[i][1] - range[i][0] + 1)) / 256) + range[i][0];
    }
    color += ('00' + value.toString(16)).substr(-2);
  }

  if (predefinedColors) {
    const index = Math.floor(hash % predefinedColors.length);
    color = predefinedColors[index];
  }

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name, [
        [0, 255],
        [0, 205],
        [10, 255],
      ]),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
